define("ptportal/components/dashboard/bid-volume-by-vendors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    categories: null,

    init() {
      this._super(...arguments);

      this.chartOptions = [];
      this.chartData = {};
      this.set('chartOptions', configureChartOptions());

      function configureChartOptions() {
        return {
          chart: {
            type: 'pie',
            height: 550
          },
          title: {
            text: ''
          },
          legend: {
            enabled: true
          },
          tooltip: {
            pointFormat: 'Vendor Volume: <b>{point.percentage:.1f}%</b>'
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true
              },
              showInLegend: true
            }
          }
        };
      }
    },

    didInsertElement() {
      this._super(...arguments);

      let data = [];

      if (this.get('model').length !== undefined) {
        this.get('model').forEach(function (bidvolume) {
          data.pushObject({
            name: bidvolume.programname + " (" + bidvolume.value + " bids)",
            y: bidvolume.value
          });
        });
      }

      let pieArray = [{
        name: 'Bid Volume',
        colorByPoint: true,
        data: data
      }];
      this.set('chartData', pieArray);
    },

    didUpdateAttrs() {
      this.didInsertElement();
    }

  });

  _exports.default = _default;
});