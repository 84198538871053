define("ptportal/helpers/read-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function ([object, path]) {
    if (path) {
      return Ember.get(object, path);
    } else {
      return object;
    }
  });

  _exports.default = _default;
});