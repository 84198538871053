define("ptportal/components/dashboard/dashboard-latestbids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rows: Ember.computed('model', function () {
      if (this.get('model').length !== undefined) {
        let formattedArray = [];
        this.get('model').forEach(function (latestbid) {
          formattedArray.pushObject({
            priceid: latestbid.price_id,
            dealnumber: latestbid.bidnum,
            vendor: latestbid.vendor,
            program: latestbid.program,
            received: latestbid.received
          });
        });
        return formattedArray;
      }
    })
  });

  _exports.default = _default;
});