define("ptportal/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "sweetalert2"], function (_exports, _applicationRouteMixin, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    swal: Ember.inject.service(),

    beforeModel() {
      return this._loadCurrentUser();
    },

    sessionAuthenticated() {
      this._super(...arguments);

      this._loadCurrentUser();
    },

    _loadCurrentUser() {
      return this.get('currentUser').load().catch(() => this.get('session').invalidate());
    },

    actions: {
      updatePasswordRoute(password) {
        let query = {
          TNOLDPASSWORD: password.tnopassword,
          TNPASSWORD: password.tnpassword
        };
        this.get('ajax').request("tncontactpeople/" + this.currentUser.user.tncontactpersonid, {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Password Changed', 'The password was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Password Change Failed!', 'An error occoured while updating the password.', 'error');
          }
        });
      }

    }
  });

  _exports.default = _default;
});