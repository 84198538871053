define("ptportal/templates/components/bideditor/bideditor-endusers-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IzMGNG1d",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationname\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",false],[23,\"role\",\"button\",null],[23,\"class\",\"icon icon-delete-forever is-selectable\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],\"removeEndUser\",[27,[26,0,\"Expression\"],[\"id\"]]],null],[10],[1,1,0,0,\"\\n    \"],[7,\"bs-tooltip\",[],[[\"@title\"],[\"Remove EndUser\"]],null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tnrelation\",\"action\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/bideditor/bideditor-endusers-row.hbs"
    }
  });

  _exports.default = _default;
});