define("ptportal/components/editcustomers/editcust-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    custdata: Ember.inject.service('cust-data'),
    customerName: "",
    customerNumber: "",
    selectedSalesRelationGrp: "",
    selectedSalesRelationIn: "",
    selectedSalesRelationOut: "",
    selectedSalesRelationMgr: "",

    init() {
      this._super(...arguments); // this.getCustSalesNameGrp = this.custdata.getCustSalesNameGrp();
      // this.getCustSalesNameIn = this.custdata.getCustSalesNameIn();
      // this.getCustSalesNameOut = this.custdata.getCustSalesNameOut();
      // this.getCustSalesNameMgr = this.custdata.getCustSalesNameMgr();


      if (this.get('custsalesrelations') !== undefined && this.get('custsalesrelations') !== null) {
        let custSalesNameGrp = [];
        let getCustSalesNameIn = [];
        let getCustSalesNameOut = [];
        let getCustSalesNameMgr = [];
        this.get('custsalesrelations').data.forEach(function (salesrelation) {
          if (salesrelation.attributes.tncustsalestype === 8) {
            custSalesNameGrp.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesgrpname: salesrelation.attributes.tncustsalesname
            });
          }

          if (salesrelation.attributes.tncustsalestype === 2) {
            getCustSalesNameIn.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesinname: salesrelation.attributes.tncustsalesname
            });
          }

          if (salesrelation.attributes.tncustsalestype === 4) {
            getCustSalesNameOut.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesoutname: salesrelation.attributes.tncustsalesname
            });
          }

          if (salesrelation.attributes.tncustsalestype === 6) {
            getCustSalesNameMgr.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesmgrname: salesrelation.attributes.tncustsalesname
            });
          }
        });
        this.getCustSalesNameGrp = custSalesNameGrp;
        this.getCustSalesNameIn = getCustSalesNameIn;
        this.getCustSalesNameOut = getCustSalesNameOut;
        this.getCustSalesNameMgr = getCustSalesNameMgr;
      }

      if (this.get('model') != null) {
        if (this.model.customerName) {
          this.set('customerName', this.model.customerName);
        }

        if (this.model.customerNumber) {
          this.set('customerNumber', this.model.customerNumber);
        }

        if (this.model.tncustsalesgrpname) {
          this.set('selectedSalesRelationGrp', this.model);
        }

        if (this.model.tncustsalesinname) {
          this.set('selectedSalesRelationIn', this.model);
        }

        if (this.model.tncustsalesoutname) {
          this.set('selectedSalesRelationOut', this.model);
        }

        if (this.model.tncustsalesmgrname) {
          this.set('selectedSalesRelationMgr', this.model);
        }
      }
    },

    actions: {
      changein() {},

      changeout() {},

      changemgr() {},

      changegrp() {},

      updateFilters() {
        //this.resetSelection(0);
        var editcustfilters = {};

        if (this.selectedSalesRelationGrp == null) {
          this.set("selectedSalesRelationGrp", "");
        }

        if (this.selectedSalesRelationIn == null) {
          this.set("selectedSalesRelationIn", "");
        }

        if (this.selectedSalesRelationOut == null) {
          this.set("selectedSalesRelationOut", "");
        }

        if (this.selectedSalesRelationMgr == null) {
          this.set("selectedSalesRelationMgr", "");
        }

        editcustfilters['customerName'] = this.customerName, editcustfilters['customerNumber'] = this.customerNumber, editcustfilters['selectedSalesRelationGrp'] = this.selectedSalesRelationGrp.tncustsalesrelationid, editcustfilters['tncustsalesgrpname'] = this.selectedSalesRelationGrp.tncustsalesgrpname, editcustfilters['selectedSalesRelationIn'] = this.selectedSalesRelationIn.tncustsalesrelationid, editcustfilters['tncustsalesinname'] = this.selectedSalesRelationIn.tncustsalesinname, editcustfilters['selectedSalesRelationOut'] = this.selectedSalesRelationOut.tncustsalesrelationid, editcustfilters['tncustsalesoutname'] = this.selectedSalesRelationOut.tncustsalesoutname, editcustfilters['selectedSalesRelationMgr'] = this.selectedSalesRelationMgr.tncustsalesrelationid;
        editcustfilters['tncustsalesmgrname'] = this.selectedSalesRelationMgr.tncustsalesmgrname, this.searchMyCust(editcustfilters, 1);
      },

      clearFilters() {
        this.set("customerName", "");
        this.set("customerNumber", "");
        this.set("selectedSalesRelationGrp", "");
        this.set("selectedSalesRelationIn", "");
        this.set("selectedSalesRelationOut", "");
        this.set("selectedSalesRelationMgr", "");
        this.clearFilters();
      }

    }
  });

  _exports.default = _default;
});