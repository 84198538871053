define("ptportal/controllers/login", ["exports", "ember-ajax/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    axparser: Ember.inject.service('ax-parser'),
    actions: {
      authenticate() {
        //start ajax
        // var parser, xmlDoc, tkn, myRe, myRe2;
        //
        // request('https://www.pricetrackerdata.com/TNWebService/Service.asmx/guid?').catch((error) => {
        //   myRe = /d>.*<\/t/g;
        //   myRe2 = /\w*-.*([A-Z]|[0-9])/;
        //   parser = new DOMParser();
        //   xmlDoc = parser.parseFromString(error, "text/xml");
        //   tkn = myRe2.exec(myRe.exec(xmlDoc.childNodes[0].childNodes[0].nodeValue))[0];
        //   window.localStorage.setItem('axtoken', tkn);
        // }).finally(() => {
        //   request('https://www.pricetrackerdata.com/TNWebService/Service.asmx/login3', { method: 'POST', data: { _userid: this.get('username'), _password: this.get('password'), _tnWsGuid: tkn }}).catch(
        //     (error) => {
        //       //console.log(this.axparser.parse_response(error));
        //       //console.info(error);
        //     }
        //   );
        // });
        // return
        //end ajax
        this.set('errorMessage', '');
        const credentials = this.getProperties('username', 'password');
        const authenticator = 'authenticator:jwt'; // or 'authenticator:jwt'

        this.get('session').authenticate(authenticator, credentials).catch(() => {
          const errorMessage = 'Wrong email or password. Please try again!';
          this.set('errorMessage', errorMessage);
        });
      }

    }
  });

  _exports.default = _default;
});