define("ptportal/components/mycustomers/mycust-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    customerName: null,
    accountNumber: null,
    selectedMapStatus: null,
    selectedRelationSource: null,

    init() {
      this._super(...arguments);

      this.mapstatus = ['All', 'Mapped', 'Un Mapped', 'Disabled'];
      this.relationsource = ['All', 'BidLetter', 'Domain', 'Name Match'];

      if (this.get('model') != null) {
        if (this.model.customerName) {
          this.set('customerName', this.model.customerName);
        }

        if (this.model.accountNumber) {
          this.set('accountNumber', this.model.accountNumber);
        }

        if (this.model.mapStatus) {
          this.set('selectedMapStatus', this.model.mapStatus);
        }

        if (this.model.relationSource) {
          this.set('selectedRelationSource', this.model.relationSource);
        }
      }
    },

    actions: {
      updateFilters() {
        var mycustfilters = {};
        mycustfilters['customerName'] = this.customerName, mycustfilters['accountNumber'] = this.accountNumber, mycustfilters['mapStatus'] = this.selectedMapStatus, mycustfilters['relationSource'] = this.selectedRelationSource;
        this.searchMyCust(mycustfilters, 1);
      },

      clearFilters() {
        this.set("customerName", null);
        this.set("accountNumber", null);
        this.set("selectedMapStatus", null);
        this.set("selectedRelationSource", null);
        this.clearFilters();
      }

    }
  });

  _exports.default = _default;
});