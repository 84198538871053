define("ptportal/components/editcustomers/editcust-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageCount: "",
    disableNext: 0,
    totalPages: 0,
    disablePrevious: 0,
    disablePagination: 0,

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('model') !== undefined && this.get('model') !== null && this.get('model') !== {}) {
        let message = "Page " + this.currentPage + " of " + this.model.totalpages;
        this.set("pageCount", message);
        this.set("totalPages", this.model.totalpages);

        if (this.currentPage == this.totalPages) {
          this.set("disableNext", 0);
        }

        if (this.currentPage < this.totalPages) {
          this.set("disableNext", 1);
        }

        if (this.currentPage > 1) {
          this.set("disablePrevious", 1);
        } else {
          this.set("disablePrevious", 0);
        }

        if (this.totalPages > 1) {
          this.set("disablePagination", 1);
        } else {
          this.set("disablePagination", 0);
        }
      } else {
        this.set("disablePagination", 0);
      }
    },

    addMode: false,
    rows: Ember.computed('model', function () {
      if (this.get('model') !== undefined && this.get('model') !== null && this.get('model') !== {}) {
        let formattedArray = [];
        this.get('model').data.forEach(function (editcustomer) {
          let relationtype = editcustomer.tnParseDataType;

          switch (relationtype) {
            case 2:
              relationtype = 'Header';
              break;

            case 4:
              relationtype = 'Part';
              break;

            case 6:
              relationtype = 'PartLine';
              break;

            case 8:
              relationtype = 'Customer';
              break;

            case 10:
              relationtype = 'Distributor';
              break;

            case 12:
              relationtype = 'Vendor';
              break;

            case 14:
              relationtype = 'Reseller';
              break;

            case 16:
              relationtype = 'Bundle';
              break;

            case 18:
              relationtype = 'Config';
              break;

            case 20:
              relationtype = 'PartFamily';
              break;

            case 22:
              relationtype = 'PartTwo';
              break;

            case 24:
              relationtype = 'Bid';
              break;

            case 26:
              relationtype = 'PartLineTwo';
              break;

            default:
              relationtype = 'Undefined';
          }

          formattedArray.pushObject({
            tnrelationname: editcustomer.tnRelationName,
            tnrelationcity: editcustomer.tnRelationCity,
            tnrelationstate: editcustomer.tnRelationState,
            tnrelationemail: editcustomer.tnRelationEmail,
            tnparsedatatype: relationtype,
            in: editcustomer.in,
            out: editcustomer.out,
            mgr: editcustomer.mgr,
            grp: editcustomer.grp,
            tncustsalesinname: editcustomer.in,
            tncustsalesoutname: editcustomer.out,
            tncustsalesmgrname: editcustomer.mgr,
            tncustsalesgrpname: editcustomer.grp,
            name: editcustomer.tnRelationState,
            tncustinternalid1: editcustomer.tnCustInternalID1,
            tncustrelationid: editcustomer.tnCustRelationID
          });
        });
        return formattedArray;
      }
    }),
    actions: {
      addCustomer() {
        this.toggleProperty("addMode");
      },

      nextPage() {
        this.nextPage();
      },

      prevPage() {
        this.prevPage();
      },

      firstPage() {
        this.firstPage();
      },

      lastPage() {
        this.lastPage(this.get("totalPages"));
      }

    }
  });

  _exports.default = _default;
});