define("ptportal/components/mycustomers/mycust-relations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addRelationMode: false,
    rows: Ember.computed("model", function () {
      if (this.get('model') !== null && this.get('model') !== undefined) {
        let formattedArray = [];
        this.get('model').data.forEach(function (relation) {
          formattedArray.pushObject({
            tnrelationname: relation.attributes.tnrelationname,
            tncustinternalid1: relation.attributes.tncustinternalid1,
            id: relation.id
          });
        });
        return formattedArray;
      }
    }),
    isAddRelationDisabled: Ember.computed("selectedPriceRelationId", "selectedPriceRelationStatus", function () {
      let pricerelationid = this.get("selectedPriceRelationId");
      let pricerelationstatus = this.get("selectedPriceRelationStatus");

      if (pricerelationid === 0 || pricerelationstatus !== "Mapped") {
        return "disabled";
      }

      return "";
    }),
    actions: {
      addRelationMode() {
        this.toggleProperty("addRelationMode");
        this.clearCustRelations();
      }

    }
  });

  _exports.default = _default;
});