define("ptportal/router", ["exports", "ptportal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('tncontactpeople');
    this.route('dashboard');
    this.route('bidexplorer');
    this.route('exceptions');
    this.route('email');
    this.route('bideditor');
    this.route('mycustomers');
    this.route('programs');
    this.route('editcustomers');
    this.route('reports');
    this.route('noaccess');
  });
  var _default = Router;
  _exports.default = _default;
});