define("ptportal/components/bidexplorer/be-biddelta-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rows: Ember.computed('model', function () {
      if (this.get('model') !== undefined && this.get('model').length !== 0) {
        let formattedArray = [];
        this.get('model').data.forEach(function (tnbiddelta) {
          let deltastartdate = tnbiddelta.tnLineStartDate.replace(/T[0-9][0-9]:00:00.000Z/, '');

          if (deltastartdate == '1900-01-01') {
            deltastartdate = "";
          }

          let deltaenddate = tnbiddelta.tnLineEndDate.replace(/T[0-9][0-9]:00:00.000Z/, '');

          if (deltaenddate == '1900-01-01') {
            deltaenddate = "";
          }

          let deltabidprice = tnbiddelta.tnBidPrice;

          if (deltabidprice !== "") {
            deltabidprice = parseFloat(deltabidprice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          }

          let deltadiscount = tnbiddelta.tnDiscountPercentage;

          if (deltadiscount !== "") {
            deltadiscount = parseFloat(deltadiscount).toFixed(2);
          }

          formattedArray.pushObject({
            tnLineNumber: tnbiddelta.tnLineNumber,
            tnVendPart: tnbiddelta.tnVendPart,
            tnVendPartDesc: tnbiddelta.tnVendPartDesc,
            tnQuantity: tnbiddelta.tnQuantity,
            tnBidPrice: deltabidprice,
            tnLineStartDate: deltastartdate,
            tnLineEndDate: deltaenddate,
            tnProductLine: tnbiddelta.tnProductLine,
            tnDiscountPercentage: deltadiscount,
            tnDelta: tnbiddelta.tnDelta,
            tnDeltaAmount: tnbiddelta.tnDeltaAmount
          });
        });
        return formattedArray;
      }
    })
  });

  _exports.default = _default;
});