define("ptportal/templates/components/bidexplorer/be-add-reseller-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mvji9zwt",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationname\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationcity\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tncustname\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tncustinternalid1\"]]],[11],[1,1,0,0,\"\\n\"],[2,\" <td>\\n    <i {{action \\\"addCustRelation\\\" reseller.tncustrelationid}} class=\\\"icon icon-plus-circle\\\">\\n      <BsTooltip @title=\\\"Add Reseller\\\" />\\n    </i>\\n</td> \"],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"reseller\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/bidexplorer/be-add-reseller-row.hbs"
    }
  });

  _exports.default = _default;
});