define("ptportal/models/addressstate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    stateid: _emberData.default.attr('string'),
    countryregionid: _emberData.default.attr('string'),
    intrastatcode: _emberData.default.attr('string'),
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    taxgroup: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('number')
  });

  _exports.default = _default;
});