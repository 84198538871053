define("ptportal/routes/email", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-cli-file-saver/mixins/file-saver", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _fileSaver, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _fileSaver.default, {
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    swal: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    router: Ember.inject.service('router'),
    custdata: Ember.inject.service('cust-data'),
    selectedEmailId: 0,
    email: null,
    prices: null,
    currentPage: 1,
    totalRows: 0,
    emailfilters: null,
    originalDocUrl: "",
    isCustomerCDW: 0,
    //------START-------TO MAKE THE OPEN IN BID EXPLORER WORK-----------START----------------//
    queryReceiptDate: "",
    queryReceiptDateFrom: "",
    queryEmailStatus: "",
    isqueryparams: 0,
    queryParams: {
      receiptDate: {
        replace: true,
        refreshModel: true
      },
      receiptDateFrom: {
        replace: true,
        refreshModel: true
      },
      emailstatus: {
        replace: true,
        refreshModel: true
      }
    },

    //------END-------TO MAKE THE OPEN IN BID EXPLORER WORK------------END---------------//
    model(params) {
      if (this.get("currentUser").user.tncustid == "CU100007") {
        this.set("isCustomerCDW", 1);
      } //------START-------TO MAKE THE OPEN IN BID EXPLORER WORK-----------START----------------//


      var emailfilters = {};

      if (params.emailstatus != this.get("queryEmailStatus") || params.receiptDate != this.get("queryReceiptDate") || params.receiptDateFrom != this.get("queryReceiptDateFrom")) {
        this.set("isqueryparams", 0);
      }

      if (params.emailstatus && params.receiptDate && params.receiptDateFrom && this.get("isqueryparams") == 0) {
        emailfilters['emailstatus'] = params.emailstatus;
        emailfilters['receiptDate'] = params.receiptDate;
        emailfilters['receiptDateFrom'] = params.receiptDateFrom;
        this.set("queryEmailStatus", params.emailstatus);
        this.set("queryReceiptDate", params.receiptDate);
        this.set("queryReceiptDateFrom", params.receiptDateFrom);
        this.searchEmail(emailfilters, 1);
      }

      this.set("isqueryparams", 1); //------END-------TO MAKE THE OPEN IN BID EXPLORER WORK------------END---------------//

      return Ember.RSVP.hash({
        emails: this.get('emails'),
        email: this.get('email'),
        prices: this.get('prices'),
        selectedEmailId: this.get("selectedEmailId"),
        currentPage: this.get('currentPage'),
        totalRows: this.get('totalRows'),
        originalDocUrl: this.get('originalDocUrl'),
        emailfilters: this.get('emailfilters'),
        isCustomerCDW: this.get('isCustomerCDW')
      });
    },

    beforeModel: function () {
      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      if (this.get("currentUser").user.accessEmail) {
        return true;
      }

      this.get('session').invalidate();
    },
    //------START-------TO MAKE THE OPEN IN BID EXPLORER WORK-----------START----------------//
    searchEmail: function (emailfilters, pagenumber) {
      this.set("currentPage", pagenumber);
      this.set("emailfilters", emailfilters);
      this.set("originalDocUrl", 0);
      var query = {}; // Build Query Start

      if (emailfilters.receiptDate) {
        if (emailfilters.receiptDate.length == 1) {
          let receiptdate = emailfilters.receiptDate[0];
          receiptdate = receiptdate.getFullYear() + "-" + (receiptdate.getMonth() + 1) + "-" + receiptdate.getDate();
          query['TNMESSAGERECEIPTDATE'] = receiptdate;
        } else {
          query['TNMESSAGERECEIPTDATE'] = emailfilters.receiptDate;
        }
      }

      if (emailfilters.receiptDateFrom) {
        if (emailfilters.receiptDateFrom.length == 1) {
          let receiptdatefrom = emailfilters.receiptDateFrom[0];
          receiptdatefrom = receiptdatefrom.getFullYear() + "-" + (receiptdatefrom.getMonth() + 1) + "-" + receiptdatefrom.getDate();
          query['TNMESSAGERECEIPTDATEFROM'] = receiptdatefrom;
        } else {
          query['TNMESSAGERECEIPTDATEFROM'] = emailfilters.receiptDateFrom;
        }
      }

      if (emailfilters.emailstatus) {
        let emailstatus = emailfilters.emailstatus;

        switch (emailstatus) {
          case 'Unparsed':
            emailstatus = 2;
            break;

          case 'Successful':
            emailstatus = 4;
            break;

          case 'Unsuccessful':
            emailstatus = 6;
            break;

          case 'Unknown Bid':
            emailstatus = 10;
            break;

          case 'Hold':
            emailstatus = 16;
            break;

          case 'Force Process':
            emailstatus = 18;
            break;

          case 'Unparsed Rails':
            emailstatus = 28;
            break;

          default:
            emailstatus = 0;
        }

        query['TNPRICEPARSESTATUS'] = emailstatus;
      }

      query['page'] = pagenumber;
      this.get('ajax').request('tnpriceemails', {
        type: 'GET',
        data: query
      }).then(emails => {
        this.set("emails", emails);
        this.set("totalRows", emails.totalrecords);
        this.set("selectedEmailId", 0);
        this.set('prices', null);
        this.set('email', null);
        this.refresh();
      });
    },
    //------END-------TO MAKE THE OPEN IN BID EXPLORER WORK------------END---------------//
    actions: {
      searchEmail(emailfilters, pagenumber) {
        this.set("currentPage", pagenumber);
        this.set("emailfilters", emailfilters);
        this.set("originalDocUrl", 0);
        document.getElementById("bidlines-tab").click();
        var query = {}; // Build Query Start

        if (emailfilters.emailid) {
          query['TNEMAILID'] = emailfilters.emailid;
        }

        if (emailfilters.tnvendid) {
          query['TNVENDID'] = emailfilters.tnvendid;
        }

        if (emailfilters.subject) {
          query['TNMESSAGESUBJECT'] = emailfilters.subject;
        }

        if (emailfilters.receiptDate) {
          if (emailfilters.receiptDate.length == 1) {
            let receiptdate = emailfilters.receiptDate[0];
            receiptdate = receiptdate.getFullYear() + "-" + (receiptdate.getMonth() + 1) + "-" + receiptdate.getDate();
            query['TNMESSAGERECEIPTDATE'] = receiptdate;
          } else {
            query['TNMESSAGERECEIPTDATE'] = emailfilters.receiptDate;
          }
        }

        if (emailfilters.receiptDateFrom) {
          if (emailfilters.receiptDateFrom.length == 1) {
            let receiptdatefrom = emailfilters.receiptDateFrom[0];
            receiptdatefrom = receiptdatefrom.getFullYear() + "-" + (receiptdatefrom.getMonth() + 1) + "-" + receiptdatefrom.getDate();
            query['TNMESSAGERECEIPTDATEFROM'] = receiptdatefrom;
          } else {
            query['TNMESSAGERECEIPTDATEFROM'] = emailfilters.receiptDateFrom;
          }
        }

        if (emailfilters.showUnresolved == 1) {
          query['TNSHOWUNRESOLVED'] = 1;
        }

        if (emailfilters.emailstatus) {
          let emailstatus = emailfilters.emailstatus;

          switch (emailstatus) {
            case 'Unparsed':
              emailstatus = 2;
              break;

            case 'Successful':
              emailstatus = 4;
              break;

            case 'Unsuccessful':
              emailstatus = 6;
              break;

            case 'Unknown Bid':
              emailstatus = 10;
              break;

            case 'Hold':
              emailstatus = 16;
              break;

            case 'Force Process':
              emailstatus = 18;
              break;

            case 'Unparsed Rails':
              emailstatus = 28;
              break;

            default:
              emailstatus = 0;
          }

          query['TNPRICEPARSESTATUS'] = emailstatus;
        }

        query['page'] = pagenumber;
        this.get('ajax').request('tnpriceemails', {
          type: 'GET',
          data: query
        }).then(emails => {
          this.set("emails", emails);
          this.set("totalRows", emails.totalrecords);
          this.set("selectedEmailId", 0);
          this.set('prices', null);
          this.set('email', null);
          this.refresh();
        });
      },

      selectEmail(semail) {
        this.set("selectedEmailId", semail);
        document.getElementById("bidlines-tab").click();
        this.set('prices', null);
        this.set('email', null);
        var query = {};
        query['TNEMAILID'] = this.get("selectedEmailId");
        this.get('ajax').request('explorer_prices', {
          type: 'GET',
          data: query
        }).then(prices => {
          this.set("prices", prices);
          this.refresh();
        });
        this.get('ajax').request('tnpriceemails', {
          type: 'GET',
          data: query
        }).then(data => {
          if (data.data[0]) {
            if (data.data[0].docurl == "") {
              this.set("originalDocUrl", 0);
            } else {
              this.set("originalDocUrl", data.data[0].docurl);
            }
          }

          this.refresh();
        });
        this.refresh();
      },

      originalDoc() {
        // window.open(
        //   this.get("originalDocUrl"),
        //   '_blank'
        // );
        let contentType = 'application/json';
        let filename = this.get("selectedEmailId") + '.msg';
        return this.get('ajax').request('get_bid_doc', {
          type: 'GET',
          dataType: 'arraybuffer',
          data: {
            TNEMAILID: this.get("selectedEmailId")
          }
        }).then(content => this.saveFileAs(filename, content, contentType)).catch(() => {
          (0, _sweetalert.default)('An Error Occoured', 'Error retrieving document!', 'error');
        });
      },

      emailBody() {
        this.get('ajax').request("tnpriceemails/" + this.get("selectedEmailId"), {
          type: 'GET'
        }).then(singleemail => {
          this.set('email', singleemail);
          this.refresh();
        });
      },

      setEmailStatus(status) {
        this.get('ajax').request("tnpriceemails/" + this.get("selectedEmailId"), {
          type: 'PUT',
          data: {
            TNPRICEPARSESTATUS: status
          }
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Email Status Updated', 'Email Status was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Email Status update Failed!', 'An error occoured while updating the email status.', 'error');
          }

          this.send("searchEmail", this.get("emailfilters"), this.get("currentPage"));
        });
      },

      openInBidExplorer(priceid) {
        this.get('router').transitionTo('bidexplorer', {
          queryParams: {
            priceid: priceid
          }
        });
      },

      clearFilters() {
        this.set("emailfilters", null);
        this.set('prices', null);
        this.set('emails', null);
        this.set('email', null);
        this.set("selectedEmailId", 0);
        this.set('currentPage', 1);
        this.set('totalRows', 0);
        this.set('originalDocUrl', "");
        document.getElementById("bidlines-tab").click();
        this.refresh();
      },

      //Pagination
      nextPage() {
        let currentPage = this.get("currentPage");
        currentPage++;
        this.send("searchEmail", this.get("emailfilters"), currentPage);
      },

      prevPage() {
        let currentPage = this.get("currentPage");
        currentPage--;
        this.send("searchEmail", this.get("emailfilters"), currentPage);
      },

      firstPage() {
        this.set("currentPage", 1);
        this.send("searchEmail", this.get("emailfilters"), 1);
      },

      lastPage(totalpages) {
        this.set("currentPage", totalpages);
        this.send("searchEmail", this.get("emailfilters"), totalpages);
      }

    }
  });

  _exports.default = _default;
});