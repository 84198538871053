define("ptportal/models/tnpricerelation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    //tncust: DS.belongsTo('tncust'),
    //tncustrelation: DS.belongsTo('tncustrelation'),
    //tnpricerelationrefs: DS.hasMany('tnpricerelationref'),
    //tnrenewalrelationrefs: DS.hasMany('tnrenewalrelationref'),
    //tnrenewals: DS.hasMany('tnrenewal'),
    tnrelationname: _emberData.default.attr('string'),
    tnrelationname2: _emberData.default.attr('string'),
    tnrelationaddress: _emberData.default.attr('string'),
    tnrelationcity: _emberData.default.attr('string'),
    tnrelationstate: _emberData.default.attr('string'),
    tnrelationzipcode: _emberData.default.attr('string'),
    tnrelationvendorid1: _emberData.default.attr('string'),
    tnrelationid: _emberData.default.attr('string'),
    tnrelationaddressraw: _emberData.default.attr('string'),
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    tncustid: _emberData.default.attr('string'),
    tnrelationcountryid: _emberData.default.attr('string'),
    tnrelationuname: _emberData.default.attr('string'),
    tnrelationuaddress: _emberData.default.attr('string'),
    tnrelationucity: _emberData.default.attr('string'),
    tnrelationustate: _emberData.default.attr('string'),
    tnrelationuzipcode: _emberData.default.attr('string'),
    tnrelationuaddressraw: _emberData.default.attr('string'),
    tnparsedatatype: _emberData.default.attr('number'),
    tncustrelationid: _emberData.default.attr('string'),
    tnrelationsource: _emberData.default.attr('number'),
    tnrelationstatus: _emberData.default.attr('number'),
    tncustinternalid1: _emberData.default.attr('string'),
    tncustinternalid2: _emberData.default.attr('string'),
    accountnumber: _emberData.default.attr('string')
  });

  _exports.default = _default;
});