define("ptportal/components/exceptions/exceptions-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    custdata: Ember.inject.service('cust-data'),
    router: Ember.inject.service('router'),
    store: Ember.inject.service(),
    priceid: "",
    selectedVendor: "",
    selectedProgram: "",
    selectedStatus: "Exception",
    selectedDatatype: "All",
    selectedContactPerson: "",

    // isProd: false,
    init() {
      this._super(...arguments);

      this.vendors = this.custdata.getVendors(); // retrieve from service

      this.pricestatus = ['Exception', 'Ready', 'Complete', 'Hold', 'Ignore'];
      this.datatype = ['All', 'Production', 'Test'];
      this.programs = this.custdata.getPrograms();
      this.contactpeople = this.users; // this.contactpeople = this.custdata.getContactPeople();

      if (this.get('model') != null) {
        if (this.model.priceid) {
          this.set('priceid', this.model.priceid);
        }

        if (this.model.tnvendvalue) {
          this.set('selectedVendor', this.model);
        }

        if (this.model.tnprogramvalue) {
          this.set('selectedProgram', this.model);
        }

        if (this.model.priceStatus) {
          this.set('selectedStatus', this.model.priceStatus);
        }

        if (this.model.dataType) {
          this.set('selectedDatatype', this.model.dataType);
        }

        if (this.model.selectedContactPerson) {
          this.set('selectedContactPerson', this.model);
        } // if (this.model.isProd){
        //   this.set('isProd', true);
        // }

      }
    },

    actions: {
      updateFilters() {
        var exceptionfilters = {};

        if (this.selectedVendor == null) {
          this.set("selectedVendor", "");
        }

        if (this.selectedProgram == null) {
          this.set("selectedProgram", "");
        }

        if (this.selectedContactPerson == null) {
          this.set("selectedContactPerson", "");
        }

        exceptionfilters['priceid'] = this.priceid, exceptionfilters['tnvendid'] = this.selectedVendor.tnvendid, exceptionfilters['tnvendvalue'] = this.selectedVendor.tnvendvalue, exceptionfilters['tnprogramid'] = this.selectedProgram.tnprogramid, exceptionfilters['tnprogramvalue'] = this.selectedProgram.tnprogramvalue, exceptionfilters['priceStatus'] = this.selectedStatus, exceptionfilters['dataType'] = this.selectedDatatype, exceptionfilters['selectedContactPerson'] = this.selectedContactPerson.id, exceptionfilters['avatar'] = this.selectedContactPerson.avatar, exceptionfilters['fullName'] = this.selectedContactPerson.fullName, // exceptionfilters['isProd'] = this.isProd ? 1:0,
        this.searchExceptions(exceptionfilters, 1);
      },

      clearFilters() {
        this.set("priceid", "");
        this.set("selectedStatus", "Exception");
        this.set("selectedDatatype", "All");
        this.set("selectedVendor", "");
        this.set("selectedProgram", "");
        this.set("selectedContactPerson", ""); // this.set("isProd", false);

        this.clearFilters();
      }

    }
  });

  _exports.default = _default;
});