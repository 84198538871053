define("ptportal/templates/components/exceptions/exceptions-relations-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DsREr/0I",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationname\"]]],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,0],[\"isAlso\"]]],null,[[\"default\"],[{\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationname2\"]]],[11]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnaccountnumber\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",false],[23,\"role\",\"button\",null],[23,\"class\",\"icon icon-delete-forever is-selectable\",null],[3,0,0,[27,[26,2,\"ModifierHead\"],[]],[[27,[24,0],[]],\"removeRelation\",[27,[26,0,\"Expression\"],[\"id\"]]],null],[10],[1,1,0,0,\"\\n    \"],[7,\"bs-tooltip\",[],[[\"@title\"],[[32,[\"Remove \",[31,292,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"isCustomerCDW\"]],\"Customer\",\"Reseller\"],null]]]]],null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tnrelation\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/exceptions/exceptions-relations-row.hbs"
    }
  });

  _exports.default = _default;
});