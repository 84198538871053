define("ptportal/routes/editcustomers", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    router: Ember.inject.service('router'),
    currentUser: Ember.inject.service('current-user'),
    swal: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    customers: null,
    myCustFilters: null,
    custSalesRelation: null,
    currentPage: 1,
    totalRows: 0,

    model() {
      let custsalesrelation = [];
      custsalesrelation = this.get('ajax').request('tncustsalesrelations', {
        type: 'GET'
      });
      this.set("custSalesRelation", custsalesrelation);
      return Ember.RSVP.hash({
        customers: this.get("customers"),
        custSalesRelation: this.get("custSalesRelation"),
        currentPage: this.get('currentPage'),
        totalRows: this.get('totalRows'),
        myCustFilters: this.get('myCustFilters')
      });
    },

    beforeModel: function () {
      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      if (this.get("currentUser").user.accessCustomerEditor) {
        return true;
      }

      this.get('session').invalidate();
    },
    actions: {
      searchMyCust(mycustfilters, pagenumber) {
        this.set("currentPage", pagenumber);
        this.set("mycustfilters", mycustfilters);
        var query = {};

        if (mycustfilters.customerName) {
          query['TNRELATIONNAME'] = mycustfilters.customerName;
        }

        if (mycustfilters.customerNumber) {
          query['TNCUSTINTERNALID1'] = mycustfilters.customerNumber;
        }

        if (mycustfilters.selectedSalesRelationIn) {
          query['TNCUSTSALESRELATIONIDINSIDE'] = mycustfilters.selectedSalesRelationIn;
        }

        if (mycustfilters.selectedSalesRelationOut) {
          query['TNCUSTSALESRELATIONIDOUTSIDE'] = mycustfilters.selectedSalesRelationOut;
        }

        if (mycustfilters.selectedSalesRelationMgr) {
          query['TNCUSTSALESRELATIONIDMANAGER'] = mycustfilters.selectedSalesRelationMgr;
        }

        if (mycustfilters.selectedSalesRelationGrp) {
          query['TNCUSTSALESRELATIONIDGROUP'] = mycustfilters.selectedSalesRelationGrp;
        }

        query['page'] = pagenumber;
        this.get('ajax').request('editcustomers', {
          type: 'GET',
          data: query
        }).then(customers => {
          this.set("customers", customers);
          this.set("totalRows", customers.totalrecords);
          this.set("myCustFilters", mycustfilters);
          this.refresh();
        });
      },

      removeCustomer(tncustrelationid) {
        // console.log("Remove Customer with Cust Relation id ", tncustrelationid);
        this.get('swal').open({
          title: 'Are you sure you want to delete this Customer?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Delete",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request('editcustomers/' + tncustrelationid, {
              type: 'PUT',
              data: {
                TNCUSTRELATIONID: tncustrelationid,
                TNACTIVE: 0
              }
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Customer Deleted', 'Customer was successfully deleted.', 'success');
              } else {
                (0, _sweetalert.default)('Customer Delete Failed!', 'An error occoured while deleting the customer.', 'error');
              }

              this.send("searchMyCust", this.get("mycustfilters"), this.get("currentPage"));
            });
          }
        });
      },

      removeSalesRelation(tncustsalesrelationid) {
        // console.log("Remove Customer with Cust Relation id ", tncustsalesrelationid);
        this.get('swal').open({
          title: 'Are you sure you want to delete this Sales Relation?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Delete",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request('tncustsalesrelations/' + tncustsalesrelationid, {
              type: 'PUT',
              data: {
                TNCUSTSALESRELATIONID: tncustsalesrelationid,
                TNACTIVE: 0
              }
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Sales Relation Deleted', 'Sales relation was successfully deleted.', 'success');
              } else {
                (0, _sweetalert.default)('Sales Relation Delete Failed!', 'An error occoured while deleting the sales relation.', 'error');
              }

              this.refresh();
            });
          }
        });
      },

      updateCustomer(customer) {
        // console.log("Update Customer with", customer);
        let customertype = customer.CustomerType;
        let customertypenum = 0;

        switch (customertype) {
          case 'Customer':
            customertypenum = 8;
            break;

          case 'Distributor':
            customertypenum = 10;
            break;

          case 'Vendor':
            customertypenum = 12;
            break;

          case 'Reseller':
            customertypenum = 14;
            break;

          default:
            customertypenum = 0;
        }

        var query = {};
        query['DATAAREAID'] = 'itv';
        query['TNCUSTRELATIONID'] = customer.custRelationid;
        query['TNRELATIONNAME'] = customer.customername;
        query['TNCUSTINTERNALID1'] = customer.customernumber;
        query['TNPARSEDATATYPE'] = customertypenum;
        query['TNRELATIONEMAIL'] = customer.email;
        query['TNRELATIONCITY'] = customer.city;

        if (customer.StateProv != null) {
          query['TNRELATIONSTATE'] = customer.StateProv.stateid;
        }

        if (customer.SalesRelationIn != null) {
          query['TNCUSTSALESRELATIONIDINSIDE'] = customer.SalesRelationIn.tncustsalesrelationid;
        }

        if (customer.SalesRelationMgr != null) {
          query['TNCUSTSALESRELATIONIDMANAGER'] = customer.SalesRelationMgr.tncustsalesrelationid;
        }

        if (customer.SalesRelationGrp != null) {
          query['TNCUSTSALESRELATIONIDGROUP'] = customer.SalesRelationGrp.tncustsalesrelationid;
        }

        if (customer.SalesRelationOut != null) {
          query['TNCUSTSALESRELATIONIDOUTSIDE'] = customer.SalesRelationOut.tncustsalesrelationid;
        }

        this.get('ajax').request('editcustomers/' + customer.custRelationid, {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Customer Updated', 'Customer was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Customer Delete Failed!', 'An error occoured while updating the customer.', 'error');
          }

          this.send("searchMyCust", this.get("mycustfilters"), this.get("currentPage"));
        });
      },

      updateSalesRelation(salesrelation) {
        // console.log("Update SalesRelation with", salesrelation);
        let salesrelationtype = salesrelation.salesrelationtype;
        let salesrelationnum = 0;

        switch (salesrelationtype) {
          case 'Sales Rep In':
            salesrelationnum = 2;
            break;

          case 'Sales Rep Out':
            salesrelationnum = 4;
            break;

          case 'Sales Manager':
            salesrelationnum = 6;
            break;

          case 'Sales Group':
            salesrelationnum = 8;
            break;

          default:
            salesrelationnum = 0;
        }

        this.get('ajax').request('tncustsalesrelations/' + salesrelation.custsalesrelationid, {
          type: 'PUT',
          data: {
            TNCUSTSALESRELATIONID: salesrelation.custsalesrelationid,
            TNCUSTSALESID: salesrelation.salesid,
            TNRELATIONNAME: salesrelation.salesrelationname,
            TNCUSTSALESTYPE: salesrelationnum,
            TNCUSTSALESEMAIL: salesrelation.email
          }
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Sales Relation Updated', 'Sales relation was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Sales Relation Delete Failed!', 'An error occoured while updating the sales relation.', 'error');
          }

          this.refresh();
        });
      },

      addCustomer(customer) {
        // console.log("Add Customer with", customer);
        let customertype = customer.CustomerType;
        let customertypenum = 0;

        switch (customertype) {
          case 'Customer':
            customertypenum = 8;
            break;

          case 'Distributor':
            customertypenum = 10;
            break;

          case 'Vendor':
            customertypenum = 12;
            break;

          case 'Reseller':
            customertypenum = 14;
            break;

          default:
            customertypenum = 0;
        }

        var query = {};
        query['DATAAREAID'] = 'itv';
        query['TNACTIVE'] = 1;
        query['TNRELATIONNAME'] = customer.customername;
        query['TNCUSTINTERNALID1'] = customer.customernumber;
        query['TNPARSEDATATYPE'] = customertypenum;
        query['TNRELATIONEMAIL'] = customer.email;
        query['TNRELATIONCITY'] = customer.city;

        if (customer.StateProv != null) {
          query['TNRELATIONSTATE'] = customer.StateProv.stateid;
        }

        if (customer.SalesRelationIn != null) {
          query['TNCUSTSALESRELATIONIDINSIDE'] = customer.SalesRelationIn.tncustsalesrelationid;
        }

        if (customer.SalesRelationMgr != null) {
          query['TNCUSTSALESRELATIONIDMANAGER'] = customer.SalesRelationMgr.tncustsalesrelationid;
        }

        if (customer.SalesRelationGrp != null) {
          query['TNCUSTSALESRELATIONIDGROUP'] = customer.SalesRelationGrp.tncustsalesrelationid;
        }

        if (customer.SalesRelationOut != null) {
          query['TNCUSTSALESRELATIONIDOUTSIDE'] = customer.SalesRelationOut.tncustsalesrelationid;
        }

        this.get('ajax').request('editcustomers/', {
          type: 'POST',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Customer added', 'Customer was successfully created.', 'success');
          } else {
            (0, _sweetalert.default)('Customer Add Failed!', 'An error occoured while adding the customer.', 'error');
          }

          if (this.get("mycustfilters") !== undefined) {
            this.send("searchMyCust", this.get("mycustfilters"), this.get("currentPage"));
          } else {
            this.refresh();
          }
        });
      },

      addSalesRelation(salesrelation) {
        // console.log("Add SalesRelation with", salesrelation);
        let salesrelationtype = salesrelation.salesrelationtype;
        let salesrelationnum = 0;

        switch (salesrelationtype) {
          case 'Sales Rep In':
            salesrelationnum = 2;
            break;

          case 'Sales Rep Out':
            salesrelationnum = 4;
            break;

          case 'Sales Manager':
            salesrelationnum = 6;
            break;

          case 'Sales Group':
            salesrelationnum = 8;
            break;

          default:
            salesrelationnum = 0;
        }

        this.get('ajax').request('tncustsalesrelations/', {
          type: 'POST',
          data: {
            TNCUSTSALESNAME: salesrelation.salesrelationname,
            TNCUSTSALESID: salesrelation.salesid,
            TNCUSTSALESTYPE: salesrelationnum,
            TNCUSTSALESEMAIL: salesrelation.email
          }
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Sales Relation added', 'Sales Relation was successfully created.', 'success');
          } else {
            (0, _sweetalert.default)('Sales Relation Add Failed!', 'An error occoured while adding the sales relation.', 'error');
          }

          this.refresh();
        });
      },

      clearFilters() {
        this.set("customers", null);
        this.set("myCustFilters", null);
        this.set("currentPage", 1);
        this.set("totalRows", 0);
        this.refresh();
      },

      //Pagination
      nextPage() {
        let currentPage = this.get("currentPage");
        currentPage++;
        this.send("searchMyCust", this.get("mycustfilters"), currentPage);
      },

      prevPage() {
        let currentPage = this.get("currentPage");
        currentPage--;
        this.send("searchMyCust", this.get("mycustfilters"), currentPage);
      },

      firstPage() {
        this.set("currentPage", 1);
        this.send("searchMyCust", this.get("mycustfilters"), 1);
      },

      lastPage(totalpages) {
        this.set("currentPage", totalpages);
        this.send("searchMyCust", this.get("mycustfilters"), totalpages);
      }

    }
  });

  _exports.default = _default;
});