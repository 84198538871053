define("ptportal/components/mycustomers/mycust-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageCount: "",
    disableNext: 0,
    totalPages: 0,
    disablePrevious: 0,
    disablePagination: 0,

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('model') !== undefined && this.get('model') !== null && this.get('model') !== {}) {
        let message = "Page " + this.currentPage + " of " + this.model.totalpages;
        this.set("pageCount", message);
        this.set("totalPages", this.model.totalpages);

        if (this.currentPage == this.totalPages) {
          this.set("disableNext", 0);
        }

        if (this.currentPage < this.totalPages) {
          this.set("disableNext", 1);
        }

        if (this.currentPage > 1) {
          this.set("disablePrevious", 1);
        } else {
          this.set("disablePrevious", 0);
        }

        if (this.totalPages > 1) {
          this.set("disablePagination", 1);
        } else {
          this.set("disablePagination", 0);
        }
      } else {
        this.set("disablePagination", 0);
      }
    },

    rows: Ember.computed('model', function () {
      if (this.get('model') !== undefined && this.get('model') !== null && this.get('model') !== {}) {
        let formattedArray = [];
        this.get('model').data.forEach(function (customers) {
          let relationstatus = customers.relationStatus;

          switch (relationstatus) {
            case 0:
              relationstatus = 'All';
              break;

            case 2:
              relationstatus = 'Mapped';
              break;

            case 4:
              relationstatus = 'UnMapped';
              break;

            case 6:
              relationstatus = 'Disabled';
              break;

            default:
              relationstatus = 'Undefined';
          }

          let relationsource = customers.relationSource;

          switch (relationsource) {
            case 0:
              relationsource = 'All';
              break;

            case 2:
              relationsource = 'BidLetter';
              break;

            case 4:
              relationsource = 'Domain';
              break;

            case 6:
              relationsource = 'NameMatch';
              break;

            default:
              relationsource = 'Undefined';
          }

          formattedArray.pushObject({
            tnrelationname: customers.tnRelationName,
            tnrelationcity: customers.tnRelationCity,
            tncustinternalid1: customers.mappedRelationID,
            tnrelationstatus: relationstatus,
            tnrelationsource: relationsource,
            tnrelationadress: customers.tnRelationAddress,
            tnvendorid: customers.vendorRelationID,
            tnmappedrelationname: customers.mappedRelationName,
            tnpricerelationid: customers.tnPriceRelationID
          });
        });
        return formattedArray;
      }
    }),
    actions: {
      nextPage() {
        this.nextPage();
      },

      prevPage() {
        this.prevPage();
      },

      firstPage() {
        this.firstPage();
      },

      lastPage() {
        this.lastPage(this.get("totalPages"));
      }

    }
  });

  _exports.default = _default;
});