define("ptportal/components/sweet-alert", ["exports", "ember-sweetalert/components/sweet-alert"], function (_exports, _sweetAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _sweetAlert.default;
    }
  });
});