define("ptportal/components/bideditor/bideditor-add-reseller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    resellername: "",
    custinternalid1: "",
    totalRows: 0,
    pageCount: "",
    disableNext: 0,
    totalPages: 0,
    disablePrevious: 0,
    disablePagination: 0,

    init() {
      this._super(...arguments);

      this.selectReseller(0);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('resellers') !== undefined && this.get('resellers') !== null && this.get('resellers') !== {}) {
        let message = "Page " + this.currentPageRelations + " of " + this.resellers.meta.totalpages;
        this.set("pageCount", message);
        this.set("totalPages", this.resellers.meta.totalpages);

        if (this.currentPageRelations == this.totalPages) {
          this.set("disableNext", 0);
        }

        if (this.currentPageRelations < this.totalPages) {
          this.set("disableNext", 1);
        }

        if (this.currentPageRelations > 1) {
          this.set("disablePrevious", 1);
        } else {
          this.set("disablePrevious", 0);
        }

        if (this.totalPages > 1) {
          this.set("disablePagination", 1);
        } else {
          this.set("disablePagination", 0);
        }
      } else {
        this.set("disablePagination", 0);
      }

      if (this.get('resellers') !== null) {
        this.set("totalRows", this.get('resellers').meta.totalrecords);
      }
    },

    rows: Ember.computed("resellers", function () {
      if (this.get('resellers') !== null) {
        let formattedArray = [];
        this.get('resellers').data.forEach(function (reseller) {
          formattedArray.pushObject({
            tnrelationname: reseller.attributes.tnrelationname,
            tnrelationcity: reseller.attributes.tnrelationcity,
            tncustinternalid1: reseller.attributes.tncustinternalid1,
            tncustname: reseller.attributes.custname,
            tncustrelationid: reseller.attributes.tncustrelationid
          });
        });
        return formattedArray;
      }
    }),
    actions: {
      closeModal() {
        this.closeModal();
      },

      getCustRelations() {
        let reseller = {};
        reseller['resellername'] = this.get('resellername');
        reseller['custinternalid1'] = this.get('custinternalid1');
        this.getCustRelations(reseller, 1);
      },

      addCustRelation() {
        this.addCustRelation();
        this.closeModal();
      },

      nextPage() {
        this.nextPageRelations();
      },

      prevPage() {
        this.prevPageRelations();
      },

      firstPage() {
        this.firstPageRelations();
      },

      lastPage() {
        this.lastPageRelations(this.get("totalPages"));
      }

    }
  });

  _exports.default = _default;
});