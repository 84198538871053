define("ptportal/services/cust-data", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),

    getVendors() {
      if (this.store.peekAll('tnvendor').length > 0) {
        return this.store.peekAll('tnvendor');
      } else {
        return this.store.findAll('tnvendor');
      }
    },

    getPrograms() {
      if (this.store.peekAll('tnvendorprogram').length > 0) {
        return this.store.peekAll('tnvendorprogram');
      } else {
        return this.store.findAll('tnvendorprogram');
      }
    },

    getCustRelations() {
      if (this.store.peekAll('tncustrelation').length > 0) {
        return this.store.peekAll('tncustrelation');
      } else {
        return this.store.findAll('tncustrelation');
      }
    },

    getCustSalesRelations() {
      if (this.store.peekAll('tncustsalesrelation').length > 0) {
        return this.store.peekAll('tncustsalesrelation');
      } else {
        return this.store.findAll('tncustsalesrelation');
      }
    },

    // :Undefined => 0,
    // :SalesRepIn => 2,
    // :SalesRepOut => 4,
    // :SalesManager => 6,
    // :SalesGroup => 8
    getCustSalesNameGrp() {
      return this.store.query('tncustsalesrelation', {
        TNCUSTSALESTYPE: 8
      });
    },

    getCustSalesNameIn() {
      return this.store.query('tncustsalesrelation', {
        TNCUSTSALESTYPE: 2
      });
    },

    getCustSalesNameOut() {
      return this.store.query('tncustsalesrelation', {
        TNCUSTSALESTYPE: 4
      });
    },

    getCustSalesNameMgr() {
      return this.store.query('tncustsalesrelation', {
        TNCUSTSALESTYPE: 6
      });
    },

    getAddressState() {
      if (this.store.peekAll('addressstate').length > 0) {
        return this.store.peekAll('addressstate');
      } else {
        return this.store.findAll('addressstate');
      }
    },

    getSendOptions() {
      if (this.store.peekAll('tncustsend').length > 0) {
        return this.store.peekAll('tncustsend');
      } else {
        return this.store.findAll('tncustsend');
      }
    },

    getContactPeople() {
      if (this.store.peekAll('tncontactperson').length > 1) {
        return this.store.peekAll('tncontactperson');
      } else {
        return this.store.findAll('tncontactperson');
      }
    }

  });

  _exports.default = _default;
});