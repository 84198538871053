define("ptportal/routes/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    router: Ember.inject.service('router'),
    custdata: Ember.inject.service('cust-data'),
    dashboardDate: null,
    selectedVendor: null,
    bidVolumeReport: null,

    init() {
      this._super(...arguments);

      var today = new Date();
      let emailmonth = today.getMonth() + 1;

      if (emailmonth < 10) {
        emailmonth = '0' + emailmonth;
      }

      let emaildate = today.getDate();

      if (emaildate < 10) {
        emaildate = '0' + emaildate;
      }

      var date = today.getFullYear() + '-' + emailmonth + '-' + emaildate;
      this.set("dashboardDate", date);
      var self = this;
      setInterval(function () {
        self.refreshMyData();
      }, 300000);
    },

    refreshMyData: function () {
      this.refresh();
    },
    beforeModel: function () {
      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      this.get('store').queryRecord('tncontactperson', {
        tnuserid: 'me'
      }).then(tncontactperson => {
        this.set('isAccessDashboard', tncontactperson.accessDashboard);

        if (tncontactperson.accessDashboard) {
          return true;
        } else if (tncontactperson.accessBidExplorer) {
          this.get('router').transitionTo('bidexplorer');
        } else if (tncontactperson.accessExceptions) {
          this.get('router').transitionTo('exceptions');
        } else if (tncontactperson.accessEmail) {
          this.get('router').transitionTo('email');
        } else if (tncontactperson.accessBidEditor) {
          this.get('router').transitionTo('bideditor');
        } else if (tncontactperson.accessMyCustomers) {
          this.get('router').transitionTo('mycustomers');
        } else if (tncontactperson.accessPrograms) {
          this.get('router').transitionTo('programs');
        } else if (tncontactperson.accessCustomerEditor) {
          this.get('router').transitionTo('editcustomers');
        } else if (tncontactperson.accessUserManagement) {
          this.get('router').transitionTo('tncontactpeople');
        } else {
          this.get('router').transitionTo('noaccess');
        }
      });
    },

    model() {
      // Build Query End
      let dashboard = [];
      let recentBids = [];
      let vendorStats = [];
      let vendors = [];
      let tnvendid = null;

      if (this.get("selectedVendor") !== null) {
        tnvendid = this.get("selectedVendor").tnvendid;
      }

      dashboard = this.get('ajax').request('dashboard_data', {
        type: 'GET',
        data: {
          TNPRICECREATEDATE: this.get("dashboardDate"),
          tnvendid: tnvendid,
          bidStats: 1
        }
      });
      recentBids = this.get('ajax').request('dashboard_data', {
        type: 'GET',
        data: {
          TNPRICECREATEDATE: this.get("dashboardDate"),
          showRecentBids: 1
        }
      });
      vendorStats = this.get('ajax').request('dashboard_data', {
        type: 'GET',
        data: {
          TNPRICECREATEDATE: this.get("dashboardDate"),
          showVendorStats: 1
        }
      });
      vendors = this.custdata.getVendors(); // retrieve from service

      return Ember.RSVP.hash({
        dashboard: dashboard,
        recentBids: recentBids,
        vendorStats: vendorStats,
        vendors: vendors,
        bidVolumeReport: this.get("bidVolumeReport")
      });
    },

    actions: {
      getBidVolumeReport(bidvolfilters) {
        this.set("bidVolumeReport", null);
        var query = {};

        if (bidvolfilters.receiptDate) {
          if (bidvolfilters.receiptDate.length == 1) {
            let receiptdate = bidvolfilters.receiptDate[0];
            receiptdate = receiptdate.getFullYear() + "-" + (receiptdate.getMonth() + 1) + "-" + receiptdate.getDate();
            query['TNPRICECREATEDATE'] = receiptdate;
          } else {
            query['TNPRICECREATEDATE'] = bidvolfilters.receiptDate;
          }
        } else {
          return;
        }

        if (bidvolfilters.receiptDateFrom) {
          if (bidvolfilters.receiptDateFrom.length == 1) {
            let receiptdatefrom = bidvolfilters.receiptDateFrom[0];
            receiptdatefrom = receiptdatefrom.getFullYear() + "-" + (receiptdatefrom.getMonth() + 1) + "-" + receiptdatefrom.getDate();
            query['TNPRICECREATEDATEFROM'] = receiptdatefrom;
          } else {
            query['TNPRICECREATEDATEFROM'] = bidvolfilters.receiptDateFrom;
          }
        } else {
          return;
        }

        query['showVendorStats'] = 1;
        this.get('ajax').request('dashboard_data', {
          type: 'GET',
          data: query
        }).then(data => {
          if (data.data) {
            if (data.data.length === 0) {
              (0, _sweetalert.default)('No Records Found', 'There were no bids found between the date range selected.', 'warning');
            }
          } else {
            this.set("bidVolumeReport", data);
          }

          this.refresh();
        });
      },

      exportReport() {
        let formattedArray = [];
        formattedArray.pushObject(["Vendor Name", "Program Name", "Volume"]);

        if (this.get("bidVolumeReport") !== null) {
          this.get("bidVolumeReport").forEach(function (data) {
            formattedArray.pushObject([data.name, data.programname, data.value]);
          });
        }

        let type = "CSV";

        if (type === 'Excel') {
          this.get('excel').export(formattedArray, {
            sheetName: 'Bid Volume By Vendor',
            fileName: 'bid-volume-by-vendor.xlsx'
          });
        } else if (type === 'CSV') {
          this.get('csv').export(formattedArray, {
            fileName: 'bid-volume-by-vendor.csv'
          });
        }
      },

      clearFilters() {
        this.set("bidVolumeReport", null);
        this.refresh();
      },

      updateDashboardRoute(date, vendor) {
        this.set("dashboardDate", date);
        this.set("selectedVendor", vendor);
        this.refresh();
      },

      selectLatestBid(priceid) {
        this.get('router').transitionTo('bidexplorer', {
          queryParams: {
            priceid: priceid
          }
        });
      },

      openEmail(status) {
        this.get('router').transitionTo('email', {
          queryParams: {
            receiptDate: this.get("dashboardDate"),
            receiptDateFrom: this.get("dashboardDate"),
            emailstatus: status
          }
        });
      }

    }
  });

  _exports.default = _default;
});