define("ptportal/templates/tncontactpeople", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s828uoGc",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"page-content p-4 p-sm-6\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"contacts-list card\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,79,26,[27,[26,1,\"CallHead\"],[]],null,[[\"model\"],[[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"usermanagement/users-table\"]}",
    "meta": {
      "moduleName": "ptportal/templates/tncontactpeople.hbs"
    }
  });

  _exports.default = _default;
});