define("ptportal/components/editcustomers/editcust-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    custdata: Ember.inject.service('cust-data'),
    customername: null,
    customernumber: null,
    email: null,
    city: null,
    selectedSalesRelationGrp: null,
    selectedSalesRelationIn: null,
    selectedSalesRelationOut: null,
    selectedSalesRelationMgr: null,
    selectedCustomerType: null,
    selectedStateProv: null,
    custrelationid: null,

    init() {
      this._super(...arguments); // this.getCustSalesNameGrp = this.custdata.getCustSalesNameGrp();
      // this.getCustSalesNameIn = this.custdata.getCustSalesNameIn();
      // this.getCustSalesNameOut = this.custdata.getCustSalesNameOut();
      // this.getCustSalesNameMgr = this.custdata.getCustSalesNameMgr();


      this.customerType = ['Customer', 'Distributor', 'Vendor', 'Reseller'];
      this.stateProv = this.custdata.getAddressState();

      if (this.get('custsalesrelations') !== undefined && this.get('custsalesrelations') !== null) {
        let custSalesNameGrp = [];
        let getCustSalesNameIn = [];
        let getCustSalesNameOut = [];
        let getCustSalesNameMgr = [];
        this.get('custsalesrelations').data.forEach(function (salesrelation) {
          if (salesrelation.attributes.tncustsalestype === 8) {
            custSalesNameGrp.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesgrpname: salesrelation.attributes.tncustsalesname
            });
          }

          if (salesrelation.attributes.tncustsalestype === 2) {
            getCustSalesNameIn.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesinname: salesrelation.attributes.tncustsalesname
            });
          }

          if (salesrelation.attributes.tncustsalestype === 4) {
            getCustSalesNameOut.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesoutname: salesrelation.attributes.tncustsalesname
            });
          }

          if (salesrelation.attributes.tncustsalestype === 6) {
            getCustSalesNameMgr.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesmgrname: salesrelation.attributes.tncustsalesname
            });
          }
        });
        this.getCustSalesNameGrp = custSalesNameGrp;
        this.getCustSalesNameIn = getCustSalesNameIn;
        this.getCustSalesNameOut = getCustSalesNameOut;
        this.getCustSalesNameMgr = getCustSalesNameMgr;
      }
    },

    didReceiveAttrs() {
      this.set('customername', this.customers.tnrelationname);
      this.set('customernumber', this.customers.tncustinternalid1);
      this.set('email', this.customers.tnrelationemail);
      this.set('city', this.customers.tnrelationcity);
      this.set('selectedSalesRelationGrp', this.customers);
      this.set('selectedSalesRelationIn', this.customers);
      this.set('selectedSalesRelationOut', this.customers);
      this.set('selectedSalesRelationMgr', this.customers);
      this.set('selectedCustomerType', this.customers.tnparsedatatype);
      this.set('selectedStateProv', this.customers);
      this.set('custrelationid', this.customers.tncustrelationid);
    },

    actions: {
      closeModal() {
        this.closeModal();
      },

      updateCustomer() {
        var customer = {};
        customer['custRelationid'] = this.get('custrelationid');
        customer['customername'] = this.get('customername');
        customer['customernumber'] = this.get('customernumber');
        customer['email'] = this.get('email');
        customer['city'] = this.get('city');
        customer['SalesRelationGrp'] = this.get('selectedSalesRelationGrp');
        customer['SalesRelationIn'] = this.get('selectedSalesRelationIn');
        customer['SalesRelationOut'] = this.get('selectedSalesRelationOut');
        customer['SalesRelationMgr'] = this.get('selectedSalesRelationMgr');
        customer['CustomerType'] = this.get('selectedCustomerType');
        customer['StateProv'] = this.get('selectedStateProv');
        this.updateCustomer(customer);
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});