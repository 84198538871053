define("ptportal/routes/noaccess", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    session: Ember.inject.service('session'),
    router: Ember.inject.service('router'),
    swal: Ember.inject.service(),

    init() {
      this._super(...arguments);

      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      (0, _sweetalert.default)('No Access Found', 'You do not have access to any of the Screens. Please contact your administrator to provide you access to the required screens.', 'error');
    }

  });

  _exports.default = _default;
});