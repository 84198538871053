define("ptportal/templates/components/bidexplorer/be-biddelta-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nsYLoy55",
    "block": "{\"symbols\":[\"tnbiddelta\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"table-responsive overview-table-wrapper tableFixHead\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"table\",true],[12,\"class\",\"table table-hover\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"thead\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"tr\",true],[10],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Line\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Vendor Part\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Part Description\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Qty\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Bid Price\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Line Start\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Line End\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Product Line\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Discount %\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Delta Type\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Delta Change\"],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"tbody\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[1,0,0,0,[31,513,27,[27,[26,0,\"CallHead\"],[]],null,[[\"tnbiddelta\"],[[27,[24,1],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"bidexplorer/be-biddelta-row\",\"rows\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/bidexplorer/be-biddelta-tab.hbs"
    }
  });

  _exports.default = _default;
});