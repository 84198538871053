define("ptportal/components/email/email-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    custdata: Ember.inject.service('cust-data'),
    router: Ember.inject.service('router'),
    emailid: "",
    subject: "",
    selectedVendor: "",
    selectedStatus: "All",
    showUnresolved: false,
    receiptDate: "",
    receiptDateFrom: "",
    dateToday: null,

    init() {
      this._super(...arguments);

      this.emailstatus = ['All', 'Unparsed', 'Successful', 'Unsuccessful', 'Unknown Bid', 'Hold', 'Force Process', 'Ignore', 'Unparsed Rails'];
      this.vendors = this.custdata.getVendors();
      var today = new Date();
      let emailmonth = today.getMonth() + 1;

      if (emailmonth < 10) {
        emailmonth = '0' + emailmonth;
      }

      let emaildate = today.getDate();

      if (emaildate < 10) {
        emaildate = '0' + emaildate;
      }

      var date = today.getFullYear() + '-' + emailmonth + '-' + emaildate;
      this.set("dateToday", date);
      this.set("receiptDate", date);
      this.set("receiptDateFrom", date);

      if (this.get('model') != null) {
        if (this.model.emailid) {
          this.set('emailid', this.model.emailid);
        }

        if (this.model.tnvendvalue) {
          this.set('selectedVendor', this.model);
        }

        if (this.model.subject) {
          this.set('subject', this.model.subject);
        }

        if (this.model.emailstatus) {
          this.set('selectedStatus', this.model.emailstatus);
        }

        if (this.model.receiptDate) {
          this.set('receiptDate', this.model.receiptDate);
        }

        if (this.model.receiptDateFrom) {
          this.set('receiptDateFrom', this.model.receiptDateFrom);
        }

        if (this.model.showUnresolved) {
          this.set('showUnresolved', true);
        }
      }
    },

    actions: {
      togglecdCalendar() {
        this.flatpickrRef1.toggle();
      },

      togglecdfromCalendar() {
        this.flatpickrRef2.toggle();
      },

      updateFilters() {
        var emailfilters = {};

        if (this.selectedVendor == null) {
          this.set("selectedVendor", "");
        }

        emailfilters['emailid'] = this.emailid, emailfilters['subject'] = this.subject, emailfilters['tnvendid'] = this.selectedVendor.tnvendid, emailfilters['tnvendvalue'] = this.selectedVendor.tnvendvalue, emailfilters['emailstatus'] = this.selectedStatus, emailfilters['receiptDate'] = this.receiptDate, emailfilters['receiptDateFrom'] = this.receiptDateFrom, emailfilters['showUnresolved'] = this.showUnresolved ? 1 : 0, this.searchEmail(emailfilters, 1);
      },

      clearFilters() {
        this.set("emailid", "");
        this.set("subject", "");
        this.set("selectedVendor", "");
        this.set("selectedStatus", "");
        this.set('showUnresolved', false);
        this.set("receiptDate", this.get("dateToday"));
        this.set("receiptDateFrom", this.get("dateToday"));
        this.clearFilters();
      }

    }
  });

  _exports.default = _default;
});