define("ptportal/models/tnpriceexception", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tnprice: _emberData.default.belongsTo('tnprice'),
    tncust: _emberData.default.belongsTo('tncust'),
    tnvendor: _emberData.default.belongsTo('tnvendor'),
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    tnpriceid: _emberData.default.attr('string'),
    tnpriceexceptiontype: _emberData.default.attr('number'),
    tnpriceexceptionid: _emberData.default.attr('string'),
    tnpriceexceptionstatus: _emberData.default.attr('number'),
    tnpriceexceptionresolvedate: _emberData.default.attr('string'),
    tnpriceexceptionresolvetime: _emberData.default.attr('number'),
    tncontactpersonid: _emberData.default.attr('string'),
    tnparselogicid: _emberData.default.attr('string'),
    tnpriceexceptiondesc: _emberData.default.attr('string'),
    tnlinenumber: _emberData.default.attr('number'),
    tnparsedatatype: _emberData.default.attr('number'),
    tnpricerelationid: _emberData.default.attr('string'),
    tnpriceexceptionresolvestr: _emberData.default.attr('string'),
    tncustid: _emberData.default.attr('string'),
    tnvendpart: _emberData.default.attr('string'),
    tnvendid: _emberData.default.attr('string'),
    relationname: _emberData.default.attr('string'),
    resolvetime: _emberData.default.attr('date'),
    fieldtype: _emberData.default.attr('string'),
    resolvedby: _emberData.default.attr('string')
  });

  _exports.default = _default;
});