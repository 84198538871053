define("ptportal/components/bideditor/bideditor-bidlines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addMode: false,
    rows: Ember.computed('model', function () {
      if (this.get('model') != null) {
        if (this.get('model').length !== undefined) {
          let formattedArray = [];
          this.get('model').forEach(function (tnbidlines) {
            // let linestartdate = tnbidlines.tnlinestartdate;
            // if(linestartdate.getFullYear() <= 1900){
            //   linestartdate = ""
            // }
            // else{
            //   let linestartdatemm = (linestartdate.getMonth()+1);
            //   if(linestartdatemm<10){
            //     linestartdatemm = '0'+linestartdatemm
            //   }
            //
            //   let linestartdatedd = (linestartdate.getDate()+1);
            //   if(linestartdatedd<10){
            //     linestartdatedd = '0'+linestartdatedd
            //   }
            //   linestartdate = linestartdate.getFullYear() + "-" + linestartdatemm + "-" + linestartdatedd;
            // }
            // let lineenddate = tnbidlines.tnlineenddate;
            // if(lineenddate.getFullYear() <= 1900){
            //  lineenddate = ""
            //  }
            // else{
            //   let lineenddatemm = (lineenddate.getMonth()+1);
            //   if(lineenddatemm<10){
            //     lineenddatemm = '0'+lineenddatemm
            //   }
            //
            //   let lineenddatedd = (lineenddate.getDate()+1);
            //   if(lineenddatedd<10){
            //     lineenddatedd = '0'+lineenddatedd
            //   }
            //   lineenddate = lineenddate.getFullYear() + "-" + lineenddatemm + "-" + lineenddatedd;
            //  }
            // // console.log("Part",tnbidlines.tnvendpart);
            // // console.log("Date",tnbidlines.tnlinestartdate);
            // // console.log("Date Parsed",linestartdate);
            let linestartdate = tnbidlines.tnlinestartdate.replace(/T[0-9][0-9]:00:00.000Z/, '');

            if (linestartdate == '1900-01-01') {
              linestartdate = "";
            }

            let lineenddate = tnbidlines.tnlineenddate.replace(/T[0-9][0-9]:00:00.000Z/, '');

            if (lineenddate == '1900-01-01') {
              lineenddate = "";
            }

            let linestatus = tnbidlines.tnlinestatus;

            switch (linestatus) {
              case 0:
                linestatus = 'Current';
                break;

              case 2:
                linestatus = 'Historical';
                break;

              default:
                linestatus = '';
            }

            let parsedatatype = tnbidlines.tnparsedatatype;

            switch (parsedatatype) {
              case 2:
                parsedatatype = 'Header';
                break;

              case 4:
                parsedatatype = 'Part';
                break;

              case 6:
                parsedatatype = 'PartLine';
                break;

              case 8:
                parsedatatype = 'Customer';
                break;

              case 10:
                parsedatatype = 'Distributor';
                break;

              case 12:
                parsedatatype = 'Vendor';
                break;

              case 14:
                parsedatatype = 'Reseller';
                break;

              case 16:
                parsedatatype = 'Bundle';
                break;

              case 18:
                parsedatatype = 'Config';
                break;

              case 20:
                parsedatatype = 'PartFamily';
                break;

              case 22:
                parsedatatype = 'PartTwo';
                break;

              case 24:
                parsedatatype = 'Bid';
                break;

              case 26:
                parsedatatype = 'PartLineTwo';
                break;

              default:
                parsedatatype = 'Undefined';
            }

            formattedArray.pushObject({
              tnpricelineid: tnbidlines.tnpricelineid,
              tnvendpart: tnbidlines.tnvendpart,
              tnvendpartdesc: tnbidlines.tnvendpartdesc,
              tndiscountpercentage: tnbidlines.tndiscountpercentage.toFixed(2),
              tnquantity: tnbidlines.tnquantity,
              tnquantitymax: tnbidlines.tnquantitymax,
              tnquantitymin: tnbidlines.tnquantitymin,
              tnlinestartdate: linestartdate,
              tnlineenddate: lineenddate,
              tnpricelist: tnbidlines.tnpricelist.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
              tnrebate: tnbidlines.tnrebate.toFixed(2),
              tnpricebid: tnbidlines.tnpricebid.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
              tnvendpartline: tnbidlines.tnvendpartline,
              tnlinenumber: tnbidlines.tnlinenumber,
              tnlinestatus: linestatus,
              tnlinepricetype: tnbidlines.tnlinepricetype,
              tnparsedatatype: parsedatatype,
              tnpartconfigid: tnbidlines.tnpartconfigid
            });
          });
          return formattedArray;
        }
      }
    }),
    actions: {
      addBidline() {
        this.toggleProperty("addMode");
      }

    }
  });

  _exports.default = _default;
});