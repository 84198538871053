define("ptportal/components/editcustomers/editcust-salesrelations-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    editMode: false,
    actions: {
      removeSalesRelation(salesrelationid) {
        this.removeSalesRelation(salesrelationid);
      },

      editSalesRelation() {
        this.toggleProperty("editMode");
      }

    }
  });

  _exports.default = _default;
});