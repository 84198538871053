define("ptportal/components/bideditor/bideditor-bidlines-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pricelineid: null,
    selectedDataType: null,
    vendorpart: null,
    partdescription: null,
    bidprice: null,
    listprice: null,
    quantity: null,
    startdate: null,
    enddate: null,
    discountpercentage: null,
    rebate: null,
    minqty: null,
    maxqty: null,
    partconfigid: null,
    pricetype: null,
    partline: null,

    init() {
      this._super(...arguments);

      this.dataType = ['Part', 'PartLine', 'Bundle', 'Config', 'PartFamily'];
    },

    didReceiveAttrs() {
      this.set('pricelineid', this.tnbidline.tnpricelineid);
      this.set('selectedDataType', this.tnbidline.tnparsedatatype);
      this.set('vendorpart', this.tnbidline.tnvendpart);
      this.set('partdescription', this.tnbidline.tnvendpartdesc);
      this.set('bidprice', this.tnbidline.tnpricebid);
      this.set('listprice', this.tnbidline.tnpricelist);
      this.set('quantity', this.tnbidline.tnquantity);
      this.set('startdate', this.tnbidline.tnlinestartdate);
      this.set('enddate', this.tnbidline.tnlineenddate);
      this.set('discountpercentage', this.tnbidline.tndiscountpercentage);
      this.set('rebate', this.tnbidline.tnrebate);
      this.set('minqty', this.tnbidline.tnquantitymin);
      this.set('maxqty', this.tnbidline.tnquantitymax);
      this.set('partconfigid', this.tnbidline.tnpartconfigid);
      this.set('pricetype', this.tnbidline.tnlinepricetype);
      this.set('partline', this.tnbidline.tnvendpartline);
    },

    actions: {
      closeModal() {
        this.closeModal();
      },

      updateBidline() {
        var bidline = {};
        bidline['pricelineid'] = this.get('pricelineid');

        if (this.get('selectedDataType') !== this.tnbidline.tnparsedatatype) {
          bidline['selectedDataType'] = this.get('selectedDataType');
        }

        if (this.get('vendorpart') !== this.tnbidline.tnvendpart) {
          bidline['vendorpart'] = this.get('vendorpart');
        }

        if (this.get('partdescription') !== this.tnbidline.tnvendpartdesc) {
          bidline['partdescription'] = this.get('partdescription');
        }

        if (this.get('bidprice') !== this.tnbidline.tnpricebid) {
          bidline['bidprice'] = this.get('bidprice');
        }

        if (this.get('listprice') !== this.tnbidline.tnpricelist) {
          bidline['listprice'] = this.get('listprice');
        }

        if (this.get('quantity') !== this.tnbidline.tnquantity) {
          bidline['quantity'] = this.get('quantity');
        }

        if (this.get('startdate') !== this.tnbidline.tnlinestartdate) {
          bidline['startdate'] = this.get('startdate');
        }

        if (this.get('enddate') !== this.tnbidline.tnlineenddate) {
          bidline['enddate'] = this.get('enddate');
        }

        if (this.get('discountpercentage') !== this.tnbidline.tndiscountpercentage) {
          bidline['discountpercentage'] = this.get('discountpercentage');
        }

        if (this.get('rebate') !== this.tnbidline.tnrebate) {
          bidline['rebate'] = this.get('rebate');
        }

        if (this.get('minqty') !== this.tnbidline.tnquantitymin) {
          bidline['minqty'] = this.get('minqty');
        }

        if (this.get('maxqty') !== this.tnbidline.tnquantitymax) {
          bidline['maxqty'] = this.get('maxqty');
        }

        if (this.get('partconfigid') !== this.tnbidline.tnpartconfigid) {
          bidline['partconfigid'] = this.get('partconfigid');
        }

        if (this.get('pricetype') !== this.tnbidline.tnlinepricetype) {
          bidline['pricetype'] = this.get('pricetype');
        }

        if (this.get('partline') !== this.tnbidline.tnvendpartline) {
          bidline['partline'] = this.get('partline');
        }

        this.updateBidline(bidline);
        this.closeModal();
      },

      togglesdCalendar() {
        this.flatpickrRef1.toggle();
      },

      toggleedCalendar() {
        this.flatpickrRef2.toggle();
      },

      clearsdCalendar() {
        this.flatpickrRef1.clear();
      },

      clearedCalendar() {
        this.flatpickrRef2.clear();
      }

    }
  });

  _exports.default = _default;
});