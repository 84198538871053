define("ptportal/components/bidexplorer/bid-explorer-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    custdata: Ember.inject.service('cust-data'),
    router: Ember.inject.service('router'),
    expandSearch: false,
    addMode: false,
    bidNumber: "",
    selectedVendor: "",
    selectedProgram: "",
    //selectedRelation: "",
    selectedSalesRelationGrp: "",
    selectedSalesRelationIn: "",
    selectedSalesRelationOut: "",
    selectedSalesRelationMgr: "",
    selectedStatus: "All",
    startDate: "",
    endDate: "",
    createDate: "",
    dealRevision: "",
    latestRevision: false,
    isProd: false,
    priceid: "",
    quoteNumber: "",
    vendPart: "",
    productLine: "",
    relationName: "",
    endCustName: "",
    selectedReseller: "",
    selectedResellerTemp: "",

    init() {
      this._super(...arguments);

      this.bidstatus = ['All', 'Active', 'Expired']; // retrieve from service
      //this.custrelations = this.custdata.getCustRelations();

      this.vendors = this.custdata.getVendors();
      this.programs = this.custdata.getPrograms(); // this.getCustSalesNameGrp = this.custdata.getCustSalesNameGrp();
      // this.getCustSalesNameIn = this.custdata.getCustSalesNameIn();
      // this.getCustSalesNameOut = this.custdata.getCustSalesNameOut();
      // this.getCustSalesNameMgr = this.custdata.getCustSalesNameMgr();

      if (this.get('custsalesrelations') !== undefined && this.get('custsalesrelations') !== null) {
        let custSalesNameGrp = [];
        let getCustSalesNameIn = [];
        let getCustSalesNameOut = [];
        let getCustSalesNameMgr = [];
        this.get('custsalesrelations').data.forEach(function (salesrelation) {
          if (salesrelation.attributes.tncustsalestype === 8) {
            custSalesNameGrp.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesgrpname: salesrelation.attributes.tncustsalesname
            });
          }

          if (salesrelation.attributes.tncustsalestype === 2) {
            getCustSalesNameIn.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesinname: salesrelation.attributes.tncustsalesname
            });
          }

          if (salesrelation.attributes.tncustsalestype === 4) {
            getCustSalesNameOut.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesoutname: salesrelation.attributes.tncustsalesname
            });
          }

          if (salesrelation.attributes.tncustsalestype === 6) {
            getCustSalesNameMgr.pushObject({
              tncustsalesrelationid: salesrelation.attributes.tncustsalesrelationid,
              tncustsalesmgrname: salesrelation.attributes.tncustsalesname
            });
          }
        });
        this.getCustSalesNameGrp = custSalesNameGrp;
        this.getCustSalesNameIn = getCustSalesNameIn;
        this.getCustSalesNameOut = getCustSalesNameOut;
        this.getCustSalesNameMgr = getCustSalesNameMgr;
      }

      if (this.get('model') != null) {
        if (this.model.priceid) {
          this.set('priceid', this.model.priceid);
        }

        if (this.model.bidNumber) {
          this.set('bidNumber', this.model.bidNumber);
        }

        if (this.model.tnvendvalue) {
          this.set('selectedVendor', this.model);
        }

        if (this.model.tnprogramvalue) {
          this.set('selectedProgram', this.model);
        }

        if (this.model.bidStatus) {
          this.set('selectedStatus', this.model.bidStatus);
        }

        if (this.model.startDate) {
          this.set('startDate', this.model.startDate);
        }

        if (this.model.endDate) {
          this.set('endDate', this.model.endDate);
        }

        if (this.model.createDate) {
          this.set('createDate', this.model.createDate);
        }

        if (this.model.dealRevision) {
          this.set('dealRevision', this.model.dealRevision);
        }

        if (this.model.latestRevision) {
          this.set('latestRevision', true);
        }

        if (this.model.isProd) {
          this.set('isProd', true);
        }

        if (this.model.quoteNumber) {
          this.set('quoteNumber', this.model.quoteNumber);
        }

        if (this.model.endCustName) {
          this.set('endCustName', this.model.endCustName);
        }

        if (this.model.tnrelationname) {
          this.set('selectedReseller', this.model.tnrelationname);
        }

        if (this.model.tncustsalesgrpname) {
          this.set('selectedSalesRelationGrp', this.model);
        }

        if (this.model.tncustsalesinname) {
          this.set('selectedSalesRelationIn', this.model);
        }

        if (this.model.tncustsalesoutname) {
          this.set('selectedSalesRelationOut', this.model);
        }

        if (this.model.tncustsalesmgrname) {
          this.set('selectedSalesRelationMgr', this.model);
        }
      }
    },

    actions: {
      expandSearch() {
        this.toggleProperty("expandSearch");
      },

      changereseller() {},

      changevendor() {},

      changeprogram() {},

      changein() {},

      changeout() {},

      changemgr() {},

      changegrp() {},

      togglesdCalendar() {
        this.flatpickrRef1.toggle();
      },

      toggleedCalendar() {
        this.flatpickrRef2.toggle();
      },

      togglecdCalendar() {
        this.flatpickrRef3.toggle();
      },

      clearsdCalendar() {
        this.set("startDate", "");
      },

      clearedCalendar() {
        this.set("endDate", "");
      },

      clearcdCalendar() {
        this.set("createDate", "");
      },

      addReseller() {
        this.toggleProperty("addMode");
        this.clearCustRelations();
      },

      addCustRelation() {
        this.set("selectedReseller", this.get("selectedResellerTemp"));
      },

      selectResellerName(resellername) {
        this.set("selectedResellerTemp", resellername);
      },

      clearReseller() {
        this.set("selectedReseller", "");
      },

      updateFilters() {
        var bidexplorerfilters = {};

        if (this.selectedVendor == null) {
          this.set("selectedVendor", "");
        }

        if (this.selectedProgram == null) {
          this.set("selectedProgram", "");
        }

        if (this.selectedReseller == null) {
          this.set("selectedRelation", "");
        }

        if (this.selectedSalesRelationGrp == null) {
          this.set("selectedSalesRelationGrp", "");
        }

        if (this.selectedSalesRelationIn == null) {
          this.set("selectedSalesRelationIn", "");
        }

        if (this.selectedSalesRelationOut == null) {
          this.set("selectedSalesRelationOut", "");
        }

        if (this.selectedSalesRelationMgr == null) {
          this.set("selectedSalesRelationMgr", "");
        }

        bidexplorerfilters['priceid'] = this.priceid, bidexplorerfilters['bidNumber'] = this.bidNumber, bidexplorerfilters['vendID'] = this.selectedVendor.tnvendid, bidexplorerfilters['tnvendvalue'] = this.selectedVendor.tnvendvalue, bidexplorerfilters['programID'] = this.selectedProgram.tnprogramid, bidexplorerfilters['tnprogramvalue'] = this.selectedProgram.tnprogramvalue, bidexplorerfilters['tnrelationname'] = this.selectedReseller, bidexplorerfilters['salesRelation'] = this.selectedSalesRelation, bidexplorerfilters['bidStatus'] = this.selectedStatus, bidexplorerfilters['startDate'] = this.startDate, bidexplorerfilters['endDate'] = this.endDate, bidexplorerfilters['createDate'] = this.createDate, bidexplorerfilters['dealRevision'] = this.dealRevision, bidexplorerfilters['latestRevision'] = this.latestRevision ? 1 : 0, bidexplorerfilters['isProd'] = this.isProd ? 1 : 0, bidexplorerfilters['quoteNumber'] = this.quoteNumber, bidexplorerfilters['vendPart'] = this.vendPart, bidexplorerfilters['productLine'] = this.productLine, bidexplorerfilters['endCustName'] = this.endCustName, bidexplorerfilters['selectedSalesRelationGrp'] = this.selectedSalesRelationGrp.tncustsalesrelationid, bidexplorerfilters['tncustsalesgrpname'] = this.selectedSalesRelationGrp.tncustsalesgrpname, bidexplorerfilters['selectedSalesRelationIn'] = this.selectedSalesRelationIn.tncustsalesrelationid, bidexplorerfilters['tncustsalesinname'] = this.selectedSalesRelationIn.tncustsalesinname, bidexplorerfilters['selectedSalesRelationOut'] = this.selectedSalesRelationOut.tncustsalesrelationid, bidexplorerfilters['tncustsalesoutname'] = this.selectedSalesRelationOut.tncustsalesoutname, bidexplorerfilters['selectedSalesRelationMgr'] = this.selectedSalesRelationMgr.tncustsalesrelationid, bidexplorerfilters['tncustsalesmgrname'] = this.selectedSalesRelationMgr.tncustsalesmgrname, this.searchBids(bidexplorerfilters, 1);
      },

      clearFilters() {
        this.set("bidNumber", "");
        this.set("selectedVendor", "");
        this.set("selectedProgram", "");
        this.set("selectedReseller", "");
        this.set("selectedSalesRelationGrp", "");
        this.set("selectedSalesRelationIn", "");
        this.set("selectedSalesRelationOut", "");
        this.set("selectedSalesRelationMgr", "");
        this.set("selectedStatus", "All");
        this.set("dealRevision", "");
        this.set("priceid", "");
        this.set("quoteNumber", "");
        this.set("latestRevision", false);
        this.set("isProd", false);
        this.set("vendPart", "");
        this.set("productLine", "");
        this.set("relationName", "");
        this.set("endCustName", "");
        this.set("startDate", "");
        this.set("endDate", "");
        this.set("createDate", "");
        this.clearFilters();
      }

    }
  });

  _exports.default = _default;
});