define("ptportal/components/programs/programs-marketing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    emailfrom: null,
    emailinternal: null,
    comment1: null,
    comment2: null,
    comment3: null,
    marketing1: null,
    marketing2: null,
    marketing3: null,

    didReceiveAttrs() {
      if (this.model != null) {
        this.set("emailfrom", this.get('model').data.attributes.tnnotificationemailfrom);
        this.set("emailinternal", this.get('model').data.attributes.tnnotificationemailinternal);
        this.set("comment1", this.get('model').data.attributes.tncommenttext1);
        this.set("comment2", this.get('model').data.attributes.tncommenttext2);
        this.set("comment3", this.get('model').data.attributes.tncommenttext3);
        this.set("marketing1", this.get('model').data.attributes.tnmarketingtext1);
        this.set("marketing2", this.get('model').data.attributes.tnmarketingtext2);
        this.set("marketing3", this.get('model').data.attributes.tnmarketingtext3);
      }
    },

    actions: {
      editMarketing() {
        var marketing = {};
        marketing['emailfrom'] = this.get('emailfrom');
        marketing['emailinternal'] = this.get('emailinternal');
        marketing['comment1'] = this.get('comment1');
        marketing['comment2'] = this.get('comment2');
        marketing['comment3'] = this.get('comment3');
        marketing['marketing1'] = this.get('marketing1');
        marketing['marketing2'] = this.get('marketing2');
        marketing['marketing3'] = this.get('marketing3');
        this.updateMarketing(marketing);
      }

    }
  });

  _exports.default = _default;
});