define("ptportal/components/programs/programs-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rows: Ember.computed('model', function () {
      if (this.get('model').length !== undefined) {
        let formattedArray = [];
        this.get('model').forEach(function (tncustprograms) {
          formattedArray.pushObject({
            tncustprogramid: tncustprograms.TNCUSTPROGRAMID,
            tnvendid: tncustprograms.TNVENDID,
            tnvendvalue: tncustprograms.TNVENDVALUE,
            tnprogramid: tncustprograms.TNPROGRAMID,
            tncustprogramvalue: tncustprograms.TNPROGRAMVALUE,
            tncustinternalid1: tncustprograms.TNCUSTINTERNALID1,
            tncustprogrammarkup: tncustprograms.TNCUSTPROGRAMMARKUP
          });
        });
        return formattedArray;
      }
    })
  });

  _exports.default = _default;
});