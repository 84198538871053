define("ptportal/components/editcustomers/editcust-overview-rows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    editMode: false,
    actions: {
      removeCustomer(tncustrelationid) {
        this.removeCustomer(tncustrelationid);
      },

      editCustomer() {
        this.toggleProperty("editMode");
      }

    }
  });

  _exports.default = _default;
});