define("ptportal/components/bidexplorer/be-relation-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rows: Ember.computed('model', function () {
      if (this.get('model').length !== undefined) {
        let formattedArray = [];
        this.get('model').forEach(function (tnrelations) {
          let relationtype = tnrelations.tnparsedatatype;

          switch (relationtype) {
            case 2:
              relationtype = 'Header';
              break;

            case 4:
              relationtype = 'Part';
              break;

            case 6:
              relationtype = 'PartLine';
              break;

            case 8:
              relationtype = 'Customer';
              break;

            case 10:
              relationtype = 'Distributor';
              break;

            case 12:
              relationtype = 'Vendor';
              break;

            case 14:
              relationtype = 'Reseller';
              break;

            case 16:
              relationtype = 'Bundle';
              break;

            case 18:
              relationtype = 'Config';
              break;

            case 20:
              relationtype = 'PartFamily';
              break;

            case 22:
              relationtype = 'PartTwo';
              break;

            case 24:
              relationtype = 'Bid';
              break;

            case 26:
              relationtype = 'PartLineTwo';
              break;

            default:
              relationtype = 'Undefined';
          }

          formattedArray.pushObject({
            tnparsedatatype: relationtype,
            tnrelationname: tnrelations.tnrelationname,
            tnrelationaddress: tnrelations.tnrelationaddress,
            tnrelationcity: tnrelations.tnrelationcity,
            tnrelationstate: tnrelations.tnrelationstate,
            tnrelationzipcode: tnrelations.tnrelationzipcode,
            tnrelationvendorid1: tnrelations.tnrelationvendorid1,
            tnrelationvendorid2: tnrelations.tnrelationvendorid2
          });
        });
        return formattedArray;
      }
    })
  });

  _exports.default = _default;
});