define("ptportal/components/exceptions/exceptions-relations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addMode: false,
    rows: Ember.computed('model', function () {
      let iscuscdw = this.get('isCustomerCDW');

      if (this.get('model').length !== undefined) {
        let formattedArray = [];
        this.get('model').forEach(function (tnrelations) {
          let relationtype = tnrelations.tnparsedatatype;
          let relationtypeshow = 14;

          if (iscuscdw) {
            relationtypeshow = 8;
          }

          if (relationtype == relationtypeshow) {
            formattedArray.pushObject({
              tnparsedatatype: relationtype,
              tnrelationname: tnrelations.tnrelationname,
              tnrelationname2: tnrelations.tnrelationname2,
              tnaccountnumber: tnrelations.accountnumber,
              id: tnrelations.id
            });
          }
        });
        return formattedArray;
      }
    }),
    actions: {
      addReseller() {
        this.toggleProperty("addMode");
        this.clearCustRelations();
      }

    }
  });

  _exports.default = _default;
});