define("ptportal/templates/components/bidexplorer/be-email-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NlQwXGi+",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[7,\"bs-modal\",[],[[\"@size\",\"@onHide\"],[\"lg\",[31,30,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"closeModal\"],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[7,[27,[24,1],[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[9,\"h4\",true],[12,\"class\",\"modal-title\",null],[10],[9,\"i\",true],[12,\"class\",\"glyphicon glyphicon-alert\",null],[10],[11],[1,1,0,0,\"Email Body\"],[11],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"],[7,[27,[24,1],[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[9,\"iframe\",true],[12,\"width\",\"100%\",null],[12,\"height\",\"500px\",null],[13,\"srcdoc\",[27,[24,0],[\"emailBody\"]],null],[10],[11],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"],[7,[27,[24,1],[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[7,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[31,333,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"closeModal\"],null],\"danger\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Close\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/bidexplorer/be-email-body.hbs"
    }
  });

  _exports.default = _default;
});