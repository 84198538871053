define("ptportal/components/exceptions/exceptions-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    addMode: false,
    showResolveStr: 0,
    showAddReseller: 0,
    fieldType: "text",
    resolutionStr: "",
    isNumber: 0,

    didReceiveAttrs() {
      let exceptionstatus = this.tnexceptions.tnpriceexceptionstatusraw;
      let exceptiontype = this.tnexceptions.tnpriceexceptiontyperaw;
      let parsedatatype = this.tnexceptions.tnparsedatatyperaw;
      let fieldtype = this.tnexceptions.fieldtype;
      let exceptiondescription = this.tnexceptions.tnpriceexceptiondesc.toLowerCase();

      if (exceptionstatus == 4 || exceptiontype == 10 || exceptiontype == 12) {
        this.set('showResolveStr', 1);
      } else {
        // if(parsedatatype == 8 || parsedatatype == 10 || parsedatatype == 12 || parsedatatype == 14){
        if (parsedatatype == 10 || parsedatatype == 12 || parsedatatype == 14) {
          this.set('showAddReseller', 1);
        } else {
          if (fieldtype == null) {
            if (exceptiondescription.includes("date")) {
              this.set("fieldType", "date");
            } else if (exceptiondescription.includes("quantity") || exceptiondescription.includes("price") || exceptiondescription.includes("revision") || exceptiondescription.includes("discount") || exceptiondescription.includes("%") || exceptiondescription.includes("negative")) {
              this.set("isNumber", 1);
              this.set("fieldType", "number");
            } else {
              this.set('showResolveStr', 1);
            }
          }
        }

        if (fieldtype == "Date") {
          this.set("fieldType", "date");
        }

        if (fieldtype == "Enum" || fieldtype == "Integer" || fieldtype == "Int64" || fieldtype == "Real") {
          this.set("fieldType", "number");
          this.set("isNumber", 1);
        }
      }
    },

    actions: {
      removeException(exceptionId) {
        this.removeException(exceptionId);
      },

      resolveException() {
        this.resolveException(this.tnexceptions.tnexceptionid, this.get("resolutionStr"));
      },

      clearResolveStr() {
        this.set("resolutionStr", "");
      },

      addReseller() {
        this.toggleProperty("addMode");
        this.clearCustRelations();
      },

      addCustRelationException() {
        this.addCustRelationException(this.tnexceptions.tnexceptionid);
      }

    }
  });

  _exports.default = _default;
});