define("ptportal/components/bideditor/bideditor-endusers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addMode: false,
    endusername: null,
    rows: Ember.computed('model', function () {
      if (this.get('model') != null) {
        if (this.get('model').length !== undefined) {
          let formattedArray = [];
          this.get('model').forEach(function (tnrelations) {
            let relationtype = tnrelations.tnparsedatatype;

            if (relationtype == 8) {
              formattedArray.pushObject({
                tnrelationname: tnrelations.tnrelationname,
                id: tnrelations.id
              });
            }
          });
          return formattedArray;
        }
      }
    }),
    actions: {
      addMode() {
        this.toggleProperty("addMode");
        this.set("endusername", null);
      },

      addEndUser() {
        this.addEndUser(this.get('endusername'));
        this.toggleProperty("addMode");
      }

    }
  });

  _exports.default = _default;
});