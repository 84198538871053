define("ptportal/controllers/application", ["exports", "sweetalert2"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    swal: Ember.inject.service(),
    isChangePassword: false,
    currentUser: Ember.inject.service('current-user'),
    tnopassword: null,
    tnpassword: null,
    tncpassword: null,
    validated: false,
    actions: {
      invalidateSession() {
        // window.localStorage.removeItem('axtoken');
        this.get('session').invalidate();
      },

      changePassword() {
        this.toggleProperty("isChangePassword");
        this.set("tnopassword", null);
        this.set("tnpassword", null);
        this.set("tncpassword", null);
        this.set("vlength", "");
        this.set("vlowercase", "");
        this.set("vuppercase", "");
        this.set("vnumber", "");
        this.set("vconfirmpassword", "");
      },

      onPasswordChange() {
        var lengthvalidated;
        var lowervalidated;
        var uppervalidated;
        var digitvalidated;

        if (this.get("tnpassword")) {
          if (this.get("tnpassword").length >= 8) {
            lengthvalidated = true;
            this.set("vlength", "colorgreen");
          } else {
            lengthvalidated = false;
            this.set("vlength", "colorred");
          }

          if (/^(?=.*[a-z]).+$/.test(this.get("tnpassword"))) {
            lowervalidated = true;
            this.set("vlowercase", "colorgreen");
          } else {
            lowervalidated = false;
            this.set("vlowercase", "colorred");
          }

          if (/^(?=.*[A-Z]).+$/.test(this.get("tnpassword"))) {
            uppervalidated = true;
            this.set("vuppercase", "colorgreen");
          } else {
            uppervalidated = false;
            this.set("vuppercase", "colorred");
          }

          if (/^(?=.*[0-9_\W]).+$/.test(this.get("tnpassword"))) {
            digitvalidated = true;
            this.set("vnumber", "colorgreen");
          } else {
            digitvalidated = false;
            this.set("vnumber", "colorred");
          }
        } else {
          this.set("vlength", "");
          this.set("vlowercase", "");
          this.set("vuppercase", "");
          this.set("vnumber", "");
        }

        if (this.get("tncpassword")) {
          if (this.get("tnpassword") !== this.get("tncpassword")) {
            this.set("vconfirmpassword", "colorred");
          } else {
            this.set("vconfirmpassword", "colorgreen");
          }
        } else {
          this.set("vconfirmpassword", "");
        }

        if (lengthvalidated && lowervalidated && uppervalidated && digitvalidated) {
          this.set("validated", true);
        } else {
          this.set("validated", false);
        }
      },

      onCPasswordChange() {
        if (this.get("tncpassword") && this.get("tnpassword")) {
          if (this.get("tnpassword") !== this.get("tncpassword")) {
            this.set("vconfirmpassword", "colorred");
          } else {
            this.set("vconfirmpassword", "colorgreen");
          }
        } else {
          this.set("vconfirmpassword", "");
        }
      },

      updatePassword() {
        if (this.get("tnpassword") == "") {
          this.set("tnpassword", null);
        }

        if (this.get("tncpassword") == "") {
          this.set("tncpassword", null);
        }

        if (this.get("tnpassword") !== this.get("tncpassword")) {
          (0, _sweetalert.default)('Password and Confirm Password did not match', 'Please re-enter the Password and Confirm Password and try again.', 'error');
          return;
        }

        if (this.get("validated")) {
          var changePassword = {};
          changePassword['tnopassword'] = this.get('tnopassword');
          changePassword['tnpassword'] = this.get('tnpassword');
          this.send('updatePasswordRoute', changePassword);
          this.toggleProperty("isChangePassword");
        } else {
          (0, _sweetalert.default)('Password Does Not Meet Requirements', 'Your password needs to be atleast 8 characters, and needs to include one upper case letter, one lowercase letter and a number or special character.', 'error');
          return;
        }
      }

    }
  });

  _exports.default = _default;
});