define("ptportal/templates/components/mycustomers/mycust-overview-rows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZPEuWFyY",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationstatus\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationsource\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[12,\"class\",\"mw200\",null],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationname\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[12,\"class\",\"mw200\",null],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationadress\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationcity\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnvendorid\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnmappedrelationname\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tncustinternalid1\"]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"customers\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/mycustomers/mycust-overview-rows.hbs"
    }
  });

  _exports.default = _default;
});