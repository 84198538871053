define("ptportal/components/programs/programs-products-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    productline: null,
    description: null,
    markup: null,
    actions: {
      closeModal() {
        this.closeModal();
      },

      createMarkup() {
        var markup = {};
        markup['productline'] = this.get('productline');
        markup['description'] = this.get('description');
        markup['markup'] = this.get('markup');
        this.createMarkup(markup);
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});