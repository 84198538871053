define("ptportal/models/tncustprogram", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // tncust: DS.belongsTo('tncust'),
    // tnvendorprogram: DS.belongsTo('tnvendorprogram'),
    // tnvendor: DS.belongsTo('tnvendor'),
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    tnprogramid: _emberData.default.attr('string'),
    tncustid: _emberData.default.attr('string'),
    tncustidentifier: _emberData.default.attr('string'),
    tnvendid: _emberData.default.attr('string'),
    tnactive: _emberData.default.attr('number'),
    tnbidpublishtype: _emberData.default.attr('number'),
    tncustprogrammarkup: _emberData.default.attr('number'),
    tncustprogramid: _emberData.default.attr('string'),
    tncustprogramterms: _emberData.default.attr('string'),
    tnpassword: _emberData.default.attr('string'),
    tnuserid: _emberData.default.attr('string'),
    tnquotesellpricecalculation: _emberData.default.attr('string'),
    tncommenttext1: _emberData.default.attr('string'),
    tnmarketingtext1: _emberData.default.attr('string'),
    tncommenttext2: _emberData.default.attr('string'),
    tnmarketingtext2: _emberData.default.attr('string'),
    tncommenttext3: _emberData.default.attr('string'),
    tnmarketingtext3: _emberData.default.attr('string'),
    tnnotificationemailfrom: _emberData.default.attr('string'),
    tnnotificationemailinternal: _emberData.default.attr('string'),
    tncustiddestination: _emberData.default.attr('string'),
    tnpublishtoprogramdirectory: _emberData.default.attr('number')
  });

  _exports.default = _default;
});