define("ptportal/components/email/email-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageCount: "",
    disableNext: 0,
    totalPages: 0,
    disablePrevious: 0,
    disablePagination: 0,

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('model') !== undefined && this.get('model') !== null && this.get('model') !== {}) {
        let message = "Page " + this.currentPage + " of " + this.model.totalpages;
        this.set("pageCount", message);
        this.set("totalPages", this.model.totalpages);

        if (this.currentPage == this.totalPages) {
          this.set("disableNext", 0);
        }

        if (this.currentPage < this.totalPages) {
          this.set("disableNext", 1);
        }

        if (this.currentPage > 1) {
          this.set("disablePrevious", 1);
        } else {
          this.set("disablePrevious", 0);
        }

        if (this.totalPages > 1) {
          this.set("disablePagination", 1);
        } else {
          this.set("disablePagination", 0);
        }
      } else {
        this.set("disablePagination", 0);
      }
    },

    rows: Ember.computed('model.[]', function () {
      if (this.get('model') !== undefined && this.get('model') !== null) {
        let formattedArray = [];
        this.get('model').data.forEach(function (tnemails) {
          let emailstatus = tnemails.TNPRICEPARSESTATUS;

          switch (emailstatus) {
            case 2:
              emailstatus = 'Unparsed';
              break;

            case 4:
              emailstatus = 'Successful';
              break;

            case 6:
              emailstatus = 'Unsuccessful';
              break;

            case 8:
              emailstatus = 'Manual';
              break;

            case 10:
              emailstatus = 'UnknownBidType';
              break;

            case 12:
              emailstatus = 'InProcess';
              break;

            case 14:
              emailstatus = 'Receiving';
              break;

            case 16:
              emailstatus = 'Hold';
              break;

            case 18:
              emailstatus = 'ForceProcess';
              break;

            case 20:
              emailstatus = 'Ignore';
              break;

            case 22:
              emailstatus = 'Error';
              break;

            case 24:
              emailstatus = 'Waiting';
              break;

            case 26:
              emailstatus = 'NonRailsBid';
              break;

            case 28:
              emailstatus = 'UnparsedRails';
              break;

            default:
              emailstatus = 'All';
          }

          let receivedatetime = tnemails.TNMESSAGERECEIPTDATETIME;
          receivedatetime = receivedatetime.replace("T", " ");
          receivedatetime = receivedatetime.replace(/.[0-9][0-9][0-9]Z$/, "");
          formattedArray.pushObject({
            tnemailid: tnemails.TNEMAILID,
            tnmessagereceiptdate: receivedatetime,
            tnmessagesubject: tnemails.TNMESSAGESUBJECT,
            tnvendorprogram: tnemails.TNPROGRAMVALUE,
            tnemailstatus: emailstatus
          });
        });
        return formattedArray;
      }
    }),
    actions: {
      nextPage() {
        this.nextPage();
      },

      prevPage() {
        this.prevPage();
      },

      firstPage() {
        this.firstPage();
      },

      lastPage() {
        this.lastPage(this.get("totalPages"));
      }

    }
  });

  _exports.default = _default;
});