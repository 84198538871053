define("ptportal/routes/bideditor", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-cli-file-saver/mixins/file-saver", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _fileSaver, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _fileSaver.default, {
    session: Ember.inject.service('session'),
    swal: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    router: Ember.inject.service('router'),
    currentUser: Ember.inject.service('current-user'),
    editPrice: null,
    pricerelations: null,
    custrelations: null,
    selectedResellerId: 0,
    selectedPriceId: 0,
    lines: null,
    sendBid: 0,
    currentPageRelations: 1,
    sendOptions: null,
    isAlso: 0,
    isCustomerCDW: 0,
    custRelationType: "Reseller",
    queryParams: {
      priceid: {
        refreshModel: true
      }
    },
    beforeModel: function () {
      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      if (this.get("currentUser").user.accessBidEditor) {
        return true;
      }

      this.get('session').invalidate();
    },
    afterModel: function () {
      this._super(...arguments); // Get send Options for the customer account.


      if (this.get("sendOptions") == null) {
        this.get('ajax').request('tncustsends', {
          type: 'GET'
        }).then(sendoptions => {
          let sendAllOptions = [];
          sendoptions.data.forEach(function (option) {
            sendAllOptions.push(option.attributes.tncustsendtype);
          });
          let sendOptions = {};
          sendOptions['salesNotification'] = sendAllOptions.includes(2);
          sendOptions['bidDeskNotification'] = sendAllOptions.includes(4);
          sendOptions['fTPCalculators'] = sendAllOptions.includes(6);
          sendOptions['httpSapTest'] = sendAllOptions.includes(8);
          sendOptions['httpSapTestForce'] = sendAllOptions.includes(10);
          sendOptions['httpSapProduction'] = sendAllOptions.includes(12);
          sendOptions['httpSapProductionForce'] = sendAllOptions.includes(14);
          sendOptions['httpProduction'] = sendAllOptions.includes(16);
          sendOptions['httpTest'] = sendAllOptions.includes(18);
          sendOptions['fTPXml'] = sendAllOptions.includes(20);
          sendOptions['fTPXmlTest'] = sendAllOptions.includes(22);
          this.set("sendOptions", sendOptions);
          this.refresh();
        });
      }
    },

    model(params) {
      this.set("sendBid", this.get("currentUser").user.accessSendBid);

      if (this.get("currentUser").user.tncustid == "CU100055") {
        this.set("isAlso", 1);
      }

      if (this.get("currentUser").user.tncustid == "CU100007") {
        this.set("isCustomerCDW", 1);
        this.set("custRelationType", "Customer");
      }

      var query = {};

      if (params.priceid) {
        query['TNPRICEID'] = params.priceid;
        this.set("selectedPriceId", params.priceid);
        let price = this.get('ajax').request('tnprices', {
          type: 'GET',
          data: query
        });
        this.set("editPrice", price);
        let pricerelations = this.store.query('tnpricerelation', {
          TNPRICEID: this.get("selectedPriceId")
        });
        this.set('pricerelations', pricerelations);
        let pricelines = this.store.query('tnpriceline', {
          TNPRICEID: this.get("selectedPriceId")
        });
        this.set('lines', pricelines);
      }

      return Ember.RSVP.hash({
        editprice: this.get("editPrice"),
        lines: this.get("lines"),
        pricerelations: this.get("pricerelations"),
        custrelations: this.get('custrelations'),
        selectedPriceId: this.get('selectedPriceId'),
        selectedResellerId: this.get('selectedResellerId'),
        sendBid: this.get('sendBid'),
        currentPageRelations: this.get('currentPageRelations'),
        sendOptions: this.get('sendOptions'),
        isAlso: this.get('isAlso'),
        isCustomerCDW: this.get('isCustomerCDW')
      });
    },

    actions: {
      originalDoc() {
        // this.get('ajax').request('tnprices', {
        //   type: 'GET',
        //   data: {TNPRICEID: this.get("selectedPriceId")}
        // }).then( (price) => {
        //   if (price.data[0].attributes.docurl == ""){
        //     Swal('Not Available', 'Origianl Document for this Price id is not available.', 'warning');
        //   }else{
        //     window.open(
        //       price.data[0].attributes.docurl,
        //       '_blank'
        //     );
        //   }
        // });
        let contentType = 'application/json';
        let filename = this.get("selectedPriceId") + '.msg';
        return this.get('ajax').request('get_bid_doc', {
          type: 'GET',
          dataType: 'arraybuffer',
          data: {
            TNPRICEID: this.get("selectedPriceId")
          }
        }).then(content => this.saveFileAs(filename, content, contentType)).catch(() => {
          (0, _sweetalert.default)('An Error Occoured', 'Error retrieving document!', 'error');
        });
      },

      sendBidOutbound(sendtype) {
        this.get('swal').open({
          title: 'Confirm Send Bid',
          text: 'Are you sure you want to send this bid (' + sendtype + ')',
          showCancelButton: true,
          confirmButtonText: "Send",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            let query = {};
            let token = JSON.parse(window.localStorage.getItem('ember_simple_auth-session')).authenticated.ax_guid;
            query['TNPRICEID'] = this.get("selectedPriceId");
            query['TNWSGUID'] = token;
            query['TNCUSTSENDTYPE'] = sendtype;
            this.get('ajax').request("ax_requests", {
              type: 'GET',
              data: query
            }).then(data => {
              if (data.message) {
                if (data.message == "<sendBidResponse/>") {
                  (0, _sweetalert.default)('Send Message', 'Bid was sent successfully!', 'success');
                } else {
                  (0, _sweetalert.default)('Send Message', data.message, 'info');
                }
              }
            }).catch(error => {
              (0, _sweetalert.default)('An Error occoured', 'There was an error sending bid!', 'error');
            });
          }
        });
      },

      sendBidRails(sendtype) {
        this.get('swal').open({
          title: 'Confirm Send Bid',
          text: 'Are you sure you want to send this bid (' + sendtype + ')',
          showCancelButton: true,
          confirmButtonText: "Send",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            let query = {};
            query['TNPRICEID'] = this.get("selectedPriceId");
            query['TNCUSTSENDTYPE'] = sendtype;
            this.get('ajax').request("bidoutput", {
              type: 'GET',
              data: query
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Send Message', 'Bid was sent successfully!', 'success');
              } else {
                (0, _sweetalert.default)('Send Message Error', 'There was an error sending bid!', 'error');
              }
            }).catch(error => {
              (0, _sweetalert.default)('An Error occoured', 'There was an error sending bid!', 'error');
            });
          }
        });
      },

      removeRelation(relationId) {
        let query = {
          TNPRICERELATIONID: relationId,
          TNPRICEID: this.get("selectedPriceId")
        };
        this.get('swal').open({
          title: 'Are you sure you want to delete this ' + this.get("custRelationType") + '?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Delete",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request("tnpricerelations/" + relationId, {
              type: 'DELETE',
              data: query
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)(this.get("custRelationType") + ' Deleted', this.get("custRelationType") + ' was successfully deleted.', 'success');
              } else {
                (0, _sweetalert.default)(this.get("custRelationType") + ' Delete Failed!', 'An error occoured while deleting the ' + this.get("custRelationType"), 'error');
              }

              this.refresh();
            });
          }
        });
      },

      removeEndUser(relationId) {
        let query = {
          TNPRICERELATIONID: relationId,
          TNPRICEID: this.get("selectedPriceId")
        };
        this.get('swal').open({
          title: 'Are you sure you want to delete this End User?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Delete",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request("tnpricerelations/" + relationId, {
              type: 'DELETE',
              data: query
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('End User Deleted', 'End User was successfully deleted.', 'success');
              } else {
                (0, _sweetalert.default)('End User Delete Failed!', 'An error occoured while deleting the end user.', 'error');
              }

              this.refresh();
            });
          }
        });
      },

      selectReseller(custRelation) {
        this.set("selectedResellerId", custRelation); //  // console.log("selectReseller Route Action",  this.get("selectedResellerId"));

        this.refresh();
      },

      getCustRelations(reseller, pagenumber) {
        this.set("currentPageRelations", pagenumber);
        this.set("cusrtrelationfilters", reseller);
        this.get('ajax').request('tncustrelations', {
          type: 'GET',
          data: {
            TNRELATIONNAME: reseller.resellername,
            TNCUSTINTERNALID1: reseller.custinternalid1,
            page: pagenumber
          }
        }).then(data => {
          this.set('custrelations', data);
          this.refresh();
        });
        this.refresh();
      },

      clearCustRelations() {
        this.set('custrelations', null);
        this.refresh();
      },

      addCustRelation() {
        let query = {
          TNCUSTRELATIONID: this.get("selectedResellerId"),
          TNPRICEID: this.get("selectedPriceId")
        };
        this.get('ajax').request("tnpricerelations/", {
          type: 'POST',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)(this.get("custRelationType") + ' Added', this.get("custRelationType") + ' was successfully added.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while adding the ' + this.get("custRelationType") + '.', 'error');
          }

          this.refresh();
        });
      },

      updateBidHeader(bidheader) {
        let query = {};

        if (bidheader.priceid) {
          query['TNPRICEID'] = bidheader.priceid;
        }

        let startdate = "";
        let enddate = "";

        if (bidheader.startdate) {
          if (bidheader.startdate.length == 1) {
            startdate = bidheader.startdate[0];
            startdate = startdate.getFullYear() + "-" + (startdate.getMonth() + 1) + "-" + startdate.getDate();
          } else {
            startdate = bidheader.startdate;
          }

          query['TNDEALSTARTDATE'] = startdate;
        }

        if (bidheader.enddate) {
          if (bidheader.enddate.length == 1) {
            enddate = bidheader.enddate[0];
            enddate = enddate.getFullYear() + "-" + (enddate.getMonth() + 1) + "-" + enddate.getDate();
          } else {
            enddate = bidheader.enddate;
          }

          query['TNDEALENDDATE'] = enddate;
        }

        if (bidheader.selectedcurrencycode) {
          let currencycode = bidheader.selectedcurrencycode;
          let currencycodenum = 0;

          switch (currencycode) {
            case 'CAD':
              currencycodenum = 2;
              break;

            case 'USD':
              currencycodenum = 4;
              break;

            case 'GBP':
              currencycodenum = 6;
              break;

            case 'EUR':
              currencycodenum = 8;
              break;

            case 'Default':
              currencycodenum = 10;
              break;

            case 'NOK':
              currencycodenum = 12;
              break;

            case 'DKK':
              currencycodenum = 14;
              break;

            case 'SEK':
              currencycodenum = 16;
              break;

            case 'CZK':
              currencycodenum = 18;
              break;

            case 'PLN':
              currencycodenum = 20;
              break;

            case 'CHF':
              currencycodenum = 22;
              break;

            case 'BGN':
              currencycodenum = 24;
              break;

            case 'RON':
              currencycodenum = 26;
              break;
          }

          query['TNCURRENCYCODE'] = currencycodenum;
        }

        if (bidheader.dealnumber) {
          query['TNDEALNUMBER'] = bidheader.dealnumber;
        }

        if (bidheader.dealquotenumber) {
          query['TNDEALQUOTENUMBER'] = bidheader.dealquotenumber;
        }

        if (bidheader.dealrevision) {
          query['TNDEALREVISION'] = bidheader.dealrevision;
        }

        if (bidheader.dealdescription) {
          query['TNDEALDESCRIPTION'] = bidheader.dealdescription;
        }

        if (bidheader.dealnotes) {
          query['TNDEALNOTES'] = bidheader.dealnotes;
        }

        if (bidheader.dealspecialterms) {
          query['TNDEALSPECIALTERMS'] = bidheader.dealspecialterms;
        }

        if (bidheader.dealterms) {
          query['TNDEALTERMSLONG'] = bidheader.dealterms;
        } // console.log("Update Bid Header with ", query);


        this.get('ajax').request("tnprices/" + bidheader.priceid, {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Bid Updated', 'Bid was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while updating the bid.', 'error');
          }

          this.refresh();
        });
      },

      addEndUser(endusername) {
        // console.log("Add End user for price id: " + this.get("selectedPriceId") + " and end user name:" , endusername);
        let query = {
          TNPRICEID: this.get("selectedPriceId"),
          TNRELATIONNAME: endusername,
          TNPARSEDATATYPE: 8
        };
        this.get('ajax').request("tnpricerelations/", {
          type: 'POST',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('End User Added', 'End User was successfully added.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while adding the end user.', 'error');
          }

          this.refresh();
        });
      },

      removeBidline(pricelineid) {
        // console.log("Remove Bid Line with Price Line ID" , pricelineid);
        this.get('swal').open({
          title: 'Are you sure you want to delete this bid line?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Delete",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request("tnpricelines/" + pricelineid, {
              type: 'DELETE'
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Bid Line Deleted', 'Bid line was successfully deleted.', 'success');
              } else {
                (0, _sweetalert.default)('Bid Line Delete Failed!', 'An error occoured while deleting the bid line.', 'error');
              }

              this.refresh();
            });
          }
        });
      },

      updateBidline(bidline) {
        let query = {};

        if (bidline.selectedDataType) {
          let parsedatatype = bidline.selectedDataType;
          let numparsedatatype = 0;

          switch (parsedatatype) {
            case 'Part':
              numparsedatatype = 4;
              break;

            case 'PartLine':
              numparsedatatype = 6;
              break;

            case 'Bundle':
              numparsedatatype = 6;
              break;

            case 'Config':
              numparsedatatype = 18;
              break;

            case 'PartFamily':
              numparsedatatype = 20;
              break;
          }

          query['TNPARSEDATATYPE'] = numparsedatatype;
        }

        let linestartdate = "";
        let lineenddate = "";

        if (bidline.startdate) {
          if (bidline.startdate.length == 1) {
            linestartdate = bidline.startdate[0];
            linestartdate = linestartdate.getFullYear() + "-" + (linestartdate.getMonth() + 1) + "-" + linestartdate.getDate();
          } else {
            linestartdate = bidline.startdate;
          }

          query['TNLINESTARTDATE'] = linestartdate;
        }

        if (bidline.enddate) {
          if (bidline.enddate.length == 1) {
            lineenddate = bidline.enddate[0];
            lineenddate = lineenddate.getFullYear() + "-" + (lineenddate.getMonth() + 1) + "-" + lineenddate.getDate();
          } else {
            lineenddate = bidline.enddate;
          }

          query['TNLINEENDDATE'] = lineenddate;
        }

        query['DATAAREAID'] = 'itv';

        if (bidline.bidprice) {
          query['TNPRICEBID'] = bidline.bidprice;
        }

        if (bidline.discountpercentage) {
          query['TNDISCOUNTPERCENTAGE'] = bidline.discountpercentage;
        }

        if (bidline.listprice) {
          query['TNPRICELIST'] = bidline.listprice;
        }

        if (bidline.maxqty) {
          query['TNQUANTITYMAX'] = bidline.maxqty;
        }

        if (bidline.minqty) {
          query['TNQUANTITYMIN'] = bidline.minqty;
        }

        if (bidline.partconfigid) {
          query['TNPARTCONFIGID'] = bidline.partconfigid;
        }

        if (bidline.partdescription) {
          query['TNVENDPARTDESC'] = bidline.partdescription;
        }

        if (bidline.partline) {
          query['TNVENDPARTLINE'] = bidline.partline;
        }

        if (bidline.pricetype) {
          query['TNLINEPRICETYPE'] = bidline.pricetype;
        }

        if (bidline.quantity) {
          query['TNQUANTITY'] = bidline.quantity;
        }

        if (bidline.rebate) {
          query['TNREBATE'] = bidline.rebate;
        }

        if (bidline.vendorpart) {
          query['TNVENDPART'] = bidline.vendorpart;
        }

        this.get('ajax').request("tnpricelines/" + bidline.pricelineid, {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Bid line Updated', 'Bid line was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while updating the bid line.', 'error');
          }

          this.refresh();
        });
      },

      addBidline(bidline) {
        // console.log("Add Bid Line for price id: " + this.get("selectedPriceId") + " with " , bidline);
        let parsedatatype = bidline.selectedDataType;
        let numparsedatatype = 0;

        switch (parsedatatype) {
          case 'Part':
            numparsedatatype = 4;
            break;

          case 'PartLine':
            numparsedatatype = 6;
            break;

          case 'Bundle':
            numparsedatatype = 6;
            break;

          case 'Config':
            numparsedatatype = 18;
            break;

          case 'PartFamily':
            numparsedatatype = 20;
            break;
        }

        let linestartdate = "";
        let lineenddate = "";

        if (bidline.startdate) {
          if (bidline.startdate.length == 1) {
            linestartdate = bidline.startdate[0];
            linestartdate = linestartdate.getFullYear() + "-" + (linestartdate.getMonth() + 1) + "-" + linestartdate.getDate();
          } else {
            linestartdate = bidline.startdate;
          }
        }

        if (bidline.enddate) {
          if (bidline.enddate.length == 1) {
            lineenddate = bidline.enddate[0];
            lineenddate = lineenddate.getFullYear() + "-" + (lineenddate.getMonth() + 1) + "-" + lineenddate.getDate();
          } else {
            lineenddate = bidline.enddate;
          }
        }

        this.get('ajax').request("tnpricelines/", {
          type: 'POST',
          data: {
            DATAAREAID: 'itv',
            TNPRICEID: this.get("selectedPriceId"),
            TNPRICEBID: bidline.bidprice,
            TNDISCOUNTPERCENTAGE: bidline.discountpercentage,
            TNLINEENDDATE: lineenddate,
            TNPRICELIST: bidline.listprice,
            TNQUANTITYMAX: bidline.maxqty,
            TNQUANTITYMIN: bidline.minqty,
            TNPARTCONFIGID: bidline.partconfigid,
            TNVENDPARTDESC: bidline.partdescription,
            TNVENDPARTLINE: bidline.partline,
            TNLINEPRICETYPE: bidline.pricetype,
            TNQUANTITY: bidline.quantity,
            TNREBATE: bidline.rebate,
            TNPARSEDATATYPE: numparsedatatype,
            TNLINESTARTDATE: linestartdate,
            TNVENDPART: bidline.vendorpart
          }
        }).then(data => {
          if (data) {
            if (data.message) {
              (0, _sweetalert.default)('Bid line Added', 'Bid line was successfully added.', 'success');
            } else {
              (0, _sweetalert.default)('Error!', 'An error occoured while adding the bid line.', 'error');
            }
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while adding the bid line.', 'error');
          }

          this.refresh();
        });
      },

      //Pagination Relations
      nextPageRelations() {
        let currentPageRelations = this.get("currentPageRelations");
        currentPageRelations++;
        this.send("getCustRelations", this.get("cusrtrelationfilters"), currentPageRelations);
      },

      prevPageRelations() {
        let currentPageRelations = this.get("currentPageRelations");
        currentPageRelations--;
        this.send("getCustRelations", this.get("cusrtrelationfilters"), currentPageRelations);
      },

      firstPageRelations() {
        this.set("currentPageRelations", 1);
        this.send("getCustRelations", this.get("cusrtrelationfilters"), 1);
      },

      lastPageRelations(totalpages) {
        this.set("currentPageRelations", totalpages);
        this.send("getCustRelations", this.get("cusrtrelationfilters"), totalpages);
      }

    }
  });

  _exports.default = _default;
});