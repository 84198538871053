define("ptportal/components/exceptions/exceptions-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rows: Ember.computed('model', function () {
      if (this.get('model') !== undefined && this.get('model') !== null) {
        let formattedArray = [];
        this.get('model').forEach(function (tnexceptions) {
          let exceptionstatus = tnexceptions.tnpriceexceptionstatus;

          switch (exceptionstatus) {
            case 2:
              exceptionstatus = 'Unresolved';
              break;

            case 4:
              exceptionstatus = 'Resolved';
              break;

            default:
              exceptionstatus = 'Undefined';
          }

          let exceptiontype = tnexceptions.tnpriceexceptiontype;

          switch (exceptiontype) {
            case 2:
              exceptiontype = 'Missing Value';
              break;

            case 4:
              exceptiontype = 'No Bid Lines';
              break;

            case 6:
              exceptiontype = 'Missing Reference';
              break;

            case 8:
              exceptiontype = 'Multiple Part Match';
              break;

            case 10:
              exceptiontype = 'Manual Process';
              break;

            case 12:
              exceptiontype = 'Duplicate Bid';
              break;

            default:
              exceptiontype = 'Undefined';
          }

          let datatype = tnexceptions.tnparsedatatype;

          switch (datatype) {
            case 2:
              datatype = 'Header';
              break;

            case 4:
              datatype = 'Part';
              break;

            case 6:
              datatype = 'PartLine';
              break;

            case 8:
              datatype = 'Customer';
              break;

            case 10:
              datatype = 'Distributor';
              break;

            case 12:
              datatype = 'Vendor';
              break;

            case 14:
              datatype = 'Reseller';
              break;

            case 16:
              datatype = 'Bundle';
              break;

            case 18:
              datatype = 'Config';
              break;

            case 20:
              datatype = 'PartFamily';
              break;

            case 22:
              datatype = 'PartTwo';
              break;

            case 24:
              datatype = 'Bid';
              break;

            case 26:
              datatype = 'PartLineTwo';
              break;

            default:
              datatype = 'Undefined';
          } // let resolvedate = tnexceptions.tnpriceexceptionresolvedate;
          // if(resolvedate == 'Sun Dec 31 1899 19:00:00 GMT-0500 (Eastern Standard Time)'){
          //  resolvedate = " "
          // }
          // else {
          //   resolvedate = (resolvedate.getMonth()+1)+ "-" + (resolvedate.getDate()+1) + "-" + resolvedate.getFullYear();
          // }


          let resolvedate = tnexceptions.tnpriceexceptionresolvedate.replace(/T[0-9][0-9]:[0-9][0-9]:[0-9][0-9].[0-9][0-9][0-9]Z/, '');

          if (resolvedate == '1900-01-01') {
            resolvedate = "";
          }

          let resolvetime = tnexceptions.resolvetime;

          if (resolvetime) {
            var resolvetimehh = resolvetime.getHours();
            var resolvetimemm = resolvetime.getMinutes();
            var resolvetimess = resolvetime.getSeconds();

            if (resolvetimess < 10) {
              resolvetimess = '0' + resolvetimess;
            }

            if (resolvetimemm < 10) {
              resolvetimemm = '0' + resolvetimemm;
            }

            if (resolvetimehh < 10) {
              resolvetimehh = '0' + resolvetimehh;
            }

            resolvetime = resolvetimehh + ":" + resolvetimemm + ":" + resolvetimess;
          }

          formattedArray.pushObject({
            tnexceptionid: tnexceptions.id,
            tnlinenumber: tnexceptions.tnlinenumber,
            tnparsedatatype: datatype,
            tnparsedatatyperaw: tnexceptions.tnparsedatatype,
            tnpriceexceptiondesc: tnexceptions.tnpriceexceptiondesc,
            tnpriceexceptionid: tnexceptions.tnpriceexceptionid,
            tnpriceexceptionresolvedate: resolvedate,
            tnpriceexceptionresolvestr: tnexceptions.tnpriceexceptionresolvestr,
            tnpriceexceptionresolvetime: resolvetime,
            tnpriceexceptionstatus: exceptionstatus,
            tnpriceexceptionstatusraw: tnexceptions.tnpriceexceptionstatus,
            tnpriceexceptiontype: exceptiontype,
            tnpriceexceptiontyperaw: tnexceptions.tnpriceexceptiontype,
            tnrelationname: tnexceptions.relationname,
            fieldtype: tnexceptions.fieldtype,
            resolvedby: tnexceptions.resolvedby
          });
        });
        return formattedArray;
      }
    }),
    actions: {
      removeAllExceptions() {
        this.removeAllExceptions();
      }

    }
  });

  _exports.default = _default;
});