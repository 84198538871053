define("ptportal/templates/components/email/email-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IQhGvIL5",
    "block": "{\"symbols\":[\"tnbidheader\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"table-responsive overview-table-wrapper tableFixHead\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"table\",true],[12,\"class\",\"table table-hover\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"thead\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"tr\",true],[10],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Price ID\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Deal Number\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Rev\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,0,0,0,[31,216,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"isCustomerCDW\"]],\"Customer\",\"Reseller\"],null]],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"End-User\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Create Date\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"End Date\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Start Date\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Status\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"tbody\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[1,0,0,0,[31,503,21,[27,[26,1,\"CallHead\"],[]],null,[[\"openInBidExplorer\",\"classNames\",\"tnbidheader\"],[[31,544,12,[27,[26,0,\"CallHead\"],[]],[\"openInBidExplorer\"],null],\"is-selectable\",[27,[24,1],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"email/email-price-row\",\"if\",\"rows\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/email/email-price.hbs"
    }
  });

  _exports.default = _default;
});