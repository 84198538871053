define("ptportal/models/tnprice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // tnMasterLogs: DS.hasMany('tnMasterLog'),
    // tnPriceRelationRefs: DS.hasMany('tnPriceRelationRef'),
    // tnPriceLines: DS.hasMany('tnPriceLine'),
    // tnpricerelations: DS.hasMany('tnpricerelation'),
    // tnCustRelations: DS.hasMany('tnCustRelation'),
    // tnPriceExceptions: DS.hasMany('tnPriceException'),
    // tnCust: DS.belongsTo('tncust'),
    // tnPriceEmail: DS.belongsTo('tnpriceemail'),
    // tnVendor: DS.belongsTo('tnvendor'),
    // tnPriceImport: DS.belongsTo('tnpriceimport'),
    // tnPriceEmail: DS.belongsTo('tnpriceemail'),
    tnpriceid: _emberData.default.attr('string'),
    tnvendid: _emberData.default.attr('string'),
    tnprogramid: _emberData.default.attr('string'),
    tndealnumber: _emberData.default.attr('string'),
    tndealrevision: _emberData.default.attr('string'),
    tndealquotenumber: _emberData.default.attr('string'),
    tndealdescription: _emberData.default.attr('string'),
    tncurrencycodenew: _emberData.default.attr('number'),
    currvalue: _emberData.default.attr('string'),
    tndealnotes: _emberData.default.attr('string'),
    tndealenddate: _emberData.default.attr('string'),
    tndealstartdate: _emberData.default.attr('string'),
    tnemailid: _emberData.default.attr('string'),
    tnpricestatus: _emberData.default.attr('number'),
    tnpricelog: _emberData.default.attr('string'),
    tnpriceerrorlog: _emberData.default.attr('string'),
    tnpricecreatedate: _emberData.default.attr('string'),
    tnpricecreatetime: _emberData.default.attr('number'),
    tnpricecreatedatetime: _emberData.default.attr('date'),
    tnpriceimportid: _emberData.default.attr('string'),
    tnbidfilename: _emberData.default.attr('string'),
    tnexceptioncount: _emberData.default.attr('number'),
    tndealspecialterms: _emberData.default.attr('string'),
    tndealtermslong: _emberData.default.attr('string'),
    tncustnotes: _emberData.default.attr('string'),
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    tnbidrenewalid: _emberData.default.attr('string'),
    tnlatestrevision: _emberData.default.attr('number'),
    tnpricetype: _emberData.default.attr('number'),
    tnpriceurl: _emberData.default.attr('string'),
    tnwsguid: _emberData.default.attr('string'),
    tnendusernumber: _emberData.default.attr('string'),
    tnpriceloadid: _emberData.default.attr('string'),
    tndealbasedate: _emberData.default.attr('date'),
    docurl: _emberData.default.attr('string'),
    tnsalesorg: _emberData.default.attr('string'),
    tncustomervalue: _emberData.default.attr('string'),
    tncustomertype: _emberData.default.attr('string'),
    tndiscounttype: _emberData.default.attr('string'),
    tnsapcondition: _emberData.default.attr('string'),
    tnsndinternal: _emberData.default.attr('string'),
    tnsndexport: _emberData.default.attr('string'),
    tncontractuser: _emberData.default.attr('string'),
    tnuplift: _emberData.default.attr('number'),
    tnwebshop: _emberData.default.attr('string'),
    tnsndcondition: _emberData.default.attr('string')
  });

  _exports.default = _default;
});