define("ptportal/components/bidexplorer/be-transport-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rows: Ember.computed('model', function () {
      if (this.get('model').length !== undefined) {
        let formattedArray = [];
        this.get('model').forEach(function (tntransport) {
          let destination = tntransport.tnhttppostdestination;

          switch (destination) {
            case 2:
              destination = 'Test';
              break;

            case 4:
              destination = 'Production';
              break;

            default:
              destination = 'Undefined';
          }

          let publishtype = tntransport.tnbidpublishtype;

          switch (publishtype) {
            case 2:
              publishtype = 'Ftp Xml';
              break;

            case 4:
              publishtype = 'Disabled';
              break;

            case 6:
              publishtype = 'Email';
              break;

            case 8:
              publishtype = 'HTTP';
              break;

            case 10:
              publishtype = 'Ftp Xml Http';
              break;

            case 12:
              publishtype = 'Email Http';
              break;

            case 14:
              publishtype = 'Email Ftp Xml';
              break;

            default:
              publishtype = 'Undefined';
          }

          let datetime = tntransport.tntransportdatetime.replace('T', ' ');
          let formatteddatetime = datetime.replace('.000Z', '');
          let transportstatus = tntransport.tntransportstatus;

          switch (transportstatus) {
            case 2:
              transportstatus = 'Sent No Confirmation';
              break;

            case 4:
              transportstatus = 'Sent Awaiting Confirmation';
              break;

            case 5:
              transportstatus = 'Confirmation';
              break;

            case 6:
              transportstatus = 'Send Error';
              break;

            case 8:
              transportstatus = 'Blocked';
              break;

            default:
              transportstatus = 'Undefined';
          }

          formattedArray.pushObject({
            contact: tntransport.contact,
            tnbidpublishtype: publishtype,
            tnemail: tntransport.tnemail,
            tnhttppostdestination: destination,
            tntransmissionnotes: tntransport.tntransmissionnotes,
            tntransportdatetime: formatteddatetime,
            tntransportresponse: tntransport.tntransportresponse,
            tntransportstatus: transportstatus,
            tntransporttype: tntransport.tntransporttype
          });
        });
        return formattedArray;
      }
    })
  });

  _exports.default = _default;
});