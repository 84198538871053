define("ptportal/serializers/application", ["exports", "ember-data", "ember-data-table/mixins/serializer"], function (_exports, _emberData, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend(_serializer.default, {});

  _exports.default = _default;
});