define("ptportal/components/editcustomers/editcust-salesrelations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addMode: false,
    actions: {
      addSalesRelation() {
        this.toggleProperty("addMode");
      }

    },
    rows: Ember.computed('model', 'salestype', function () {
      if (this.get('model') !== undefined && this.get('model') !== null) {
        let formattedArray = [];
        let custsalestype = this.get('salestype');
        this.get('model').data.forEach(function (editcustomer) {
          if (editcustomer.attributes.tncustsalestype === custsalestype) {
            formattedArray.pushObject({
              tncustsalesid: editcustomer.attributes.tncustsalesid,
              tncustsalesname: editcustomer.attributes.tncustsalesname,
              tncustsalesemail: editcustomer.attributes.tncustsalesemail,
              tncustsalesrelationid: editcustomer.attributes.tncustsalesrelationid,
              tncustsalestype: editcustomer.attributes.tncustsalestype
            });
          }
        });
        return formattedArray;
      }
    })
  });

  _exports.default = _default;
});