define("ptportal/models/tnvendorprogram", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // tnprices: DS.hasMany('tnprice'),
    // tnrenewals: DS.hasMany('tnrenewal'),
    // tncustprograms: DS.hasMany('tncustprogram'),
    // tnpriceimports: DS.hasMany('tnpriceimport'),
    // tnvendor: belongsTo('tnvendor'),
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    tnprogramvalue: _emberData.default.attr('string'),
    tnprogramid: _emberData.default.attr('string'),
    tnvendid: _emberData.default.attr('string'),
    tnactive: _emberData.default.attr('number'),
    tnpricetype: _emberData.default.attr('number'),
    tnshowinportal: _emberData.default.attr('number'),
    tnprogramusesvendorpricelist: _emberData.default.attr('number'),
    tnprogramusesreplacementparts: _emberData.default.attr('number'),
    tnvendauthprogramid: _emberData.default.attr('string')
  });

  _exports.default = _default;
});