define("ptportal/templates/components/dashboard/dashboard-latestbids-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zMaTg7dO",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"priceid\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"dealnumber\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"vendor\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"program\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"received\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",false],[23,\"class\",\"icon icon-open-in-new\",null],[23,\"type\",\"button\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],\"selectLatestBid\",[27,[26,0,\"Expression\"],[\"priceid\"]]],null],[10],[1,1,0,0,\"\\n    \"],[7,\"bs-tooltip\",[],[[\"@title\"],[\"Open in Bid Explorer\"]],null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"latestbid\",\"action\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/dashboard/dashboard-latestbids-row.hbs"
    }
  });

  _exports.default = _default;
});