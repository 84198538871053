define("ptportal/components/programs/programs-general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    programterms: null,
    userid: null,
    password: null,

    didReceiveAttrs() {
      if (this.model != null) {
        this.set("programterms", this.get('model').data.attributes.tncustprogramterms);
        this.set("userid", this.get('model').data.attributes.tnuserid);
        this.set("password", this.get('model').data.attributes.tnpassword);
      }
    },

    actions: {
      editGeneral() {
        var general = {};
        general['programterms'] = this.get('programterms');
        general['userid'] = this.get('userid');
        general['password'] = this.get('password');
        this.updateGeneral(general);
      }

    }
  });

  _exports.default = _default;
});