define("ptportal/models/tnvendor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // tnprices: DS.hasMany('tnprice'),
    // tnvendorprograms: DS.hasMany('tnvendorprogram'),
    // tnrenewals: DS.hasMany('tnrenewal'),
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    tnvendvalue: _emberData.default.attr('string'),
    tnvendid: _emberData.default.attr('string'),
    tnactive: _emberData.default.attr('number'),
    tnshowinportal: _emberData.default.attr('number')
  });

  _exports.default = _default;
});