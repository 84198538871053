define("ptportal/templates/components/editcustomers/editcust-salesrelations-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0JS6qfyP",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tncustsalesid\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tncustsalesname\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tncustsalesemail\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[12,\"style\",\"width: 30px;\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",false],[23,\"class\",\"icon icon-account-edit\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"editSalesRelation\"],null],[10],[1,1,0,0,\"\\n    \"],[7,\"bs-tooltip\",[],[[\"@title\"],[\"Edit\"]],null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[12,\"style\",\"width: 30px;\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",false],[23,\"class\",\"icon icon-account-remove\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"removeSalesRelation\",[27,[26,2,\"Expression\"],[\"tncustsalesrelationid\"]]],null],[10],[1,1,0,0,\"\\n    \"],[7,\"bs-tooltip\",[],[[\"@title\"],[\"Delete\"]],null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,471,42,[27,[26,3,\"CallHead\"],[]],null,[[\"salesrelation\",\"updateSalesRelation\",\"closeModal\"],[[27,[26,2,\"Expression\"],[]],[31,563,12,[27,[26,1,\"CallHead\"],[]],[\"updateSalesRelation\"],null],[31,611,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"editSalesRelation\"],null]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"route-action\",\"salesrelation\",\"editcustomers/editcust-salesrelations-edit\",\"editMode\",\"if\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/editcustomers/editcust-salesrelations-row.hbs"
    }
  });

  _exports.default = _default;
});