define("ptportal/templates/components/bidexplorer/be-relation-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BAE3a19x",
    "block": "{\"symbols\":[\"tnrelation\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"table-responsive overview-table-wrapper tableFixHead\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"table\",true],[12,\"class\",\"table table-hover\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"thead\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"tr\",true],[10],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Relation Type\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Relation Name\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Address\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"City\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"State\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Postal\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Raw Address\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Vendor ID1\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Vendor ID2\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Contact Name\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Telephone\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Email\"],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"tbody\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[1,0,0,0,[31,531,27,[27,[26,0,\"CallHead\"],[]],null,[[\"tnrelation\"],[[27,[24,1],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"bidexplorer/be-relation-row\",\"rows\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/bidexplorer/be-relation-tab.hbs"
    }
  });

  _exports.default = _default;
});