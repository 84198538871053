define("ptportal/routes/bidexplorer", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-cli-file-saver/mixins/file-saver", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _fileSaver, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _fileSaver.default, {
    session: Ember.inject.service('session'),
    swal: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    ajax: Ember.inject.service('ajax'),
    router: Ember.inject.service('router'),
    custdata: Ember.inject.service('cust-data'),
    axparser: Ember.inject.service('ax-parser'),
    price: null,
    lines: null,
    pricerelations: null,
    priceexceptions: null,
    deltaprice: null,
    transporthistory: null,
    selectedPriceId: 0,
    bidexplorerfilters: null,
    currentPage: 1,
    totalRows: 0,
    sendBid: 0,
    deltaPrevPriceID: "",
    deltaPrevRevision: "",
    deltaMessage: "",
    exceptionCount: 0,
    originalDocUrl: "",
    emailBody: "",
    emailBodyMode: false,
    custsalesrelations: null,
    currentPageRelations: 1,
    custrelations: null,
    selectedResellerId: 0,
    sendOptions: null,
    customerId: 0,
    isCustomerCDW: 0,
    //------START-------TO MAKE THE OPEN IN BID EXPLORER WORK-----------START----------------//
    queryPriceId: "",
    isqueryparams: 0,
    queryParams: {
      priceid: {
        replace: true,
        refreshModel: true
      }
    },
    //------END-------TO MAKE THE OPEN IN BID EXPLORER WORK------------END---------------//
    beforeModel: function () {
      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      if (this.get("currentUser").user.accessBidExplorer) {
        return true;
      }

      this.get('session').invalidate();
    },

    model(params) {
      this.set("sendBid", this.get("currentUser").user.accessSendBid);

      if (this.get("currentUser").user.tncustid == "CU100055") {
        this.set("customerId", 1);
      }

      if (this.get("currentUser").user.tncustid == "CU100007") {
        this.set("isCustomerCDW", 1);
      } // Get send Options for the customer account.


      if (this.get("sendOptions") == null) {
        this.get('ajax').request('tncustsends', {
          type: 'GET'
        }).then(sendoptions => {
          let sendAllOptions = [];
          sendoptions.data.forEach(function (option) {
            sendAllOptions.push(option.attributes.tncustsendtype);
          });
          let sendOptions = {};
          sendOptions['salesNotification'] = sendAllOptions.includes(2);
          sendOptions['bidDeskNotification'] = sendAllOptions.includes(4);
          sendOptions['fTPCalculators'] = sendAllOptions.includes(6);
          sendOptions['httpSapTest'] = sendAllOptions.includes(8);
          sendOptions['httpSapTestForce'] = sendAllOptions.includes(10);
          sendOptions['httpSapProduction'] = sendAllOptions.includes(12);
          sendOptions['httpSapProductionForce'] = sendAllOptions.includes(14);
          sendOptions['httpProduction'] = sendAllOptions.includes(16);
          sendOptions['httpTest'] = sendAllOptions.includes(18);
          sendOptions['fTPXml'] = sendAllOptions.includes(20);
          sendOptions['fTPXmlTest'] = sendAllOptions.includes(22);
          this.set("sendOptions", sendOptions);
        });
      } //load cust sales relation drowpdowns (In, Out, Mgr, Grp)


      if (this.get("custsalesrelations") == null) {
        let custsalesrelation = this.get('ajax').request('tncustsalesrelations', {
          type: 'GET'
        });
        this.set("custsalesrelations", custsalesrelation);
      } //------START-------TO MAKE THE OPEN IN BID EXPLORER WORK-----------START----------------//


      var bidexplorerfilters = {};

      if (params.priceid != this.get("queryPriceId")) {
        this.set("isqueryparams", 0);
      }

      if (params.priceid && this.get("isqueryparams") == 0) {
        bidexplorerfilters['priceid'] = params.priceid;
        this.set("queryPriceId", params.priceid);
        this.searchBids(bidexplorerfilters, 1);
      }

      this.set("isqueryparams", 1); //------END-------TO MAKE THE OPEN IN BID EXPLORER WORK------------END---------------//
      // Code to stop table components from erroring due to initial null value, null is not allowed to be set as default value by Ember

      if (this.get('price') === null) {
        this.set('price', []);
      }

      if (this.get('lines') === null) {
        this.set('lines', []);
      }

      if (this.get('pricerelations') === null) {
        this.set('pricerelations', []);
      }

      if (this.get('priceexceptions') === null) {
        this.set('priceexceptions', []);
      }

      if (this.get('deltaprice') === null) {
        this.set('deltaprice', []);
      }

      if (this.get('transporthistory') === null) {
        this.set('transporthistory', []);
      }

      return Ember.RSVP.hash({
        prices: this.get('prices'),
        price: this.get('price'),
        lines: this.get('lines'),
        sendBid: this.get('sendBid'),
        pricerelations: this.get('pricerelations'),
        priceexceptions: this.get('priceexceptions'),
        deltaprice: this.get('deltaprice'),
        transporthistory: this.get('transporthistory'),
        selectedPriceId: this.get('selectedPriceId'),
        currentPage: this.get('currentPage'),
        totalRows: this.get('totalRows'),
        deltaPrevPriceID: this.get('deltaPrevPriceID'),
        deltaPrevRevision: this.get('deltaPrevRevision'),
        deltaMessage: this.get('deltaMessage'),
        exceptionCount: this.get('exceptionCount'),
        originalDocUrl: this.get('originalDocUrl'),
        emailBody: this.get('emailBody'),
        emailBodyMode: this.get('emailBodyMode'),
        bidexplorerfilters: this.get('bidexplorerfilters'),
        custsalesrelations: this.get('custsalesrelations'),
        custrelations: this.get('custrelations'),
        currentPageRelations: this.get('currentPageRelations'),
        selectedResellerId: this.get('selectedResellerId'),
        sendOptions: this.get('sendOptions'),
        customerId: this.get('customerId'),
        isCustomerCDW: this.get('isCustomerCDW')
      });
    },

    //------START-------TO MAKE THE OPEN IN BID EXPLORER WORK-----------START----------------//
    searchBids: function (bidexplorerfilters, pagenumber) {
      this.set("currentPage", pagenumber);
      this.set("bidexplorerfilters", bidexplorerfilters);
      this.set("originalDocUrl", 0);
      this.set("emailBody", 0);
      this.set("exceptionCount", 0);
      this.set("price", null);
      this.set("lines", null);
      this.set("pricerelations", null);
      this.set("priceexceptions", null);
      this.set("deltaprice", null);
      this.set("transporthistory", null);
      this.set("custrelations", null);
      var query = {}; // Build Query Start

      if (bidexplorerfilters.priceid) {
        query['TNPRICEID'] = bidexplorerfilters.priceid;
      }

      query['page'] = pagenumber;
      let price = this.get('ajax').request('explorer_prices', {
        type: 'GET',
        data: query
      });
      this.set("prices", price);
      this.set("totalRows", price.totalrecords);
      this.set("selectedPriceId", 0); //this.refresh();
    },
    //------END-------TO MAKE THE OPEN IN BID EXPLORER WORK------------END---------------//
    actions: {
      searchBids(bidexplorerfilters, pagenumber) {
        this.set("currentPage", pagenumber);
        this.set("bidexplorerfilters", bidexplorerfilters);
        this.set("originalDocUrl", 0);
        this.set("emailBody", 0);
        this.set("exceptionCount", 0);
        this.set("price", null);
        this.set("lines", null);
        this.set("pricerelations", null);
        this.set("priceexceptions", null);
        this.set("deltaprice", null);
        this.set("transporthistory", null);
        this.set("custrelations", null);
        document.getElementById("bidlines-tab").click();
        document.getElementById("overview-tab").click();
        var query = {}; // Build Query Start

        if (bidexplorerfilters.priceid) {
          query['TNPRICEID'] = bidexplorerfilters.priceid;
        }

        if (bidexplorerfilters.bidNumber) {
          query['TNDEALNUMBER'] = bidexplorerfilters.bidNumber;
        }

        if (bidexplorerfilters.quoteNumber) {
          query['TNDEALQUOTENUMBER'] = bidexplorerfilters.quoteNumber;
        }

        if (bidexplorerfilters.vendPart) {
          query['TNVENDPART'] = bidexplorerfilters.vendPart;
        }

        if (bidexplorerfilters.productLine) {
          query['TNVENDPARTLINE'] = bidexplorerfilters.productLine;
        }

        if (bidexplorerfilters.vendID) {
          query['TNVENDID'] = bidexplorerfilters.vendID;
        }

        if (bidexplorerfilters.programID) {
          query['TNPROGRAMID'] = bidexplorerfilters.programID;
        }

        if (bidexplorerfilters.bidStatus) {
          query['BIDSTATUS'] = bidexplorerfilters.bidStatus;
        }

        if (bidexplorerfilters.startDate) {
          let formattedDate = new Date(bidexplorerfilters.startDate);
          query['TNDEALSTARTDATE'] = formattedDate.getFullYear() + "-" + (formattedDate.getMonth() + 1) + "-" + formattedDate.getDate();
        }

        if (bidexplorerfilters.endDate) {
          let formattedDate = new Date(bidexplorerfilters.endDate);
          query['TNDEALENDDATE'] = formattedDate.getFullYear() + "-" + (formattedDate.getMonth() + 1) + "-" + formattedDate.getDate();
        }

        if (bidexplorerfilters.createDate) {
          let formattedDate = new Date(bidexplorerfilters.createDate);
          query['TNPRICECREATEDATE'] = formattedDate.getFullYear() + "-" + (formattedDate.getMonth() + 1) + "-" + formattedDate.getDate();
        }

        if (bidexplorerfilters.dealRevision) {
          query['TNDEALREVISION'] = bidexplorerfilters.dealRevision;
        }

        if (bidexplorerfilters.latestRevision == 1) {
          query['TNLATESTREVISION'] = 1;
        }

        if (bidexplorerfilters.isProd == 1) {
          query['PRODSEARCH'] = 1;
        }

        if (bidexplorerfilters.tnrelationname) {
          query['TNRELATIONNAME'] = bidexplorerfilters.tnrelationname;
        }

        if (bidexplorerfilters.endCustName) {
          query['TNENDCUSTNAME'] = bidexplorerfilters.endCustName;
        }

        if (bidexplorerfilters.selectedSalesRelationGrp) {
          query['TNCUSTSALESRELATIONIDGROUP'] = bidexplorerfilters.selectedSalesRelationGrp;
        }

        if (bidexplorerfilters.selectedSalesRelationIn) {
          query['TNCUSTSALESRELATIONIDINSIDE'] = bidexplorerfilters.selectedSalesRelationIn;
        }

        if (bidexplorerfilters.selectedSalesRelationOut) {
          query['TNCUSTSALESRELATIONIDOUTSIDE'] = bidexplorerfilters.selectedSalesRelationOut;
        }

        if (bidexplorerfilters.selectedSalesRelationMgr) {
          query['TNCUSTSALESRELATIONIDMANAGER'] = bidexplorerfilters.selectedSalesRelationMgr;
        }

        query['page'] = pagenumber;
        this.get('ajax').request('explorer_prices', {
          type: 'GET',
          data: query
        }).then(price => {
          this.set("prices", price);
          this.set("totalRows", price.totalrecords);
          this.refresh();
        });
        this.set("selectedPriceId", 0);
        this.refresh();
      },

      selectPrice(sprice) {
        this.set("selectedPriceId", sprice);
        this.set("price", null);
        this.set("lines", null);
        this.set("pricerelations", null);
        this.set("priceexceptions", null);
        this.set("deltaprice", null);
        this.set("transporthistory", null);
        document.getElementById("bidlines-tab").click();
        this.store.query('tnpriceline', {
          TNPRICEID: sprice
        }).then(pricelines => {
          this.set('lines', pricelines);
          this.refresh();
        });
        this.get('ajax').request('explorer_prices', {
          type: 'GET',
          data: {
            TNPRICEID: sprice
          }
        }).then(price => {
          this.set("exceptionCount", price.data[0].exception_count);

          if (price.data[0]) {
            if (price.data[0].docurl == "") {
              this.set("originalDocUrl", 0);
            } else {
              this.set("originalDocUrl", price.data[0].docurl);
            }
          }

          if (price.data[0].msgbody == "") {
            this.set("emailBody", 0);
          } else {
            this.set("emailBody", price.data[0].msgbody);
          }

          this.refresh();
        });
        this.refresh();
      },

      originalDoc() {
        // window.open(
        //   this.get("originalDocUrl"),
        //   '_blank'
        // );
        let contentType = 'application/json';
        let filename = this.get("selectedPriceId") + '.msg';
        return this.get('ajax').request('get_bid_doc', {
          type: 'GET',
          dataType: 'arraybuffer',
          data: {
            TNPRICEID: this.get("selectedPriceId")
          }
        }).then(content => this.saveFileAs(filename, content, contentType)).catch(() => {
          (0, _sweetalert.default)('An Error Occoured', 'Error retrieving document!', 'error');
        });
      },

      emailBody() {
        this.toggleProperty('emailBodyMode');
        this.refresh();
      },

      sendBid(sendtype) {
        this.get('swal').open({
          title: 'Confirm Send Bid',
          text: 'Are you sure you want to send this bid (' + sendtype + ')',
          showCancelButton: true,
          confirmButtonText: "Send",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            let query = {};
            let token = JSON.parse(window.localStorage.getItem('ember_simple_auth-session')).authenticated.ax_guid;
            query['TNPRICEID'] = this.get("selectedPriceId");
            query['TNWSGUID'] = token;
            query['TNCUSTSENDTYPE'] = sendtype;
            this.get('ajax').request("ax_requests", {
              type: 'GET',
              data: query
            }).then(data => {
              if (data.message) {
                if (data.message == "<sendBidResponse/>") {
                  (0, _sweetalert.default)('Send Message', 'Bid was sent successfully!', 'success');
                } else {
                  (0, _sweetalert.default)('Send Message', data.message, 'info');
                }
              }
            }).catch(() => {
              (0, _sweetalert.default)('An Error occoured', 'There was an error sending bid!', 'error');
            });
          }
        });
      },

      sendBidRails(sendtype) {
        this.get('swal').open({
          title: 'Confirm Send Bid',
          text: 'Are you sure you want to send this bid (' + sendtype + ')',
          showCancelButton: true,
          confirmButtonText: "Send",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            let query = {};
            query['TNPRICEID'] = this.get("selectedPriceId");
            query['TNCUSTSENDTYPE'] = sendtype;
            this.get('ajax').request("bidoutput", {
              type: 'GET',
              data: query
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Send Message', 'Bid was sent successfully!', 'success');
              } else {
                (0, _sweetalert.default)('Send Message Error', 'There was an error sending bid!', 'error');
              }
            }).catch(() => {
              (0, _sweetalert.default)('An Error occoured', 'There was an error sending bid!', 'error');
            });
          }
        });
      },

      loadSinglePrice() {
        this.store.query('tnprice', {
          TNPRICEID: this.get("selectedPriceId"),
          DATAAREAID: 'itv'
        }).then(singleprice => {
          this.set('price', singleprice);
          this.refresh();
        });
      },

      loadPriceRelations() {
        this.store.query('tnpricerelation', {
          TNPRICEID: this.get("selectedPriceId")
        }).then(pricerelations => {
          this.set('pricerelations', pricerelations);
          this.refresh();
        });
      },

      loadPriceExceptions() {
        this.store.query('tnpriceexception', {
          TNPRICEID: this.get("selectedPriceId")
        }).then(priceexceptions => {
          this.set('priceexceptions', priceexceptions);
          this.refresh();
        });
      },

      loadDeltaPrice() {
        this.get('ajax').request('biddelta', {
          type: 'GET',
          data: {
            TNPRICEID: this.get("selectedPriceId")
          }
        }).then(deltaprice => {
          this.set('deltaprice', deltaprice);
          this.set('deltaPrevPriceID', deltaprice.previous_price);
          this.set('deltaPrevRevision', deltaprice.previous_revision);
          this.set('deltaMessage', deltaprice.msg);
          this.refresh();
        });
      },

      loadTransportHistory() {
        this.get('ajax').request('transporthistory', {
          type: 'GET',
          data: {
            TNTRACKERID: this.get("selectedPriceId")
          }
        }).then(transporthistory => {
          this.set('transporthistory', transporthistory);
          this.refresh();
        });
      },

      openBidEditor() {
        this.get('router').transitionTo('bideditor', {
          queryParams: {
            priceid: this.get("selectedPriceId")
          }
        });
      },

      openExceptions() {
        this.get('router').transitionTo('exceptions', {
          queryParams: {
            priceid: this.get("selectedPriceId")
          }
        });
      },

      clearFilters() {
        this.set("prices", null);
        this.set("price", null);
        this.set("lines", null);
        this.set("pricerelations", null);
        this.set("priceexceptions", null);
        this.set("deltaprice", null);
        this.set("transporthistory", null);
        this.set("selectedPriceId", 0);
        this.set("bidexplorerfilters", null);
        this.set("currentPage", 1);
        this.set("totalRows", 0);
        this.set("sendBid", 0);
        this.set("deltaPrevPriceID", "");
        this.set("deltaPrevRevision", "");
        this.set("deltaMessage", "");
        this.set("exceptionCount", 0);
        this.set("originalDocUrl", "");
        this.set("emailBody", "");
        document.getElementById("bidlines-tab").click();
        document.getElementById("overview-tab").click();
        this.refresh();
      },

      selectReseller(custRelation) {
        this.set("selectedResellerId", custRelation);
        this.refresh();
      },

      getCustRelations(reseller, pagenumber) {
        this.set("currentPageRelations", pagenumber);
        this.set("cusrtrelationfilters", reseller);
        this.get('ajax').request('tncustrelations', {
          type: 'GET',
          data: {
            TNRELATIONNAME: reseller.resellername,
            TNCUSTINTERNALID1: reseller.custinternalid1,
            page: pagenumber
          }
        }).then(data => {
          this.set('custrelations', data);
          this.refresh();
        });
        this.refresh();
      },

      clearCustRelations() {
        this.set('custrelations', null);
        this.refresh();
      },

      //Pagination
      nextPage() {
        let currentPage = this.get("currentPage");
        currentPage++;
        this.send("searchBids", this.get("bidexplorerfilters"), currentPage);
      },

      prevPage() {
        let currentPage = this.get("currentPage");
        currentPage--;
        this.send("searchBids", this.get("bidexplorerfilters"), currentPage);
      },

      firstPage() {
        this.set("currentPage", 1);
        this.send("searchBids", this.get("bidexplorerfilters"), 1);
      },

      lastPage(totalpages) {
        this.set("currentPage", totalpages);
        this.send("searchBids", this.get("bidexplorerfilters"), totalpages);
      },

      //Pagination Relations
      nextPageRelations() {
        let currentPageRelations = this.get("currentPageRelations");
        currentPageRelations++;
        this.send("getCustRelations", this.get("cusrtrelationfilters"), currentPageRelations);
      },

      prevPageRelations() {
        let currentPageRelations = this.get("currentPageRelations");
        currentPageRelations--;
        this.send("getCustRelations", this.get("cusrtrelationfilters"), currentPageRelations);
      },

      firstPageRelations() {
        this.set("currentPageRelations", 1);
        this.send("getCustRelations", this.get("cusrtrelationfilters"), 1);
      },

      lastPageRelations(totalpages) {
        this.set("currentPageRelations", totalpages);
        this.send("getCustRelations", this.get("cusrtrelationfilters"), totalpages);
      }

    }
  });

  _exports.default = _default;
});