define("ptportal/components/email/email-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rows: Ember.computed('model', function () {
      if (this.get('model') !== null) {
        let formattedArray = [];
        this.get('model').data.forEach(function (tnbidheader) {
          let pricestatus = tnbidheader.price_status;

          switch (pricestatus) {
            case 2:
              pricestatus = 'Exception';
              break;

            case 4:
              pricestatus = 'Ready';
              break;

            case 6:
              pricestatus = 'Complete';
              break;

            case 8:
              pricestatus = 'Hold';
              break;

            case 10:
              pricestatus = 'Ignore';
              break;

            case 12:
              pricestatus = 'Internal Hold';
              break;

            case 14:
              pricestatus = 'In Process';
              break;

            case 16:
              pricestatus = 'Outbound';
              break;

            default:
              pricestatus = 'Undefined';
          }

          formattedArray.pushObject({
            tnpriceid: tnbidheader.id,
            tndealnumber: tnbidheader.tndealnumber,
            tndealrevision: tnbidheader.tndealrevision,
            tnreseller: tnbidheader.tnreseller,
            tncustomer: tnbidheader.tncustomer,
            tnpricecreatedate: tnbidheader.tnpricecreatedate,
            tndealenddate: tnbidheader.tndealenddate,
            tndealstartdate: tnbidheader.tndealstartdate,
            tnstatus: pricestatus
          });
        });
        return formattedArray;
      }
    })
  });

  _exports.default = _default;
});