define("ptportal/templates/components/email/email-overview-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ti5KIMbq",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnemailid\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnmessagereceiptdate\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[12,\"class\",\"mw500\",null],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnmessagesubject\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[12,\"class\",\"mw200\",null],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnvendorprogram\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnemailstatus\"]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tnpriceemail\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/email/email-overview-row.hbs"
    }
  });

  _exports.default = _default;
});