define("ptportal/components/dashboard/bid-volume-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    receiptDate: "",
    receiptDateFrom: "",
    rows: Ember.computed('model.[]', function () {
      if (this.get('model') !== undefined && this.get('model') !== null) {
        let formattedArray = [];
        this.get('model').forEach(function (vol) {
          formattedArray.pushObject({
            vendor: vol.name,
            program: vol.programname,
            volume: vol.value
          });
        });
        return formattedArray;
      }
    }),
    actions: {
      togglecdCalendar() {
        this.flatpickrRef1.toggle();
      },

      togglecdfromCalendar() {
        this.flatpickrRef2.toggle();
      },

      updateFilters() {
        var bidvolfilters = {};
        bidvolfilters['receiptDate'] = this.receiptDate, bidvolfilters['receiptDateFrom'] = this.receiptDateFrom, this.getBidVolumeReport(bidvolfilters);
      },

      clearFilters() {
        this.set("receiptDate", "");
        this.set("receiptDateFrom", "");
        this.clearFilters();
      },

      exportReport() {
        this.exportReport();
      }

    }
  });

  _exports.default = _default;
});