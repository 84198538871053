define("ptportal/models/tncontactperson", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tncontactpersonid: _emberData.default.attr('string'),
    tnuserid: _emberData.default.attr('string'),
    tncustid: _emberData.default.attr('string'),
    tnfirstname: _emberData.default.attr('string'),
    tnlastname: _emberData.default.attr('string'),
    tnemail: _emberData.default.attr('string'),
    tntelephone: _emberData.default.attr('string'),
    tnstatus: _emberData.default.attr('string'),
    tnaccesscustrelations: _emberData.default.attr('boolean'),
    tnaccessreporting: _emberData.default.attr('boolean'),
    tnaccesssetupusers: _emberData.default.attr('boolean'),
    tnaccessexceptions: _emberData.default.attr('boolean'),
    tnaccessbidexplorer: _emberData.default.attr('boolean'),
    tnaccessbideditor: _emberData.default.attr('boolean'),
    tnsystemaccount: _emberData.default.attr('boolean'),
    tnaccessdashboard: _emberData.default.attr('boolean'),
    tnaccesscustprograms: _emberData.default.attr('boolean'),
    tnaccesscusteditor: _emberData.default.attr('boolean'),
    tnaccessanalytics: _emberData.default.attr('boolean'),
    tnaccesssendbid: _emberData.default.attr('boolean'),
    accessBidEditor: _emberData.default.attr('boolean'),
    accessBidExplorer: _emberData.default.attr('boolean'),
    accessCustomerEditor: _emberData.default.attr('boolean'),
    accessDashboard: _emberData.default.attr('boolean'),
    accessEmail: _emberData.default.attr('boolean'),
    accessExceptions: _emberData.default.attr('boolean'),
    accessExchangeRates: _emberData.default.attr('boolean'),
    accessMyCustomers: _emberData.default.attr('boolean'),
    accessPrograms: _emberData.default.attr('boolean'),
    accessSendBid: _emberData.default.attr('boolean'),
    accessUserManagement: _emberData.default.attr('boolean'),
    fullName: Ember.computed('tnfirstname', 'tnlastname', function () {
      return `${this.tnfirstname} ${this.tnlastname}`;
    }),
    avatar: Ember.computed('tnfirstname', 'tnlastname', function () {
      return `${this.tnfirstname.charAt(0)}${this.tnlastname.charAt(0)}`;
    })
  });

  _exports.default = _default;
});