define("ptportal/models/tnpriceemail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    tnmessagefrom: _emberData.default.attr('string'),
    tnmessageto: _emberData.default.attr('string'),
    tnmessagebody: _emberData.default.attr('string'),
    tnmessageid: _emberData.default.attr('string'),
    tnmessagefromaddress: _emberData.default.attr('string'),
    tnmessagesubject: _emberData.default.attr('string'),
    tnmessageattachmentcount: _emberData.default.attr('number'),
    tnmessageattchementsize: _emberData.default.attr('number'),
    tnmessagesentdate: _emberData.default.attr('date'),
    tnmessagesize: _emberData.default.attr('number'),
    tntransportid: _emberData.default.attr('string'),
    tnmessagereceiptdate: _emberData.default.attr('date'),
    tnpriceparsestatus: _emberData.default.attr('number'),
    tnpriceparsestatustxt: _emberData.default.attr('string'),
    tnemailid: _emberData.default.attr('string'),
    tnwsguid: _emberData.default.attr('string'),
    tnpriceimportid: _emberData.default.attr('string'),
    tncustid: _emberData.default.attr('string'),
    tnlog: _emberData.default.attr('string'),
    tnlogerror: _emberData.default.attr('string'),
    tnmessagesentdatetime: _emberData.default.attr('date'),
    tnmessagesentdatetimetzid: _emberData.default.attr('number'),
    tnpriceerrorlog: _emberData.default.attr('string'),
    tnpricelog: _emberData.default.attr('string'),
    tnserverid: _emberData.default.attr('string'),
    tnmessagereceiptdatetime: _emberData.default.attr('date'),
    tnmessagereceivedatetimetzid: _emberData.default.attr('number'),
    tnmessageprocessdatetime: _emberData.default.attr('date'),
    tnmessageprocessdatetimetzid: _emberData.default.attr('number'),
    tnmessageconpleteddatetime: _emberData.default.attr('date'),
    tnmessagecompleteddatetimetzid: _emberData.default.attr('number')
  });

  _exports.default = _default;
});