define("ptportal/templates/components/bidexplorer/be-relation-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dZ10aStE",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnparsedatatype\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationname\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationaddress\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationcity\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationstate\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationzipcode\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationvendorid1\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationvendorid2\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tnrelation\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/bidexplorer/be-relation-row.hbs"
    }
  });

  _exports.default = _default;
});