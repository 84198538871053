define("ptportal/controllers/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    router: Ember.inject.service('router'),
    emailDate: "",
    selectedVendor: "",

    init() {
      this._super(...arguments);

      var today = new Date();
      let emailmonth = today.getMonth() + 1;

      if (emailmonth < 10) {
        emailmonth = '0' + emailmonth;
      }

      let emaildate = today.getDate();

      if (emaildate < 10) {
        emaildate = '0' + emaildate;
      }

      var date = today.getFullYear() + '-' + emailmonth + '-' + emaildate;
      this.set("emailDate", date);
    },

    actions: {
      updateDashboard() {
        this.send('updateDashboardRoute', this.emailDate, this.selectedVendor);
      }

    }
  });

  _exports.default = _default;
});