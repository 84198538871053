define("ptportal/components/bideditor/bideditor-bidlines-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedDataType: 'Part',
    vendorpart: "",
    partdescription: "",
    bidprice: 0.00,
    listprice: 0.00,
    quantity: 0,
    startDate: null,
    endDate: null,
    discountpercentage: 0.00,
    rebate: 0.00,
    minqty: 0,
    maxqty: 0,
    partconfigid: "",
    pricetype: "",
    partline: "",

    init() {
      this._super(...arguments);

      this.dataType = ['Part', 'PartLine', 'Bundle', 'Config', 'PartFamily'];
    },

    actions: {
      closeModal() {
        this.closeModal();
      },

      addBidline() {
        var bidline = {};
        bidline['selectedDataType'] = this.get('selectedDataType');
        bidline['vendorpart'] = this.get('vendorpart');
        bidline['partdescription'] = this.get('partdescription');
        bidline['bidprice'] = this.get('bidprice');
        bidline['listprice'] = this.get('listprice');
        bidline['quantity'] = this.get('quantity');
        bidline['startdate'] = this.startDate;
        bidline['enddate'] = this.endDate;
        bidline['discountpercentage'] = this.get('discountpercentage');
        bidline['rebate'] = this.get('rebate');
        bidline['minqty'] = this.get('minqty');
        bidline['maxqty'] = this.get('maxqty');
        bidline['partconfigid'] = this.get('partconfigid');
        bidline['pricetype'] = this.get('pricetype');
        bidline['partline'] = this.get('partline');
        this.addBidline(bidline);
        this.closeModal();
      },

      togglesdCalendar() {
        this.flatpickrRef1.toggle();
      },

      toggleedCalendar() {
        this.flatpickrRef2.toggle();
      },

      clearsdCalendar() {
        this.flatpickrRef1.clear();
      },

      clearedCalendar() {
        this.flatpickrRef2.clear();
      }

    }
  });

  _exports.default = _default;
});