define("ptportal/routes/exceptions", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-cli-file-saver/mixins/file-saver", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _fileSaver, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _fileSaver.default, {
    swal: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    router: Ember.inject.service('router'),
    session: Ember.inject.service('session'),
    custdata: Ember.inject.service('cust-data'),
    currentUser: Ember.inject.service('current-user'),
    price: null,
    lines: null,
    users: null,
    priceexceptions: null,
    pricerelations: null,
    custrelations: null,
    selectedPriceId: 0,
    selectedResellerId: 0,
    exceptionfilters: null,
    currentPage: 1,
    totalRows: 0,
    currentPageRelations: 1,
    originalDocUrl: "",
    isAlso: 0,
    isCustomerCDW: 0,
    custRelationType: "Reseller",
    //------START-------TO MAKE THE OPEN IN EXCEPTION WORK-----------START----------------//
    queryPriceId: "",
    isqueryparams: 0,
    queryParams: {
      priceid: {
        replace: true,
        refreshModel: true
      }
    },
    //------END-------TO MAKE THE OPEN IN EXCEPTION WORK------------END---------------//
    beforeModel: function () {
      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      if (this.get("currentUser").user.accessExceptions) {
        return true;
      }

      this.get('session').invalidate();
    },

    model(params) {
      if (this.get("currentUser").user.tncustid == "CU100055") {
        this.set("isAlso", 1);
      }

      if (this.get("currentUser").user.tncustid == "CU100007") {
        this.set("isCustomerCDW", 1);
        this.set("custRelationType", "Customer");
      } //------START-------TO MAKE THE OPEN IN EXCEPTION WORK-----------START----------------//


      var exceptionfilters = {};

      if (params.priceid != this.get("queryPriceId")) {
        this.set("isqueryparams", 0);
      }

      if (params.priceid && this.get("isqueryparams") == 0) {
        exceptionfilters['priceid'] = params.priceid;
        this.set("queryPriceId", params.priceid);
        this.searchExceptions(exceptionfilters, 1);
      }

      this.set("isqueryparams", 1); //------END-------TO MAKE THE OPEN IN EXCEPTION WORK------------END---------------//

      if (this.get("users") == null) {
        this.set("users", this.store.peekAll('tncontactperson'));
      } // if(this.get("users") == null){
      //   this.set("users", this.store.findAll('tncontactperson'));
      //   console.log("users>>", this.get('users'));
      // }
      // else if(this.get("users").length <= 1){
      //   this.set("users", this.store.findAll('tncontactperson'));
      //   console.log("users >>>>", this.get('users'));
      // }


      if (this.get('price') === null) {
        this.set('price', []);
      }

      if (this.get('lines') === null) {
        this.set('lines', []);
      }

      if (this.get('pricerelations') === null) {
        this.set('pricerelations', []);
      }

      return Ember.RSVP.hash({
        prices: this.get('prices'),
        price: this.get('price'),
        lines: this.get('lines'),
        priceexceptions: this.get('priceexceptions'),
        pricerelations: this.get('pricerelations'),
        custrelations: this.get('custrelations'),
        selectedPriceId: this.get('selectedPriceId'),
        selectedResellerId: this.get('selectedResellerId'),
        currentPage: this.get('currentPage'),
        totalRows: this.get('totalRows'),
        currentPageRelations: this.get('currentPageRelations'),
        originalDocUrl: this.get('originalDocUrl'),
        exceptionfilters: this.get('exceptionfilters'),
        users: this.get('users'),
        isAlso: this.get('isAlso'),
        isCustomerCDW: this.get('isCustomerCDW')
      });
    },

    //------START-------TO MAKE THE OPEN IN EXCEPTION WORK-----------START----------------//
    searchExceptions: function (exceptionfilters, pagenumber) {
      this.set("currentPage", pagenumber);
      this.set("exceptionfilters", exceptionfilters);
      this.set("originalDocUrl", 0);
      this.set("price", null);
      this.set("lines", null);
      this.set("priceexceptions", null);
      this.set("pricerelations", null);
      this.set("custrelations", null);
      var query = {}; // Build Query Start

      if (exceptionfilters.priceid) {
        query['TNPRICEID'] = exceptionfilters.priceid;
      }

      query['page'] = pagenumber;
      let price = this.get('ajax').request('explorer_prices', {
        type: 'GET',
        data: query
      });
      this.set("prices", price);
      this.set("totalRows", price.totalrecords);
      this.set("selectedPriceId", 0);
      this.set('lines', null);
      this.set('priceexceptions', null);
    },
    //------END-------TO MAKE THE OPEN IN EXCEPTION WORK------------END---------------//
    actions: {
      searchExceptions(exceptionfilters, pagenumber) {
        this.set("currentPage", pagenumber);
        this.set("exceptionfilters", exceptionfilters);
        this.set("originalDocUrl", 0);
        this.set("price", null);
        this.set("lines", null);
        this.set("priceexceptions", null);
        this.set("pricerelations", null);
        this.set("custrelations", null);
        document.getElementById("exception-tab").click();
        document.getElementById("overview-tab").click();
        var query = {}; // Build Query Start

        if (exceptionfilters.priceid) {
          query['TNPRICEID'] = exceptionfilters.priceid;
        }

        if (exceptionfilters.tnvendid) {
          query['TNVENDID'] = exceptionfilters.tnvendid;
        }

        if (exceptionfilters.tnprogramid) {
          query['TNPROGRAMID'] = exceptionfilters.tnprogramid;
        }

        if (exceptionfilters.selectedContactPerson) {
          query['TNCONTACTPERSONID'] = exceptionfilters.selectedContactPerson;
        } // if (exceptionfilters.isProd == 1) {
        //   query['PRODSEARCH'] = 1;
        // }


        if (exceptionfilters.dataType == "Production") {
          query['PRODSEARCH'] = 1;
        }

        if (exceptionfilters.dataType == "Test") {
          query['TESTSEARCH'] = 1;
        }

        switch (exceptionfilters.priceStatus) {
          case "Ready":
            query['TNPRICESTATUS'] = 4;
            break;

          case "Complete":
            query['TNPRICESTATUS'] = 6;
            break;

          case "Hold":
            query['TNPRICESTATUS'] = 8;
            break;

          case "Ignore":
            query['TNPRICESTATUS'] = 10;
            break;

          case "Exception":
            query['TNPRICESTATUS'] = 2;
            break;

          default:
            query['TNPRICESTATUS'] = 2;
            break;
        }

        query['page'] = pagenumber;
        this.get('ajax').request('explorer_prices', {
          type: 'GET',
          data: query
        }).then(price => {
          this.set("prices", price);
          this.set("totalRows", price.totalrecords);
          this.refresh();
        });
        this.set("selectedPriceId", 0);
        this.set('lines', null);
        this.set('priceexceptions', null);
        this.refresh();
      },

      selectPrice(sprice) {
        this.set("selectedPriceId", sprice);
        document.getElementById("exception-tab").click();
        this.set("price", null);
        this.set("lines", null);
        this.set("priceexceptions", null);
        this.set("pricerelations", null);
        this.set("custrelations", null);
        this.store.query('tnpriceexception', {
          TNPRICEID: sprice
        }).then(priceexceptions => {
          this.set('priceexceptions', priceexceptions);
          this.refresh();
        });
        this.get('ajax').request('explorer_prices', {
          type: 'GET',
          data: {
            TNPRICEID: sprice
          }
        }).then(price => {
          this.set("exceptionCount", price.data[0].exception_count);

          if (price.data[0]) {
            if (price.data[0].docurl == "") {
              this.set("originalDocUrl", 0);
            } else {
              this.set("originalDocUrl", price.data[0].docurl);
            }
          }

          this.refresh();
        });
        this.refresh();
      },

      originalDoc() {
        // window.open(
        //   this.get("originalDocUrl"),
        //   '_blank'
        // );
        let contentType = 'application/json';
        let filename = this.get("selectedPriceId") + '.msg';
        return this.get('ajax').request('get_bid_doc', {
          type: 'GET',
          dataType: 'arraybuffer',
          data: {
            TNPRICEID: this.get("selectedPriceId")
          }
        }).then(content => this.saveFileAs(filename, content, contentType)).catch(() => {
          (0, _sweetalert.default)('An Error Occoured', 'Error retrieving document!', 'error');
        });
      },

      loadSinglePrice() {
        this.store.query('tnprice', {
          TNPRICEID: this.get("selectedPriceId"),
          DATAAREAID: 'itv'
        }).then(singleprice => {
          this.set('price', singleprice);
          this.refresh();
        });
      },

      loadPriceRelations() {
        this.store.query('tnpricerelation', {
          TNPRICEID: this.get("selectedPriceId")
        }).then(pricerelations => {
          this.set('pricerelations', pricerelations);
          this.refresh();
        });
      },

      loadPriceLines() {
        this.store.query('tnpriceline', {
          TNPRICEID: this.get("selectedPriceId")
        }).then(pricelines => {
          this.set('lines', pricelines);
          this.refresh();
        });
      },

      selectReseller(custRelation) {
        this.set("selectedResellerId", custRelation);
        this.refresh();
      },

      removeException(exceptionId) {
        this.get('swal').open({
          title: 'Are you sure you want to remove this Exception?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Remove",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request("tnpriceexceptions/" + exceptionId, {
              type: 'PUT'
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Error Removing Exception!', 'An error occoured while removing the exception.', 'error');
              } else {
                (0, _sweetalert.default)('Exception Removed', 'Exception was successfully removed.', 'success');
              }

              this.send("selectPrice", this.get("selectedPriceId")); // this.send("searchExceptions", this.get("exceptionfilters"), this.get("currentPage"));
            });
          }
        });
      },

      removeRelation(relationId) {
        let query = {
          TNPRICERELATIONID: relationId,
          TNPRICEID: this.get("selectedPriceId")
        };
        this.get('swal').open({
          title: 'Are you sure you want to delete this ' + this.get("custRelationType") + '?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Delete",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request("tnpricerelations/" + relationId, {
              type: 'DELETE',
              data: query
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)(this.get("custRelationType") + ' Deleted', this.get("custRelationType") + ' was successfully deleted.', 'success');
              } else {
                (0, _sweetalert.default)(this.get("custRelationType") + ' Delete Failed!', 'An error occoured while deleting the ' + this.get("custRelationType"), 'error');
              }

              this.send("loadPriceRelations");
            });
          }
        });
      },

      removeAllExceptions() {
        let query = {
          TNPRICEEXCEPTIONID: "ALL",
          TNPRICEID: this.get("selectedPriceId")
        };
        this.get('swal').open({
          title: 'Are you sure you want to remove all the Exceptions?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Remove",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request("tnpriceexceptions/ALL", {
              type: 'PUT',
              data: query
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Error Removing Exceptions!', 'An error occoured while removing the exceptions.', 'error');
              } else {
                (0, _sweetalert.default)('All Exceptions Removed', 'Exceptions were successfully removed.', 'success');
              } // this.send("selectPrice", this.get("selectedPriceId"));


              this.send("searchExceptions", this.get("exceptionfilters"), this.get("currentPage"));
            });
          }
        });
      },

      addCustRelationException(priceexceptionid) {
        let query = {
          TNPRICEEXCEPTIONID: priceexceptionid,
          TNCUSTRELATIONID: this.get("selectedResellerId"),
          TNPRICEID: this.get("selectedPriceId")
        }; // console.log("Resolve Exception with following: ", query);

        this.get('ajax').request("exceptions/" + priceexceptionid, {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data) {
            if (data.message) {
              (0, _sweetalert.default)('Exception Resolved', 'Exception was successfully resolved.', 'success');
            } else {
              (0, _sweetalert.default)('Error!', 'An error occoured while resolving the exception.', 'error');
            }
          } // this.send("searchExceptions", this.get("exceptionfilters"), this.get("currentPage"));


          this.send("selectPrice", this.get("selectedPriceId"));
        });
      },

      resolveException(priceexceptionid, resolutionstring) {
        let query = {
          TNPRICEEXCEPTIONID: priceexceptionid,
          TNPRICEEXCEPTIONRESOLVESTR: resolutionstring,
          TNPRICEID: this.get("selectedPriceId")
        }; // console.log("Resolve Exception with following: ", query);

        this.get('ajax').request("exceptions/" + priceexceptionid, {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data) {
            if (data.message) {
              (0, _sweetalert.default)('Exception Resolved', 'Exception was successfully resolved.', 'success');
            } else {
              (0, _sweetalert.default)('Error!', 'An error occoured while resolving the exception.', 'error');
            }
          } // this.send("searchExceptions", this.get("exceptionfilters"), this.get("currentPage"));


          this.send("selectPrice", this.get("selectedPriceId"));
        });
      },

      getCustRelations(reseller, pagenumber) {
        this.set("currentPageRelations", pagenumber);
        this.set("cusrtrelationfilters", reseller);
        this.get('ajax').request('tncustrelations', {
          type: 'GET',
          data: {
            TNRELATIONNAME: reseller.resellername,
            TNCUSTINTERNALID1: reseller.custinternalid1,
            page: pagenumber
          }
        }).then(data => {
          this.set('custrelations', data);
          this.refresh();
        });
        this.refresh();
      },

      clearCustRelations() {
        this.set('custrelations', null);
        this.refresh();
      },

      addCustRelation() {
        let query = {
          TNCUSTRELATIONID: this.get("selectedResellerId"),
          TNPRICEID: this.get("selectedPriceId")
        };
        this.get('ajax').request("tnpricerelations/", {
          type: 'POST',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)(this.get("custRelationType") + ' Added', this.get("custRelationType") + ' was successfully added.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while adding the ' + this.get("custRelationType") + '.', 'error');
          }

          this.send("loadPriceRelations");
        });
      },

      updateNotes(notes) {
        let query = {
          TNDEALSPECIALTERMS: notes.specialterms,
          TNDEALNOTES: notes.dealnotes
        };
        this.get('ajax').request("tnprices/" + this.get("selectedPriceId"), {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Notes Updated', 'Notes were successfully added.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while updating the notes.', 'error');
          }

          this.send("loadSinglePrice");
        });
      },

      openBidEditor() {
        this.get('router').transitionTo('bideditor', {
          queryParams: {
            priceid: this.get("selectedPriceId")
          }
        });
      },

      setStatus(status) {
        this.get('ajax').request("tnprices/" + this.get("selectedPriceId"), {
          type: 'PUT',
          data: {
            TNPRICESTATUS: status
          }
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Bid status updated!', 'Bid status was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Bid status update failed!', 'An error occoured while updating the bid status.', 'error');
          }

          this.send("searchExceptions", this.get("exceptionfilters"), this.get("currentPage"));
        });
      },

      setAssignee(priceid, contactperson) {
        this.get('ajax').request("tnprices/" + priceid, {
          type: 'PUT',
          data: {
            TNCONTACTPERSONID: contactperson.id
          }
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('User Assigned!', contactperson.tnfirstname + ' ' + contactperson.tnlastname + ' was successfully assigned to price id: ' + priceid, 'success');
          } else if (data.error) {
            (0, _sweetalert.default)('User already assigned!', data.error, 'info');
          } else {
            (0, _sweetalert.default)('Bid status update failed!', 'An error occoured while setting the assignee.', 'error');
          }

          this.send("searchExceptions", this.get("exceptionfilters"), this.get("currentPage"));
        });
      },

      claimException(priceid) {
        this.get('ajax').request("tnprices/" + priceid, {
          type: 'PUT',
          data: {
            TNCONTACTPERSONID: this.get("currentUser").user.id
          }
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('User Assigned!', this.get("currentUser").user.tnfirstname + ' ' + this.get("currentUser").user.tnlastname + ' was successfully assigned to price id: ' + priceid, 'success');
          } else if (data.error) {
            (0, _sweetalert.default)('User already assigned!', data.error, 'info');
          } else {
            (0, _sweetalert.default)('Bid status update failed!', 'An error occoured while setting the assignee.', 'error');
          }

          this.send("searchExceptions", this.get("exceptionfilters"), this.get("currentPage"));
        });
      },

      removeAssignee(priceid) {
        this.get('swal').open({
          title: 'Remove Assignee?',
          text: "Are you sure you want to remove the Assignee for price id " + priceid,
          showCancelButton: true,
          confirmButtonText: "Remove",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request("tnprices/" + priceid, {
              type: 'PUT',
              data: {
                TNCONTACTPERSONID: ""
              }
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Assignee Removed!', 'Assignee was successfully removed for price id ' + priceid, 'success');
              } else {
                (0, _sweetalert.default)('Remove assignee failed!', 'An error occoured while removing the assignee.', 'error');
              }

              this.send("searchExceptions", this.get("exceptionfilters"), this.get("currentPage"));
            });
          }
        });
      },

      clearFilters() {
        this.set("exceptionfilters", null);
        this.set("prices", null);
        this.set("price", null);
        this.set("lines", null);
        this.set("priceexceptions", null);
        this.set("pricerelations", null);
        this.set("custrelations", null);
        this.set("selectedPriceId", 0);
        this.set("selectedResellerId", 0);
        this.set("currentPage", 1);
        this.set("totalRows", 0);
        this.set("originalDocUrl", "");
        document.getElementById("exception-tab").click();
        document.getElementById("overview-tab").click();
        this.refresh();
      },

      //Pagination
      nextPage() {
        let currentPage = this.get("currentPage");
        currentPage++;
        this.send("searchExceptions", this.get("exceptionfilters"), currentPage);
      },

      prevPage() {
        let currentPage = this.get("currentPage");
        currentPage--;
        this.send("searchExceptions", this.get("exceptionfilters"), currentPage);
      },

      firstPage() {
        this.set("currentPage", 1);
        this.send("searchExceptions", this.get("exceptionfilters"), 1);
      },

      lastPage(totalpages) {
        this.set("currentPage", totalpages);
        this.send("searchExceptions", this.get("exceptionfilters"), totalpages);
      },

      //Pagination Relations
      nextPageRelations() {
        let currentPageRelations = this.get("currentPageRelations");
        currentPageRelations++;
        this.send("getCustRelations", this.get("cusrtrelationfilters"), currentPageRelations);
      },

      prevPageRelations() {
        let currentPageRelations = this.get("currentPageRelations");
        currentPageRelations--;
        this.send("getCustRelations", this.get("cusrtrelationfilters"), currentPageRelations);
      },

      firstPageRelations() {
        this.set("currentPageRelations", 1);
        this.send("getCustRelations", this.get("cusrtrelationfilters"), 1);
      },

      lastPageRelations(totalpages) {
        this.set("currentPageRelations", totalpages);
        this.send("getCustRelations", this.get("cusrtrelationfilters"), totalpages);
      }

    }
  });

  _exports.default = _default;
});