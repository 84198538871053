define("ptportal/templates/components/programs/programs-products-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aSB72O3b",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"productline\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"description\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"custmarkup\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",false],[23,\"class\",\"icon icon-account-edit\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"editMode\"],null],[10],[1,1,0,0,\"\\n    \"],[7,\"bs-tooltip\",[],[[\"@title\"],[\"Edit Product\"]],null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",true],[13,\"onclick\",[31,241,12,[27,[26,1,\"CallHead\"],[]],[\"deleteMarkup\",[27,[26,2,\"Expression\"],[]]],null],null],[12,\"class\",\"icon icon-account-remove\",null],[10],[1,1,0,0,\"\\n    \"],[7,\"bs-tooltip\",[],[[\"@title\"],[\"Delete Product\"]],null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,391,31,[27,[26,3,\"CallHead\"],[]],null,[[\"markuprow\",\"updateMarkup\",\"closeModal\"],[[27,[26,2,\"Expression\"],[]],[31,457,12,[27,[26,1,\"CallHead\"],[]],[\"updateMarkup\"],null],[31,498,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"editMode\"],null]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"route-action\",\"markuprow\",\"programs/programs-products-edit\",\"editMode\",\"if\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/programs/programs-products-row.hbs"
    }
  });

  _exports.default = _default;
});