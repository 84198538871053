define("ptportal/routes/tncontactpeople", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    swal: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service('router'),
    session: Ember.inject.service('session'),
    beforeModel: function () {
      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      if (this.get("currentUser").user.accessUserManagement) {
        return true;
      }

      this.get('session').invalidate();
    },

    model() {
      return this.store.findAll('tncontactperson');
    },

    actions: {
      deleteUser(sUser) {
        this.get('swal').open({
          title: 'Are you sure you want to delete this user?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Delete",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request("tncontactpeople/" + sUser.tncontactpersonid, {
              type: 'DELETE'
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('User Deleted', 'User was successfully deleted, please refresh the page to see the change.', 'success');
              } else {
                (0, _sweetalert.default)('User Delete Failed!', 'An error occoured while deleting the user.', 'error');
              }

              this.refresh();
            });
          }
        });
      },

      createUser(user) {
        let query = {
          TNCUSTID: this.currentUser.user.tncustid,
          TNUSERID: user.tnuserid,
          TNFIRSTNAME: user.firstname,
          TNLASTNAME: user.lastname,
          TNPASSWORD: user.tnpassword,
          TNEMAIL: user.email,
          TNTELEPHONE: user.telephone
        };
        this.get('ajax').request("tncontactpeople/", {
          type: 'POST',
          data: query
        }).then(data => {
          if (data.errors) {
            if (data.errors == 'User ID already exists.') {
              (0, _sweetalert.default)('User Add Failed!', 'An user with this User ID already exists.', 'error');
              return;
            }
          }

          if (!data.data) {
            (0, _sweetalert.default)('User Add Failed!', 'An error occoured while adding the user.', 'error');
            return;
          }

          let query2 = {
            TNCONTACTPERSONID: data.data.attributes.tncontactpersonid,
            ACCESSDASHBOARD: user.accessdashboard ? 1 : 0,
            ACCESSBIDEXPLORER: user.accessbidexplorer ? 1 : 0,
            ACCESSCUSTRELATIONS: user.accesscustrelations ? 1 : 0,
            ACCESSEXCEPTIONS: user.accessexceptions ? 1 : 0,
            ACCESSBIDEDITOR: user.accessbideditor ? 1 : 0,
            ACCESSEMAIL: user.accessemail ? 1 : 0,
            ACCESSEXCHANGERATE: user.accessexchange ? 1 : 0,
            ACCESSSETUPUSERS: user.accessusermanagement ? 1 : 0,
            ACCESSSENDBID: user.accesssendbid ? 1 : 0,
            ACCESSCUSTEDITOR: user.accesscusteditor ? 1 : 0,
            ACCESSCUSTPROGRAMS: user.accesscustprograms ? 1 : 0
          };
          this.get('ajax').request("tnportalaccessrights/" + data.data.attributes.tncontactpersonid, {
            type: 'PUT',
            data: query2
          }).then(data2 => {
            if (data2.message) {
              (0, _sweetalert.default)('User Added', 'User was successfully added.', 'success');
            } else {
              (0, _sweetalert.default)('User Add Failed!', 'An error occoured while adding the user.', 'error');
            }

            this.refresh();
          });
        });
      },

      updateUser(user) {
        let query = {
          TNUSERID: user.tnuserid,
          TNFIRSTNAME: user.firstname,
          TNLASTNAME: user.lastname,
          TNEMAIL: user.email,
          TNTELEPHONE: user.telephone,
          TNCONTACTPERSONID: user.contactpersonid
        };

        if (user.tnpassword) {
          query['TNPASSWORD'] = user.tnpassword;
        }

        this.get('ajax').request("tncontactpeople/" + user.contactpersonid, {
          type: 'PUT',
          data: query
        }).then(data => {
          if (!data.message) {
            (0, _sweetalert.default)('User Update Failed!', 'An error occoured while updating the user.', 'error');
            return;
          }

          let query2 = {
            TNCONTACTPERSONID: user.contactpersonid,
            ACCESSDASHBOARD: user.accessdashboard ? 1 : 0,
            ACCESSBIDEXPLORER: user.accessbidexplorer ? 1 : 0,
            ACCESSCUSTRELATIONS: user.accesscustrelations ? 1 : 0,
            ACCESSEXCEPTIONS: user.accessexceptions ? 1 : 0,
            ACCESSBIDEDITOR: user.accessbideditor ? 1 : 0,
            ACCESSEMAIL: user.accessemail ? 1 : 0,
            ACCESSEXCHANGERATE: user.accessexchange ? 1 : 0,
            ACCESSSETUPUSERS: user.accessusermanagement ? 1 : 0,
            ACCESSSENDBID: user.accesssendbid ? 1 : 0,
            ACCESSCUSTEDITOR: user.accesscusteditor ? 1 : 0,
            ACCESSCUSTPROGRAMS: user.accesscustprograms ? 1 : 0
          };
          this.get('ajax').request("tnportalaccessrights/" + user.contactpersonid, {
            type: 'PUT',
            data: query2
          }).then(data2 => {
            if (data2.message) {
              (0, _sweetalert.default)('User Updated', 'User was successfully updated.', 'success');
            } else {
              (0, _sweetalert.default)('User Update Failed!', 'An error occoured while updating the user.', 'error');
            }

            this.refresh();
          });
        });
      }

    }
  });

  _exports.default = _default;
});