define("ptportal/components/usermanagement/users-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editMode: false,
    actions: {
      editUser() {
        this.toggleProperty("editMode");
      },

      updateUser(user) {
        //  // console.log("Row User Action Called", user);
        this.updateUser(user);
      }

    }
  });

  _exports.default = _default;
});