define("ptportal/components/editcustomers/editcust-salesrelations-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    employeename: null,
    salesid: null,
    email: null,
    selectedEmployeeType: null,
    custsalesrelationid: null,

    init() {
      this._super(...arguments);

      this.employeeType = ['Sales Manager', 'Sales Group', 'Sales Rep In', 'Sales Rep Out'];
    },

    didReceiveAttrs() {
      this.set('custsalesrelationid', this.salesrelation.tncustsalesrelationid);
      this.set('employeename', this.salesrelation.tncustsalesname);
      this.set('salesid', this.salesrelation.tncustsalesid);
      this.set('email', this.salesrelation.tncustsalesemail);
      let salesrelationtype = this.salesrelation.tncustsalestype;

      switch (salesrelationtype) {
        case 2:
          salesrelationtype = 'Sales Rep In';
          break;

        case 4:
          salesrelationtype = 'Sales Rep Out';
          break;

        case 6:
          salesrelationtype = 'Sales Manager';
          break;

        case 8:
          salesrelationtype = 'Sales Group';
          break;

        default:
          salesrelationtype = 'Undefined';
      }

      this.set('selectedEmployeeType', salesrelationtype);
    },

    actions: {
      closeModal() {
        this.closeModal();
      },

      updateSalesRelation() {
        var salesrelation = {};
        salesrelation['custsalesrelationid'] = this.get('custsalesrelationid');
        salesrelation['salesrelationname'] = this.get('employeename');
        salesrelation['salesid'] = this.get('salesid');
        salesrelation['email'] = this.get('email');
        salesrelation['salesrelationtype'] = this.get('selectedEmployeeType');
        this.updateSalesRelation(salesrelation);
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});