define("ptportal/routes/programs", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    swal: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    router: Ember.inject.service('router'),
    custdata: Ember.inject.service('cust-data'),
    selectedCustProgramId: 0,
    program: null,
    markup: null,
    beforeModel: function () {
      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      if (this.get("currentUser").user.accessPrograms) {
        return true;
      }

      this.get('session').invalidate();
    },

    model() {
      let programs = [];
      programs = this.get('ajax').request('tncustprograms', {
        type: 'GET'
      });
      return Ember.RSVP.hash({
        programs: programs,
        program: this.get("program"),
        markup: this.get("markup"),
        selectedCustProgramId: this.get("selectedCustProgramId")
      });
    },

    actions: {
      selectCustProgram(custprogramid, editmode) {
        this.set("selectedCustProgramId", custprogramid);
        let singlecustprogram = this.get('ajax').request("tncustprograms/" + this.get("selectedCustProgramId"), {
          type: 'GET'
        });
        this.set('program', singlecustprogram);
        let custMarkupList = this.get('ajax').request("tncustmarkups/" + this.get("selectedCustProgramId"), {
          type: 'GET'
        });
        this.set('markup', custMarkupList);

        if (editmode == false) {
          this.refresh();
        }
      },

      updateCustProgram(custprogram) {
        // console.log("Update Cust Program with", custprogram);
        let query = {
          TNCUSTIDENTIFIER: custprogram.custinternalid1,
          TNCUSTPROGRAMMARKUP: custprogram.custprogrammarkup
        };
        this.get('ajax').request("tncustprograms/" + custprogram.custprogramid, {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Customer Program Updated', 'Customer Program was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Customer Program update Failed!', 'An error occoured while updating the Customer Program.', 'error');
          }

          this.refresh();
        });
      },

      updateGeneral(general) {
        let query = {
          TNCUSTPROGRAMTERMS: general.programterms,
          TNUSERID: general.userid,
          TNPASSWORD: general.password
        }; // console.log("Update General", query);

        this.get('ajax').request("tncustprograms/" + this.get("selectedCustProgramId"), {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Customer Program Updated', 'Customer Program was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Customer Program update Failed!', 'An error occoured while updating the Customer Program.', 'error');
          }

          this.send("selectCustProgram", this.get("selectedCustProgramId"), false);
        });
      },

      updateMarketing(marketing) {
        let query = {
          TNNOTIFICATIONEMAILFROM: marketing.emailfrom,
          TNNOTIFICATIONEMAILINTERNAL: marketing.emailinternal,
          TNCOMMENTTEXT1: marketing.comment1,
          TNCOMMENTTEXT2: marketing.comment2,
          TNCOMMENTTEXT3: marketing.comment3,
          TNMARKETINGTEXT1: marketing.marketing1,
          TNMARKETINGTEXT2: marketing.marketing2,
          TNMARKETINGTEXT3: marketing.marketing3
        }; // console.log("Update Marketing", query);

        this.get('ajax').request("tncustprograms/" + this.get("selectedCustProgramId"), {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Customer Program Updated', 'Customer Program was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Customer Program update Failed!', 'An error occoured while updating the Customer Program.', 'error');
          }

          this.send("selectCustProgram", this.get("selectedCustProgramId"), false);
        });
      },

      createMarkup(markup) {
        let query = {
          TNVENDPARTLINE: markup.productline,
          TNVENDPARTDESC: markup.description,
          TNCUSTPROGRAMMARKUP: markup.markup,
          TNCUSTPROGRAMID: this.get("selectedCustProgramId")
        };
        this.get('ajax').request("tncustmarkups/", {
          type: 'POST',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Product Added', 'Product was successfully added.', 'success');
          } else {
            (0, _sweetalert.default)('Product Add Failed!', 'An error occoured while adding the product.', 'error');
          }

          this.send("selectCustProgram", this.get("selectedCustProgramId"), false);
        });
      },

      deleteMarkup(markup) {
        // console.log("Delete Markup with id:", markup.custmarkupid),
        this.get('swal').open({
          title: 'Are you sure you want to delete this Product?',
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Delete",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            this.get('ajax').request("tncustmarkups/" + this.get("selectedCustProgramId"), {
              type: 'DELETE',
              data: {
                TNCUSTMARKUPID: markup.custmarkupid
              }
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Product Deleted', 'Product was successfully deleted.', 'success');
              } else {
                (0, _sweetalert.default)('Product Delete Failed!', 'An error occoured while deleting the product.', 'error');
              }

              this.send("selectCustProgram", this.get("selectedCustProgramId"), false);
            });
          }
        });
      },

      updateMarkup(markup) {
        let query = {
          TNVENDPARTLINE: markup.productline,
          TNVENDPARTDESC: markup.description,
          TNCUSTPROGRAMMARKUP: markup.custmarkup,
          TNCUSTMARKUPID: markup.custmarkupid
        };
        this.get('ajax').request("tncustmarkups/" + this.get("selectedCustProgramId"), {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Product Updated', 'Product was successfully updated.', 'success');
          } else {
            (0, _sweetalert.default)('Product update Failed!', 'An error occoured while updating the product.', 'error');
          }

          this.send("selectCustProgram", this.get("selectedCustProgramId"), false);
        });
      }

    }
  });

  _exports.default = _default;
});