define("ptportal/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),

    load() {
      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('tncontactperson', {
          tnuserid: 'me'
        }).then(tncontactperson => {
          this.set('user', tncontactperson); //Loading contactperson table data upon login

          this.store.findAll('tncontactperson');
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }

  });

  _exports.default = _default;
});