define("ptportal/helpers/is-equal-by-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function ([leftSide, rightSide, path]) {
    if (path) {
      return Ember.get(leftSide, path) === rightSide;
    } else {
      return leftSide === rightSide;
    }
  });

  _exports.default = _default;
});