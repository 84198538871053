define("ptportal/components/exceptions/exceptions-bidlines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rows: Ember.computed('model', function () {
      if (this.get('model').length !== undefined) {
        let formattedArray = [];
        this.get('model').forEach(function (tnbidlines) {
          // let linestartdate = tnbidlines.tnlinestartdate;
          // if(linestartdate == 'Sun Dec 31 1899 19:00:00 GMT-0500 (Eastern Standard Time)'){
          //   linestartdate = " "
          // }
          // else{
          //   let linestartdatemm = (linestartdate.getMonth()+1);
          //   if(linestartdatemm<10){
          //     linestartdatemm = '0'+linestartdatemm
          //   }
          //
          //   let linestartdatedd = (linestartdate.getDate()+1);
          //   if(linestartdatedd<10){
          //     linestartdatedd = '0'+linestartdatedd
          //   }
          //   linestartdate = linestartdate.getFullYear() + "-" + linestartdatemm + "-" + linestartdatedd;
          // }
          //
          //
          //
          // let lineenddate = tnbidlines.tnlineenddate;
          // if(lineenddate == 'Sun Dec 31 1899 19:00:00 GMT-0500 (Eastern Standard Time)'){
          //  lineenddate = " "
          //  }
          // else{
          //   let lineenddatemm = (lineenddate.getMonth()+1);
          //   if(lineenddatemm<10){
          //     lineenddatemm = '0'+lineenddatemm
          //   }
          //
          //   let lineenddatedd = (lineenddate.getDate()+1);
          //   if(lineenddatedd<10){
          //     lineenddatedd = '0'+lineenddatedd
          //   }
          //   lineenddate = lineenddate.getFullYear() + "-" + lineenddatemm + "-" + lineenddatedd;
          //  }
          // // console.log("Part",tnbidlines.tnvendpart);
          // // console.log("Date",tnbidlines.tnlinestartdate);
          // // console.log("Date Parsed",linestartdate);
          let linestartdate = tnbidlines.tnlinestartdate.replace(/T[0-9][0-9]:00:00.000Z/, '');

          if (linestartdate == '1900-01-01') {
            linestartdate = "";
          }

          let lineenddate = tnbidlines.tnlineenddate.replace(/T[0-9][0-9]:00:00.000Z/, '');

          if (lineenddate == '1900-01-01') {
            lineenddate = "";
          }

          let linestatus = tnbidlines.tnlinestatus;

          switch (linestatus) {
            case 0:
              linestatus = 'Current';
              break;

            case 2:
              linestatus = 'Historical';
              break;

            default:
              linestatus = '';
          }

          formattedArray.pushObject({
            tnvendpart: tnbidlines.tnvendpart,
            tnvendpartdesc: tnbidlines.tnvendpartdesc,
            tndiscountpercentage: tnbidlines.tndiscountpercentage.toFixed(2),
            tnquantity: tnbidlines.tnquantity,
            tnquantitymax: tnbidlines.tnquantitymax,
            tnquantitymin: tnbidlines.tnquantitymin,
            tnlinestartdate: linestartdate,
            tnlineenddate: lineenddate,
            tnpricelist: tnbidlines.tnpricelist.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            tnrebate: tnbidlines.tnrebate.toFixed(2),
            tnpricebid: tnbidlines.tnpricebid.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            tnvendpartline: tnbidlines.tnvendpartline,
            tnlinenumber: tnbidlines.tnlinenumber,
            tnlinestatus: linestatus
          });
        });
        return formattedArray;
      }
    })
  });

  _exports.default = _default;
});