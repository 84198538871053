define("ptportal/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t/DvfUix",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[10],[1,1,0,0,\" Home Page \"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ptportal/templates/index.hbs"
    }
  });

  _exports.default = _default;
});