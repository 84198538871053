define("ptportal/components/mycustomers/mycust-overview-rows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['isSelected'],
    isSelected: false,

    init() {
      this._super(...arguments);

      if (this.get("selectedPriceRelationId") === this.customers.tnpricerelationid) {
        this.toggleProperty("isSelected");
      }
    },

    click: function () {
      if (this.get("isSelected") == false) {
        this.toggleProperty("isSelected", true);
      }

      let selection = this.customers.tnpricerelationid;
      let relationstatus = this.customers.tnrelationstatus;
      this.selectPriceRelation(selection, relationstatus);
    },

    didUpdate() {
      this._super(...arguments);

      if (this.get("selectedPriceRelationId") !== this.customers.tnpricerelationid) {
        this.set("isSelected", false);
      }
    }

  });

  _exports.default = _default;
});