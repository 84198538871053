define("ptportal/services/ajax", ["exports", "ember-ajax/services/ajax", "ptportal/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service('session'),
    trustedHosts: ['pricetrackerdata.com'],
    //namespace: '/',
    //host: 'https://www.pricetrackerdata.com',
    //host: 'http://98.158.89.121:3000',
    host: _environment.default.APP.apiHost,
    namespace: '/api/v1',
    headers: Ember.computed('session.authToken', {
      get() {
        let headers = {};
        const authToken = this.get('session.data.authenticated.token'); //console.log("TOKEN: ", this.get('session.data.authenticated.token'));

        if (authToken) {
          headers['Authorization'] = "Bearer " + authToken;
        }

        return headers;
      }

    })
  });

  _exports.default = _default;
});