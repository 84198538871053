define("ptportal/components/bideditor/bideditor-endusers-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    actions: {
      removeEndUser(relationId) {
        this.removeEndUser(relationId);
      }

    }
  });

  _exports.default = _default;
});