define("ptportal/components/bidexplorer/bid-explorer-general-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = Ember.Component.extend({
    fcreatedate: null,
    fcreatetime: null,
    fstartdate: null,
    fenddate: null,

    didReceiveAttrs() {
      if (this.get('price').firstObject != undefined) {
        // let cdate = this.get('price').firstObject.tnpricecreatedate;
        // let cdatedd = cdate.getDate() + 1
        // if (cdatedd < 10){
        //   cdatedd = '0' + cdatedd;
        // }
        // let cdatemm = cdate.getMonth() + 1
        // if (cdatemm < 10){
        //   cdatemm = '0' + cdatemm;
        // }
        // let createdate = cdatemm + "-" + cdatedd + "-" + cdate.getFullYear();
        let createdate = this.get('price').firstObject.tnpricecreatedate.replace(/T[0-9][0-9]:[0-9][0-9]:[0-9][0-9].[0-9][0-9][0-9]Z/, '');

        if (createdate == '1900-01-01') {
          createdate = "";
        }

        this.set("fcreatedate", createdate);
        let ctime = this.get('price').firstObject.tnpricecreatedatetime;
        let ctimehh = ctime.getHours();

        if (ctimehh < 10) {
          ctimehh = '0' + ctimehh;
        }

        let ctimemm = ctime.getMinutes();

        if (ctimemm < 10) {
          ctimemm = '0' + ctimemm;
        }

        let ctimess = ctime.getSeconds();

        if (ctimess < 10) {
          ctimess = '0' + ctimess;
        }

        let createtime = ctimehh + ":" + ctimemm + ":" + ctimess;
        this.set("fcreatetime", createtime); // let sdate = this.get('price').firstObject.tndealstartdate;
        // let sdatedd = sdate.getDate() + 1
        // if (sdatedd < 10){
        //   sdatedd = '0' + sdatedd;
        // }
        // let sdatemm = sdate.getMonth() + 1
        // if (sdatemm < 10){
        //   sdatemm = '0' + sdatemm;
        // }
        // let startdate = sdatemm + "-" + sdatedd + "-" + sdate.getFullYear();

        let startdate = this.get('price').firstObject.tndealstartdate.replace(/T[0-9][0-9]:[0-9][0-9]:[0-9][0-9].[0-9][0-9][0-9]Z/, '');

        if (startdate == '1900-01-01') {
          startdate = "";
        }

        this.set("fstartdate", startdate); // let edate = this.get('price').firstObject.tndealenddate;
        // let edatedd = edate.getDate() + 1
        // if (edatedd < 10){
        //   edatedd = '0' + edatedd;
        // }
        // let edatemm = edate.getMonth() + 1
        // if (edatemm < 10){
        //   edatemm = '0' + edatemm;
        // }
        // let enddate = edatemm + "-" + edatedd + "-" + edate.getFullYear();

        let enddate = this.get('price').firstObject.tndealenddate.replace(/T[0-9][0-9]:[0-9][0-9]:[0-9][0-9].[0-9][0-9][0-9]Z/, '');

        if (enddate == '1900-01-01') {
          enddate = "";
        }

        this.set("fenddate", enddate);
      }
    }

  });

  _exports.default = _default;
});