define("ptportal/models/tnpriceline", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // tnprices: DS.belongsTo('tnprice'),
    // tncusts: DS.belongsTo('tncust'),
    // tnvendors: DS.belongsTo('tnvendor'),
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    tnpriceid: _emberData.default.attr('string'),
    tnvendpart: _emberData.default.attr('string'),
    tnvendpartdesc: _emberData.default.attr('string'),
    tnlinestartdate: _emberData.default.attr('string'),
    tnlineenddate: _emberData.default.attr('string'),
    tnlinedeletedate: _emberData.default.attr('date'),
    tnpricebid: _emberData.default.attr('number'),
    tnpricelist: _emberData.default.attr('number'),
    tnrebate: _emberData.default.attr('number'),
    tndiscountpercentage: _emberData.default.attr('number'),
    tnquantity: _emberData.default.attr('number'),
    tnquantitymax: _emberData.default.attr('number'),
    tnquantitymin: _emberData.default.attr('number'),
    tnquantityremaining: _emberData.default.attr('number'),
    tnquantityconsumed: _emberData.default.attr('number'),
    tnvendpartline: _emberData.default.attr('string'),
    tnlinepricetype: _emberData.default.attr('string'),
    tnlinenumber: _emberData.default.attr('number'),
    tnlinelanguage: _emberData.default.attr('string'),
    tnparsedatatype: _emberData.default.attr('number'),
    tnvendid: _emberData.default.attr('string'),
    tncustid: _emberData.default.attr('string'),
    tnpartconfigid: _emberData.default.attr('string'),
    tnbidrenewalid: _emberData.default.attr('string'),
    tnbidyear: _emberData.default.attr('string'),
    tnbidpoints: _emberData.default.attr('number'),
    tnbidband: _emberData.default.attr('string'),
    tnlinestatus: _emberData.default.attr('number'),
    tnsupportid: _emberData.default.attr('string'),
    tnauthorizationcode: _emberData.default.attr('string'),
    tnserialnumber: _emberData.default.attr('string'),
    tnpricebase: _emberData.default.attr('number'),
    tndiscountpercentage2: _emberData.default.attr('number'),
    tnpriceloadlineid: _emberData.default.attr('string'),
    tnpricelineid: _emberData.default.attr('string')
  });

  _exports.default = _default;
});