define("ptportal/components/programs/programs-overview-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    custprogramid: null,
    vendorid: null,
    vendorname: null,
    programid: null,
    programname: null,
    custinternalid1: null,
    custprogrammarkup: null,

    didReceiveAttrs() {
      this.set('custprogramid', this.tncustprograms.tncustprogramid);
      this.set('vendorid', this.tncustprograms.tnvendid);
      this.set('vendorname', this.tncustprograms.tnvendvalue);
      this.set('programid', this.tncustprograms.tnprogramid);
      this.set('programname', this.tncustprograms.tncustprogramvalue);
      this.set('custinternalid1', this.tncustprograms.tncustinternalid1);
      this.set('custprogrammarkup', this.tncustprograms.tncustprogrammarkup);
    },

    actions: {
      closeModal() {
        this.closeModal();
      },

      editCustProgram() {
        var custprogram = {};
        custprogram['custprogramid'] = this.get('custprogramid'); // custprogram['vendorid'] = this.get('vendorid');
        // custprogram['vendorname'] = this.get('vendorname');
        // custprogram['programid'] = this.get('programid');
        // custprogram['programname'] = this.get('programname');

        custprogram['custinternalid1'] = this.get('custinternalid1');
        custprogram['custprogrammarkup'] = this.get('custprogrammarkup');
        this.updateCustProgram(custprogram);
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});