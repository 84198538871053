define("ptportal/components/bideditor/bideditor-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    priceid: null,
    dealnumber: null,
    dealquotenumber: null,
    dealrevision: null,
    dealdescription: null,
    dealnotes: null,
    program: null,
    startdate: null,
    enddate: null,
    selectedCurrencyCode: null,
    dealspecialterms: null,
    dealterms: null,

    getCurrValue(currencycode) {
      let currCode = currencycode;

      switch (currCode) {
        case 2:
          currCode = 'CAD';
          break;

        case 4:
          currCode = 'USD';
          break;

        case 6:
          currCode = 'GBP';
          break;

        case 8:
          currCode = 'EUR';
          break;

        case 10:
          currCode = 'Default';
          break;

        case 12:
          currCode = 'NOK';
          break;

        case 14:
          currCode = 'DKK';
          break;

        case 16:
          currCode = 'SEK';
          break;

        case 18:
          currCode = 'CZK';
          break;

        case 20:
          currCode = 'PLN';
          break;

        case 22:
          currCode = 'CHF';
          break;

        case 24:
          currCode = 'BGN';
          break;

        case 26:
          currCode = 'RON';
          break;

        default:
          currCode = 'NaN';
      }

      return currCode;
    },

    init() {
      this._super(...arguments);

      this.currencyCode = ['CAD', 'USD', 'GBP', 'EUR', 'SEK', 'NOK', 'DKK', 'CZK', 'PLN', 'CHF', 'BGN', 'RON'];
    },

    didReceiveAttrs() {
      if (this.get('model') != null) {
        if (this.get('model').data != undefined) {
          this.set('priceid', this.get('model').data.firstObject.attributes.tnpriceid);
          this.set('dealnumber', this.get('model').data.firstObject.attributes.tndealnumber);
          this.set('dealquotenumber', this.get('model').data.firstObject.attributes.tndealquotenumber);
          this.set('dealrevision', this.get('model').data.firstObject.attributes.tndealrevision);
          this.set('dealdescription', this.get('model').data.firstObject.attributes.tndealdescription);
          this.set('dealnotes', this.get('model').data.firstObject.attributes.tndealnotes);
          this.set('program', this.get('model').data.firstObject.attributes.tnprogramvalue);
          this.set('dealspecialterms', this.get('model').data.firstObject.attributes.tndealspecialterms);
          this.set('dealterms', this.get('model').data.firstObject.attributes.tndealtermslong);
          let sdate = this.get('model').data.firstObject.attributes.tndealstartdate;
          let startdate = sdate.replace(/T[0-9][0-9]:00:00.000Z/, '');
          this.set("startdate", startdate);
          let edate = this.get('model').data.firstObject.attributes.tndealenddate;
          let enddate = edate.replace(/T[0-9][0-9]:00:00.000Z/, '');
          this.set("enddate", enddate);
          let currencycode = this.get('model').data.firstObject.attributes.tncurrencycode;

          switch (currencycode) {
            case 2:
              currencycode = 'CAD';
              break;

            case 4:
              currencycode = 'USD';
              break;

            case 6:
              currencycode = 'GBP';
              break;

            case 8:
              currencycode = 'EUR';
              break;

            case 10:
              currencycode = 'Default';
              break;

            case 12:
              currencycode = 'NOK';
              break;

            case 14:
              currencycode = 'DKK';
              break;

            case 16:
              currencycode = 'SEK';
              break;

            case 18:
              currencycode = 'CZK';
              break;

            case 20:
              currencycode = 'PLN';
              break;

            case 22:
              currencycode = 'CHF';
              break;

            case 24:
              currencycode = 'BGN';
              break;

            case 26:
              currencycode = 'RON';
              break;

            default:
              currencycode = 'NaN';
          }

          this.set("selectedCurrencyCode", currencycode);
        }
      }
    },

    actions: {
      updateBidHeader() {
        var bidheader = {};
        bidheader['priceid'] = this.get('priceid');

        if (this.get('dealnumber') !== this.get('model').data.firstObject.attributes.tndealnumber) {
          bidheader['dealnumber'] = this.get('dealnumber');
        }

        if (this.get('dealquotenumber') !== this.get('model').data.firstObject.attributes.tndealquotenumber) {
          bidheader['dealquotenumber'] = this.get('dealquotenumber');
        }

        if (this.get('dealrevision') !== this.get('model').data.firstObject.attributes.tndealrevision) {
          bidheader['dealrevision'] = this.get('dealrevision');
        }

        if (this.startdate !== this.get('model').data.firstObject.attributes.tndealstartdate.replace(/T[0-9][0-9]:00:00.000Z/, '')) {
          bidheader['startdate'] = this.startdate;
        }

        if (this.enddate !== this.get('model').data.firstObject.attributes.tndealenddate.replace(/T[0-9][0-9]:00:00.000Z/, '')) {
          bidheader['enddate'] = this.enddate;
        }

        if (this.get('selectedCurrencyCode') !== this.getCurrValue(this.get('model').data.firstObject.attributes.tncurrencycode)) {
          bidheader['selectedcurrencycode'] = this.get('selectedCurrencyCode');
        }

        if (this.get('dealnotes') !== this.get('model').data.firstObject.attributes.tndealnotes) {
          bidheader['dealnotes'] = this.get('dealnotes');
        }

        if (this.get('dealdescription') !== this.get('model').data.firstObject.attributes.tndealdescription) {
          bidheader['dealdescription'] = this.get('dealdescription');
        }

        if (this.get('dealspecialterms') !== this.get('model').data.firstObject.attributes.tndealspecialterms) {
          bidheader['dealspecialterms'] = this.get('dealspecialterms');
        }

        if (this.get('dealterms') !== this.get('model').data.firstObject.attributes.tndealtermslong) {
          bidheader['dealterms'] = this.get('dealterms');
        }

        this.updateBidHeader(bidheader);
      },

      sendBidRails(sendtype) {
        this.sendBidRails(sendtype);
      },

      togglesdCalendar() {
        this.flatpickrRef1.toggle();
      },

      toggleedCalendar() {
        this.flatpickrRef2.toggle();
      },

      clearsdCalendar() {
        this.flatpickrRef1.clear();
      },

      clearedCalendar() {
        this.flatpickrRef2.clear();
      },

      originalDoc() {
        this.originalDoc();
      }

    }
  });

  _exports.default = _default;
});