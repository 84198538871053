define("ptportal/services/ax-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    parse_response(data) {
      function replace_tags(data) {
        if (data !== null && data !== '') {
          data = data.replace(/&lt;/g, '<');
          data = data.replace(/&gt;/g, '>');
          return data;
        }
      }

      function remove_wrapping(data) {
        data.splice(0, 3);
        data.pop();
        return data;
      }

      function parse_field_id(data) {
        var myRe = /<\w*>/g;
        var myRe2 = /[^<]\w*[^>]/g;

        if (data !== null && data !== '') {
          data = myRe2.exec(myRe.exec(data))[0];
          return data;
        }
      }

      function parse_field_data(data) {
        var myRe3 = />.*</g;
        var myRe4 = /[^>].*[^<]/g;

        if (data !== null && data !== '') {
          data = myRe4.exec(myRe3.exec(data))[0];
          return data;
        }
      }

      var fields = [];

      if (data !== null && data !== '') {
        data = data.split(/\r?\n/g);
        data = remove_wrapping(data);
        data.forEach(function (line) {
          line = replace_tags(line);
          var field_id = parse_field_id(line);
          var field_data = parse_field_data(line);
          fields.push({
            id: field_id,
            data: field_data
          });
        });
        return fields;
      }
    }

  });

  _exports.default = _default;
});