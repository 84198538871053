define("ptportal/components/exceptions/exceptions-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    specialterms: null,
    dealnotes: null,

    didReceiveAttrs() {
      if (this.get('model').firstObject !== undefined) {
        this.set("specialterms", this.get('model').firstObject.tndealspecialterms);
        this.set("dealnotes", this.get('model').firstObject.tndealnotes);
      }
    },

    actions: {
      editNotes() {
        var notes = {};
        notes['specialterms'] = this.get('specialterms');
        notes['dealnotes'] = this.get('dealnotes');
        this.updateNotes(notes);
      }

    }
  });

  _exports.default = _default;
});