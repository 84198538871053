define("ptportal/templates/components/dashboard/bid-volume-by-vendors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8/07T6NN",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"id\",\"pie-chart-container\",null],[12,\"style\",\"width:100%;\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,55,11,[27,[26,2,\"CallHead\"],[]],null,[[\"chartOptions\",\"content\"],[[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"chartData\",\"chartOptions\",\"high-charts\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/dashboard/bid-volume-by-vendors.hbs"
    }
  });

  _exports.default = _default;
});