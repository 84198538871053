define("ptportal/components/usermanagement/users-table-edit", ["exports", "sweetalert2"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    firstname: null,
    lastname: null,
    email: null,
    contactpersonid: null,
    tnpassword: null,
    tncpassword: null,
    tnuserid: null,
    telephone: null,
    accessdashboard: null,
    accessbidexplorer: null,
    accesscustrelations: null,
    accessexceptions: null,
    accessbideditor: null,
    accessusermanagement: null,
    accesssendbid: null,
    accesscusteditor: null,
    accesscustprograms: null,
    accessemail: null,
    accessexchange: null,
    validated: false,

    didReceiveAttrs() {
      this.set('firstname', this.user.tnfirstname);
      this.set('lastname', this.user.tnlastname);
      this.set('email', this.user.tnemail);
      this.set('contactpersonid', this.user.tncontactpersonid);
      this.set('tnuserid', this.user.tnuserid);
      this.set('telephone', this.user.tntelephone);
      this.set('accessdashboard', this.user.accessDashboard ? 1 : 0);
      this.set('accessemail', this.user.accessEmail ? 1 : 0);
      this.set('accessbidexplorer', this.user.accessBidExplorer ? 1 : 0);
      this.set('accesscustrelations', this.user.accessMyCustomers ? 1 : 0);
      this.set('accessexceptions', this.user.accessExceptions ? 1 : 0);
      this.set('accessbideditor', this.user.accessBidEditor ? 1 : 0);
      this.set('accessusermanagement', this.user.accessUserManagement ? 1 : 0);
      this.set('accesssendbid', this.user.accessSendBid ? 1 : 0);
      this.set('accesscusteditor', this.user.accessCustomerEditor ? 1 : 0);
      this.set('accesscustprograms', this.user.accessPrograms ? 1 : 0);
      this.set('accessexchange', this.user.accessExchangeRates ? 1 : 0);
    },

    actions: {
      closeModal() {
        this.closeModal();
      },

      onPasswordChange() {
        var lengthvalidated;
        var lowervalidated;
        var uppervalidated;
        var digitvalidated;

        if (this.get("tnpassword")) {
          if (this.get("tnpassword").length >= 8) {
            lengthvalidated = true;
            this.set("vlength", "colorgreen");
          } else {
            lengthvalidated = false;
            this.set("vlength", "colorred");
          }

          if (/^(?=.*[a-z]).+$/.test(this.get("tnpassword"))) {
            lowervalidated = true;
            this.set("vlowercase", "colorgreen");
          } else {
            lowervalidated = false;
            this.set("vlowercase", "colorred");
          }

          if (/^(?=.*[A-Z]).+$/.test(this.get("tnpassword"))) {
            uppervalidated = true;
            this.set("vuppercase", "colorgreen");
          } else {
            uppervalidated = false;
            this.set("vuppercase", "colorred");
          }

          if (/^(?=.*[0-9_\W]).+$/.test(this.get("tnpassword"))) {
            digitvalidated = true;
            this.set("vnumber", "colorgreen");
          } else {
            digitvalidated = false;
            this.set("vnumber", "colorred");
          }
        } else {
          this.set("vlength", "");
          this.set("vlowercase", "");
          this.set("vuppercase", "");
          this.set("vnumber", "");
        }

        if (this.get("tncpassword")) {
          if (this.get("tnpassword") !== this.get("tncpassword")) {
            this.set("vconfirmpassword", "colorred");
          } else {
            this.set("vconfirmpassword", "colorgreen");
          }
        } else {
          this.set("vconfirmpassword", "");
        }

        if (lengthvalidated && lowervalidated && uppervalidated && digitvalidated) {
          this.set("validated", true);
        } else {
          this.set("validated", false);
        }
      },

      onCPasswordChange() {
        if (this.get("tncpassword") && this.get("tnpassword")) {
          if (this.get("tnpassword") !== this.get("tncpassword")) {
            this.set("vconfirmpassword", "colorred");
          } else {
            this.set("vconfirmpassword", "colorgreen");
          }
        } else {
          this.set("vconfirmpassword", "");
        }
      },

      editUser() {
        if (this.get("tnpassword") == "") {
          this.set("tnpassword", null);
        }

        if (this.get("tncpassword") == "") {
          this.set("tncpassword", null);
        }

        if (this.get("tnpassword") !== this.get("tncpassword")) {
          (0, _sweetalert.default)('Password and Confirm Password did not match', 'Please re-enter the Password and Confirm Password and try again.', 'error');
          return;
        }

        if (this.get("validated") || this.get("tnpassword") == null) {
          var user = {};
          user['firstname'] = this.get('firstname');
          user['lastname'] = this.get('lastname');
          user['email'] = this.get('email');
          user['contactpersonid'] = this.get('contactpersonid');

          if (this.get("tnpassword")) {
            user['tnpassword'] = this.get('tnpassword');
          }

          user['tnuserid'] = this.get('tnuserid');
          user['telephone'] = this.get('telephone');
          user['accessdashboard'] = this.get('accessdashboard');
          user['accessbidexplorer'] = this.get('accessbidexplorer');
          user['accesscustrelations'] = this.get('accesscustrelations');
          user['accessexceptions'] = this.get('accessexceptions');
          user['accessbideditor'] = this.get('accessbideditor');
          user['accessemail'] = this.get('accessemail');
          user['accessexchange'] = this.get('accessexchange');
          user['accessusermanagement'] = this.get('accessusermanagement');
          user['accesssendbid'] = this.get('accesssendbid');
          user['accesscusteditor'] = this.get('accesscusteditor');
          user['accesscustprograms'] = this.get('accesscustprograms');
          this.updateUser(user);
          this.closeModal();
        } else {
          (0, _sweetalert.default)('Password Does Not Meet Requirements', 'Your password needs to be atleast 8 characters, and needs to include one upper case letter, one lowercase letter and a number or special character.', 'error');
          return;
        }
      }

    }
  });

  _exports.default = _default;
});