define("ptportal/initializers/excel", ["exports", "ember-cli-data-export/initializers/excel"], function (_exports, _excel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _excel.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _excel.initialize;
    }
  });
});