define("ptportal/components/data-table-content-header", ["exports", "ember-data-table/components/data-table-content-header"], function (_exports, _dataTableContentHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dataTableContentHeader.default;
    }
  });
});