define("ptportal/components/exceptions/exceptions-overview-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['isSelected'],
    isSelected: false,
    selectedContactPerson: null,

    init() {
      this._super(...arguments);

      if (this.get("selectedPriceId") === this.tnexceptionprice.tnpriceid) {
        this.toggleProperty("isSelected");
      }

      this.contactpeople = this.users;
    },

    click: function () {
      if (this.get("isSelected") == false) {
        this.toggleProperty("isSelected", true);
      }

      let selection = this.tnexceptionprice.tnpriceid;
      this.selectPrice(selection);
    },

    didUpdate() {
      this._super(...arguments);

      if (this.get("selectedPriceId") !== this.tnexceptionprice.tnpriceid) {
        this.set("isSelected", false);
      }
    },

    actions: {
      setAssignee() {
        this.setAssignee(this.tnexceptionprice.tnpriceid, this.get("selectedContactPerson"));
      },

      removeAssignee() {
        this.removeAssignee(this.tnexceptionprice.tnpriceid);
      },

      claimException() {
        this.claimException(this.tnexceptionprice.tnpriceid);
      }

    }
  });

  _exports.default = _default;
});