define("ptportal/routes/mycustomers", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "sweetalert2"], function (_exports, _authenticatedRouteMixin, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    swal: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    router: Ember.inject.service('router'),
    customers: null,
    customersExport: null,
    selectedPriceRelationId: 0,
    selectedPriceRelationStatus: 0,
    setAccountMode: false,
    custrelations: null,
    mappedCustRelations: null,
    selectedResellerId: 0,
    selectedRelationId: 0,
    mycustfilters: null,
    currentPage: 1,
    totalRows: 0,
    currentPageRelations: 1,
    isCustomerCDW: 0,
    beforeModel: function () {
      if (!this.get('session.isAuthenticated')) {
        this.get('router').transitionTo('login');
        return;
      }

      if (this.get("currentUser").user.accessMyCustomers) {
        return true;
      }

      this.get('session').invalidate();
    },

    model() {
      if (this.get("currentUser").user.tncustid == "CU100007") {
        this.set("isCustomerCDW", 1);
      }

      return Ember.RSVP.hash({
        customers: this.get("customers"),
        selectedPriceRelationId: this.get("selectedPriceRelationId"),
        setAccountMode: this.get("setAccountMode"),
        custrelations: this.get('custrelations'),
        selectedResellerId: this.get('selectedResellerId'),
        selectedRelationId: this.get('selectedRelationId'),
        mappedCustRelations: this.get('mappedCustRelations'),
        selectedPriceRelationStatus: this.get('selectedPriceRelationStatus'),
        currentPage: this.get('currentPage'),
        totalRows: this.get('totalRows'),
        mycustfilters: this.get('mycustfilters'),
        currentPageRelations: this.get('currentPageRelations'),
        isCustomerCDW: this.get('isCustomerCDW')
      });
    },

    actions: {
      searchMyCust(mycustfilters, pagenumber) {
        this.set("currentPage", pagenumber);
        this.set("mycustfilters", mycustfilters);
        var query = {};

        if (mycustfilters.customerName) {
          query['TNRELATIONNAME'] = mycustfilters.customerName;
        }

        if (mycustfilters.accountNumber) {
          query['TNCUSTINTERNALID1'] = mycustfilters.accountNumber;
        }

        if (mycustfilters.mapStatus) {
          query['TNRELATIONSTATUS'] = mycustfilters.mapStatus;
        }

        if (mycustfilters.relationSource) {
          query['TNRELATIONSOURCE'] = mycustfilters.relationSource;
        }

        query['page'] = pagenumber;
        this.get('ajax').request('mycustomers', {
          type: 'GET',
          data: query
        }).then(customers => {
          this.set("customers", customers);
          this.set("totalRows", customers.totalrecords);
          this.set("selectedPriceRelationId", 0);
          this.set("mappedCustRelations", null);
          this.refresh();
        });
      },

      refreshMyCust(mycustfilters) {
        this.set("mycustfilters", mycustfilters);
        var query = {};

        if (mycustfilters.customerName) {
          query['TNRELATIONNAME'] = mycustfilters.customerName;
        }

        if (mycustfilters.accountNumber) {
          query['TNCUSTINTERNALID1'] = mycustfilters.accountNumber;
        }

        if (mycustfilters.mapStatus) {
          query['TNRELATIONSTATUS'] = mycustfilters.mapStatus;
        }

        if (mycustfilters.relationSource) {
          query['TNRELATIONSOURCE'] = mycustfilters.relationSource;
        }

        query['page'] = this.get("currentPage");
        this.get('ajax').request('mycustomers', {
          type: 'GET',
          data: query
        }).then(customers => {
          this.set("customers", customers);
          this.set("totalRows", customers.totalrecords);
          this.refresh();
        });
      },

      selectPriceRelation(pricerelationid, relationstatus) {
        this.set("selectedPriceRelationId", pricerelationid);
        this.set("selectedPriceRelationStatus", relationstatus);
        this.get('ajax').request('mycustomers/' + this.get("selectedPriceRelationId"), {
          type: 'GET'
        }).then(data => {
          this.set("mappedCustRelations", data);
          this.refresh();
        });
        this.refresh();
      },

      setAccount() {
        this.toggleProperty("setAccountMode");
        this.set('custrelations', null);
        this.refresh();
      },

      disablePriceRelation() {
        this.get('ajax').request('mycustomers/' + this.get("selectedPriceRelationId"), {
          type: 'PUT',
          data: {
            TNRELATIONSTATUS: 6
          }
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Customer Disabled', 'Customer was successfully disabled.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while disabling the customer.', 'error');
          }

          this.send("refreshMyCust", this.get("mycustfilters"));
          this.send("selectPriceRelation", this.get("selectedPriceRelationId"), "Disabled");
        });
      },

      removePriceRelation() {
        let query = {
          TNCUSTRELATIONID: "",
          TNRELATIONSTATUS: 4
        };
        this.get('ajax').request('mycustomers/' + this.get("selectedPriceRelationId"), {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Customer Removed', 'Customer was successfully removed.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while removing the customer.', 'error');
          }

          this.send("refreshMyCust", this.get("mycustfilters"));
          this.send("selectPriceRelation", this.get("selectedPriceRelationId"), "UnMapped");
        });
      },

      exportPriceRelation() {
        var query = {};

        if (this.mycustfilters.customerName) {
          query['TNRELATIONNAME'] = this.mycustfilters.customerName;
        }

        if (this.mycustfilters.accountNumber) {
          query['TNCUSTINTERNALID1'] = this.mycustfilters.accountNumber;
        }

        if (this.mycustfilters.mapStatus) {
          query['TNRELATIONSTATUS'] = this.mycustfilters.mapStatus;
        }

        if (this.mycustfilters.relationSource) {
          query['TNRELATIONSOURCE'] = this.mycustfilters.relationSource;
        }

        query['export'] = 1;
        this.get('ajax').request('mycustomers', {
          type: 'GET',
          data: query
        }).then(data => {
          this.set("customersExport", data);
          let formattedArray = [];
          formattedArray.pushObject(["Status", "Source", "Name", "Address", "City", "Vendor Id", "Relation Name", "Customer Number"]);

          if (this.get("customersExport") !== null) {
            this.get("customersExport").forEach(function (customers) {
              let relationstatus = customers.relationStatus;

              switch (relationstatus) {
                case 0:
                  relationstatus = 'All';
                  break;

                case 2:
                  relationstatus = 'Mapped';
                  break;

                case 4:
                  relationstatus = 'UnMapped';
                  break;

                case 6:
                  relationstatus = 'Disabled';
                  break;

                default:
                  relationstatus = 'Undefined';
              }

              let relationsource = customers.relationSource;

              switch (relationsource) {
                case 0:
                  relationsource = 'All';
                  break;

                case 2:
                  relationsource = 'BidLetter';
                  break;

                case 4:
                  relationsource = 'Domain';
                  break;

                case 6:
                  relationsource = 'NameMatch';
                  break;

                default:
                  relationsource = 'Undefined';
              }

              formattedArray.pushObject([relationstatus, relationsource, customers.tnRelationName, customers.tnRelationAddress, customers.tnRelationCity, customers.vendorRelationID, customers.mappedRelationName, customers.mappedRelationID]);
            });
          }

          let type = "CSV";

          if (type === 'Excel') {
            this.get('excel').export(formattedArray, {
              sheetName: 'My Customers',
              fileName: 'my-customers-csv-export.xlsx'
            });
          } else if (type === 'CSV') {
            this.get('csv').export(formattedArray, {
              fileName: 'my-customers-csv-export.csv'
            });
          }
        });
      },

      getCustRelations(reseller, pagenumber) {
        // Cust Relations: this should be moved to a separate action (getCustRelations) for when user selects 'Add Relation' button
        this.set("currentPageRelations", pagenumber);
        this.set("cusrtrelationfilters", reseller);
        this.get('ajax').request('tncustrelations', {
          type: 'GET',
          data: {
            TNRELATIONNAME: reseller.resellername,
            TNCUSTINTERNALID1: reseller.custinternalid1,
            page: pagenumber
          }
        }).then(data => {
          this.set('custrelations', data);
          this.refresh();
        });
        this.set("selectedRelationId", 0);
        this.set("selectedResellerId", 0);
        this.refresh();
      },

      selectReseller(custRelation) {
        this.set("selectedResellerId", custRelation);
        this.refresh();
      },

      selectRelation(custRelation) {
        this.set("selectedRelationId", custRelation);
        this.refresh();
      },

      //Below action for the SET ACCOUNT!
      addCustRelation() {
        let query = {
          TNCUSTRELATIONID: this.get("selectedResellerId"),
          TNRELATIONSTATUS: 2
        };
        this.get('ajax').request('mycustomers/' + this.get("selectedPriceRelationId"), {
          type: 'PUT',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Customer Mapped', 'Customer was successfully mapped.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while mapping the customer.', 'error');
          }

          this.send("refreshMyCust", this.get("mycustfilters"));
          this.send("selectPriceRelation", this.get("selectedPriceRelationId"), this.get('selectedPriceRelationStatus'));
        });
      },

      //Below action for the Add Relation!
      addCustRelation2() {
        let query = {
          TNCUSTRELATIONID: this.get("selectedRelationId"),
          TNPRICERELATIONID: this.get("selectedPriceRelationId")
        };
        this.get('ajax').request('mycustomers/', {
          type: 'POST',
          data: query
        }).then(data => {
          if (data.message) {
            (0, _sweetalert.default)('Customer Relation Added', 'Relation was successfully added for the customer.', 'success');
          } else {
            (0, _sweetalert.default)('Error!', 'An error occoured while adding relation for the customer.', 'error');
          }

          this.send("selectPriceRelation", this.get("selectedPriceRelationId"), this.get('selectedPriceRelationStatus'));
        });
      },

      removeRelation(relationid) {
        this.get('swal').open({
          title: 'Are you sure you want to remove this Relation?',
          showCancelButton: true,
          confirmButtonText: "Remove",
          type: "question"
        }).then(({
          value
        }) => {
          if (value) {
            var custRelId = relationid;
            this.get('ajax').request("mycustomers/" + this.get("selectedPriceRelationId"), {
              type: 'DELETE',
              data: {
                TNCUSTRELATIONIDREF: custRelId
              }
            }).then(data => {
              if (data.message) {
                (0, _sweetalert.default)('Relation Removed', 'Relation was successfully removed for the customer.', 'success');
              } else {
                (0, _sweetalert.default)('Error!', 'An error occoured while deleting relation for the customer.', 'error');
              }

              this.send("selectPriceRelation", this.get("selectedPriceRelationId"), this.get('selectedPriceRelationStatus'));
            });
          }
        });
      },

      clearCustRelations() {
        this.set('custrelations', null);
        this.refresh();
      },

      clearFilters() {
        this.set("mycustfilters", null);
        this.set("customers", null);
        this.set("selectedPriceRelationId", 0);
        this.set("selectedPriceRelationStatus", 0);
        this.set("custrelations", null);
        this.set("mappedCustRelations", null);
        this.set("selectedResellerId", 0);
        this.set("selectedRelationId", 0);
        this.set("currentPage", 1);
        this.set("totalRows", 0);
        this.refresh();
      },

      //Pagination
      nextPage() {
        let currentPage = this.get("currentPage");
        currentPage++;
        this.send("searchMyCust", this.get("mycustfilters"), currentPage);
      },

      prevPage() {
        let currentPage = this.get("currentPage");
        currentPage--;
        this.send("searchMyCust", this.get("mycustfilters"), currentPage);
      },

      firstPage() {
        this.set("currentPage", 1);
        this.send("searchMyCust", this.get("mycustfilters"), 1);
      },

      lastPage(totalpages) {
        this.set("currentPage", totalpages);
        this.send("searchMyCust", this.get("mycustfilters"), totalpages);
      },

      //Pagination Relations
      nextPageRelations() {
        let currentPageRelations = this.get("currentPageRelations");
        currentPageRelations++;
        this.send("getCustRelations", this.get("cusrtrelationfilters"), currentPageRelations);
      },

      prevPageRelations() {
        let currentPageRelations = this.get("currentPageRelations");
        currentPageRelations--;
        this.send("getCustRelations", this.get("cusrtrelationfilters"), currentPageRelations);
      },

      firstPageRelations() {
        this.set("currentPageRelations", 1);
        this.send("getCustRelations", this.get("cusrtrelationfilters"), 1);
      },

      lastPageRelations(totalpages) {
        this.set("currentPageRelations", totalpages);
        this.send("getCustRelations", this.get("cusrtrelationfilters"), totalpages);
      }

    }
  });

  _exports.default = _default;
});