define("ptportal/templates/components/dashboard/dashboard-latestbids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v4niqAtZ",
    "block": "{\"symbols\":[\"latestbid\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"table-responsive latestbid-table-wrapper tableFixHead\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"table\",true],[12,\"class\",\"table table-hover\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"thead\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"tr\",true],[10],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Price ID\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Deal Number\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Vendor Name\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Program\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Received Min\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"tbody\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[1,0,0,0,[31,367,34,[27,[26,1,\"CallHead\"],[]],null,[[\"latestbid\",\"selectLatestBid\"],[[27,[24,1],[]],[31,439,12,[27,[26,0,\"CallHead\"],[]],[\"selectLatestBid\"],null]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"dashboard/dashboard-latestbids-row\",\"rows\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/dashboard/dashboard-latestbids.hbs"
    }
  });

  _exports.default = _default;
});