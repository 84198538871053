define("ptportal/templates/components/mycustomers/mycust-set-account-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nQiJH0or",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationname\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tnrelationcity\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tncustname\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,0,\"Expression\"],[\"tncustinternalid1\"]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"reseller\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/mycustomers/mycust-set-account-row.hbs"
    }
  });

  _exports.default = _default;
});