define("ptportal/helpers/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function userAvatar(params) {
    let [fname, lname] = params;

    if (fname != undefined && lname != undefined) {
      let fletter = fname[0].charAt(0);
      let lletter = lname[0].charAt(0);
      let avatar = fletter + lletter;
      return avatar;
    }
  });

  _exports.default = _default;
});