define("ptportal/components/bideditor/bideditor-add-reseller-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['isSelected'],
    isSelected: false,

    init() {
      this._super(...arguments); // // console.log("Selected Reseller id Init", this.get("selectedResellerId"));
      // if(this.get("selectedResellerId") === this.reseller.tncustrelationid){
      //    this.toggleProperty("isSelected");
      // }

    },

    click: function () {
      if (this.get("isSelected") == false) {
        this.toggleProperty("isSelected", true);
      }

      let selection = this.reseller.tncustrelationid;
      this.selectReseller(selection);
    },

    didReceiveAttrs() {
      this._super(...arguments); // // console.log("Selected Reseller id Did Update", this.get("selectedResellerId"));


      if (this.get("selectedResellerId") !== this.reseller.tncustrelationid) {
        this.set("isSelected", false);
      }
    },

    actions: {// addCustRelation(CustRelationId){
      //   this.addCustRelation(CustRelationId);
      // }
    }
  });

  _exports.default = _default;
});