define("ptportal/components/editcustomers/editcust-salesrelations-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    employeename: null,
    salesid: null,
    email: null,
    selectedEmployeeType: 'Sales Rep In',

    init() {
      this._super(...arguments);

      this.employeeType = ['Sales Manager', 'Sales Group', 'Sales Rep In', 'Sales Rep Out'];
    },

    actions: {
      closeModal() {
        this.closeModal();
      },

      addSalesRelation() {
        var salesrelation = {};
        salesrelation['salesrelationname'] = this.get('employeename');
        salesrelation['salesid'] = this.get('salesid');
        salesrelation['email'] = this.get('email');
        salesrelation['salesrelationtype'] = this.get('selectedEmployeeType');
        this.addSalesRelation(salesrelation);
        this.closeModal();
      }

    }
  });

  _exports.default = _default;
});