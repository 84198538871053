define("ptportal/models/tncustsalesrelation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // tncust: DS.belongsTo('tncust'),
    // rep_inside: DS.hasMany('rep_inside'),
    // rep_manager: DS.hasMany('rep_manager'),
    // rep_group: DS.hasMany('rep_group'),
    // rep_outside: DS.hasMany('rep_outside'),
    // rep_director: DS.hasMany('rep_director'),
    dataareaid: _emberData.default.attr('string'),
    recversion: _emberData.default.attr('number'),
    recid: _emberData.default.attr('number'),
    tncustsalestype: _emberData.default.attr('number'),
    tncustsalesname: _emberData.default.attr('string'),
    tncustsalesid: _emberData.default.attr('string'),
    tncustsalesemail: _emberData.default.attr('string'),
    tncustsalesrelationid: _emberData.default.attr('string'),
    tncustid: _emberData.default.attr('string'),
    tnactive: _emberData.default.attr('number'),
    tnemailactive: _emberData.default.attr('number'),
    tncustsalestel: _emberData.default.attr('string')
  });

  _exports.default = _default;
});