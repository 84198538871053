define("ptportal/components/bidexplorer/be-overview-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageCount: "",
    disableNext: 0,
    totalPages: 0,
    disablePrevious: 0,
    disablePagination: 0,

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('model') !== undefined && this.get('model') !== null && this.get('model') !== {}) {
        let message = "Page " + this.currentPage + " of " + this.model.totalpages;
        this.set("pageCount", message);
        this.set("totalPages", this.model.totalpages);

        if (this.currentPage == this.totalPages) {
          this.set("disableNext", 0);
        }

        if (this.currentPage < this.totalPages) {
          this.set("disableNext", 1);
        }

        if (this.currentPage > 1) {
          this.set("disablePrevious", 1);
        } else {
          this.set("disablePrevious", 0);
        }

        if (this.totalPages > 1) {
          this.set("disablePagination", 1);
        } else {
          this.set("disablePagination", 0);
        }
      } else {
        this.set("disablePagination", 0);
      }
    },

    rows: Ember.computed('model', function () {
      if (this.get('model') !== undefined && this.get('model') !== null) {
        let formattedArray = [];
        this.get('model').data.forEach(function (tnbidheader) {
          let bidstatus = '';
          var today = new Date();
          var dd = today.getDate();
          var mm = today.getMonth() + 1; //January is 0!

          var yyyy = today.getFullYear();

          if (dd < 10) {
            dd = '0' + dd;
          }

          if (mm < 10) {
            mm = '0' + mm;
          }

          today = yyyy + '-' + mm + '-' + dd;

          if (tnbidheader.tndealenddate >= today) {
            bidstatus = "Active";
          } else {
            bidstatus = "Expired";
          }

          formattedArray.pushObject({
            tnpriceid: tnbidheader.tnpriceid,
            tndealnumber: tnbidheader.tndealnumber,
            tndealrevision: tnbidheader.tndealrevision,
            tnprogram: tnbidheader.tnprogram,
            tnreseller: tnbidheader.tnreseller,
            tncustomer: tnbidheader.tncustomer,
            tndealquotenumber: tnbidheader.tndealquotenumber,
            tnpricecreatedate: tnbidheader.tnpricecreatedate,
            tndealenddate: tnbidheader.tndealenddate,
            tnbidvalue: Math.round(tnbidheader.tnbidvalue),
            vendname: tnbidheader.vendname,
            tnstatus: bidstatus
          });
        });
        return formattedArray;
      }
    }),
    actions: {
      nextPage() {
        this.nextPage();
      },

      prevPage() {
        this.prevPage();
      },

      firstPage() {
        this.firstPage();
      },

      lastPage() {
        this.lastPage(this.get("totalPages"));
      }

    }
  });

  _exports.default = _default;
});