define("ptportal/templates/components/programs/programs-overview-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ubcnn49q",
    "block": "{\"symbols\":[],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tncustprogramid\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tnvendid\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tnvendvalue\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tnprogramid\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tncustprogramvalue\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tncustinternalid1\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,0,0,0,[27,[26,2,\"Expression\"],[\"tncustprogrammarkup\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"td\",true],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",false],[23,\"rote\",\"button\",null],[23,\"class\",\"icon icon-account-edit\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"editMode\"],null],[10],[1,1,0,0,\"\\n    \"],[7,\"bs-tooltip\",[],[[\"@title\"],[\"Edit\"]],null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,446,31,[27,[26,3,\"CallHead\"],[]],null,[[\"tncustprograms\",\"updateCustProgram\",\"closeModal\"],[[27,[26,2,\"Expression\"],[]],[31,527,12,[27,[26,1,\"CallHead\"],[]],[\"updateCustProgram\"],null],[31,573,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"editMode\"],null]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"route-action\",\"tncustprograms\",\"programs/programs-overview-edit\",\"editMode\",\"if\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/programs/programs-overview-row.hbs"
    }
  });

  _exports.default = _default;
});