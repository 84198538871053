define("ptportal/adapters/application", ["exports", "ember-data", "ember-simple-auth-token/mixins/token-authorizer", "ptportal/config/environment"], function (_exports, _emberData, _tokenAuthorizer, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_tokenAuthorizer.default, {
    namespace: 'api/v1',
    host: _environment.default.APP.apiHost //host: 'http://localhost:3000',
    //authenticator: 'authenticator:token',

  });

  _exports.default = _default;
});