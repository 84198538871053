define("ptportal/templates/components/bidexplorer/be-transport-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qQ6tK+W1",
    "block": "{\"symbols\":[\"tntransport\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"table-responsive overview-table-wrapper tableFixHead\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"table\",true],[12,\"class\",\"table table-hover\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"thead\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"tr\",true],[10],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Date/Time\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Publish Type\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Destination\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Response\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Status\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Notes\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Contact\"],[11],[1,1,0,0,\"\\n        \"],[9,\"th\",true],[10],[1,1,0,0,\"Email\"],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"tbody\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[1,0,0,0,[31,418,28,[27,[26,0,\"CallHead\"],[]],null,[[\"tntransport\"],[[27,[24,1],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"bidexplorer/be-transport-row\",\"rows\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ptportal/templates/components/bidexplorer/be-transport-tab.hbs"
    }
  });

  _exports.default = _default;
});